import { useAppContext } from '../context/app';
import { useUser } from '../hooks/authentication';
import { useSetSapFlowStatus } from '../hooks/data_delivery';
import {
  useCreateTransaction,
  useDeleteTransaction,
  useRunSapFlow,
  useStopSapFlow
} from '../hooks/sapflow/transactions';
import { SapFlowProcessNode, SapFlow, ESapFlowStatus } from '../types';

const SapFlowActions = () => {
  const { dispatch } = useAppContext();
  const { getCurrentOrganization } = useUser();
  const createSapflowMutation = useCreateTransaction();
  const runSapflowMutation = useRunSapFlow();
  const stopSapflowMutation = useStopSapFlow();
  const setSapflowStatusMutation = useSetSapFlowStatus();
  const orgId = getCurrentOrganization();
  //const deleteSapflowMutation = useDeleteTransaction(()=>{});


  const handleCreateSapFlow = async (configId: string, projectId: string, name: string, description: string) => {
    return await createSapflowMutation.mutateAsync({ configid: configId, projectid: projectId, name, description });
  };

  //const handleDeleteSapFlow = (sapflowId: FileNode) => {
  //  downloadFileNodeMutation.mutateAsync({ fileNodeId: fileNode.id, fileNodeName: fileNode.name });
  //};

  const handleRunSapFlow = async (sapflow: SapFlow, processNode: SapFlowProcessNode) => {
    return await runSapflowMutation.mutateAsync({ sapflowid: sapflow.id, processnodeid: processNode.id }).then(() => {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { content: 'Started Sapflow ' + sapflow.sapflowname + '.', type: 'success' } },
      });
    });
  };

  const handleStopSapFlow = async (sapflow: SapFlow) => {
    return await stopSapflowMutation.mutateAsync({ sapflowid: sapflow.id }).then(() => {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { content: 'Stopped Sapflow ' + sapflow.sapflowname + '.', type: 'success' } },
      });
    });
  };

  const handleSetSapFlowStatus = async (sapflow: SapFlow, status: ESapFlowStatus) => {
    return await setSapflowStatusMutation.mutateAsync({ sapFlowId: sapflow.id, status }).then(() => {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { content: 'Assigned Sapflow status to ' + status + '.', type: 'success' } },
      });
    });
  };

  return {
    handleCreateSapFlow,
    handleRunSapFlow,
    handleStopSapFlow,
    handleSetSapFlowStatus
  };
};

export default SapFlowActions;
