import React, { useEffect, useState, useRef } from 'react';
import { useAppContext } from '../../../../../context/app';
import { DeliveryNavigationMode, useConsumeDataDeliveryState } from '../../../../../context/dataDeliveryContext';
import { FileNodeStatus, FileNodeType } from '../../../../../types/DataDelivery';
import { Typography } from '@progress/kendo-react-common';
import RasterControls from './RasterControls';
import PointcloudControls from './PointcloudControls';
import VectorControls from './VectorControls';
import { ViewLayer } from '../../../../../types';

interface VisualizationControlsProps {
  layer: ViewLayer;
  onLayerUpdated: (layer: ViewLayer) => void;
}

const VisualizationControls: React.FC<VisualizationControlsProps> = (props: VisualizationControlsProps) => {
  const { openedFileNode } = useConsumeDataDeliveryState();

  if (openedFileNode.status !== FileNodeStatus.READY) {
    return <Typography.p>Data not ready for visualization.</Typography.p>;
  }
  if (openedFileNode.fileType === FileNodeType.RASTER) {
    return <RasterControls {...props} />;
  }
  if (openedFileNode.fileType === FileNodeType.POINT_CLOUD) {
    return <PointcloudControls {...props} />;
  }
  if (openedFileNode.fileType === FileNodeType.VECTOR || openedFileNode.fileType === FileNodeType.GSVEC) {
    return <VectorControls {...props} />;
  }
  return <div className="d-flex flex-column animated-left"></div>;
};

export default VisualizationControls;
