import { useMutation, useQueryClient } from 'react-query';
import { SapFlow } from '../../../types';
import { useAxiosInstance } from '../../common/';

interface Params {
  name: string;
  fileformat: string;
  url?: string;
  visualize: boolean;
}

const useCreateTransactionItemNode = (transId: string) => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: Params) => {
      const response = await axios.post<SapFlow>("/sapflow/" + transId + "/itemnode", params);
      return response.data;
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['transactions'], { refetchInactive: true });
        queryClient.invalidateQueries(['sapFlowView', transId], { refetchInactive: true });
      },
    }
  );
};

export default useCreateTransactionItemNode;
