import React, { useState } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Typography } from '@progress/kendo-react-common';
import { SubmitButton } from '../../form';
import { useDataCollectionWizardState } from '../../../context/dataCollectionWizard';
import TransitionAnimation from '../../../components/TransitionAnimation';
import { Loader } from '@progress/kendo-react-indicators';
import { useValidateCollection } from '../../../hooks/data_wrangling';

const EnterPath: React.FC = () => {
  const { dispatch, state } = useDataCollectionWizardState();
  const [path, setPath] = useState('');
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const validateCollection = useValidateCollection();

  const handleNext = async () => {
    try {
      const result = await validateCollection.mutateAsync({ path, collectionType: state.configType });
      if (result.success) {
        dispatch({
          type: 'VALIDATE_PATH',
          payload: { path },
        });
      } else {
        setValidationErrors(result.missingConstraints);
      }
    } catch (error) {
      setValidationErrors(['An error occurred while validating the path']);
    }
  };

  return (
    <TransitionAnimation>
      <div className="text-center">
        <Typography.h5 style={{ margin: '25px' }}>Enter Path</Typography.h5>

        <div style={{ width: '500px', margin: '0 auto' }}>
          <Input value={path} onChange={(e) => setPath(e.value)} placeholder="Enter path..." />

          {validationErrors.length > 0 && (
            <div className="text-danger mt-2">
              {validationErrors.map((error, index) => (
                <div key={index}>{error}</div>
              ))}
            </div>
          )}
        </div>

        <div className="pt-3">
          {validateCollection.isLoading ? (
            <Loader size="medium" />
          ) : (
            <SubmitButton label="Next" uppercase={false} full={false} disabled={!path} onClick={handleNext} />
          )}
        </div>
      </div>
    </TransitionAnimation>
  );
};

export default EnterPath;
