import React, { useEffect, useState, useRef, useMemo, useCallback, useImperativeHandle, forwardRef } from 'react';
import debounce from 'lodash.debounce';
import { Loader } from '@progress/kendo-react-indicators';
import { useQueryClient } from 'react-query';
import { ViewLayer, PotreePointSizing, PotreePointShape } from '../../../types';
import { Vector3, compareVectors } from '../../../types/Vector';
import { isIOS } from '../../../common/device';
import { DataDeliveryObject, Objects } from './Objects';
import { getProjUnits, unitToPotreeLengthUnits } from '../../../common/proj4Helper';
import { InteractionMode, useConsumeDataDeliveryState } from '../../../context/dataDeliveryContext';
import usePotreeInteractions from './usePotreeInteractions';
import CameraPositionTracker from './CameraPositionTracker';
import { useViewLayer } from '../../../hooks/viewerconfig';
import { updateLayerParam } from '../../../common/viewerConfigHelper';
import { FileNode } from '../../../types/DataDelivery';

// To avoid transpiler error on window.Potree  https://stackoverflow.com/questions/56457935/typescript-error-property-x-does-not-exist-on-type-window
declare const window: any;

const Potree = window.Potree;
const THREE = window.THREE;

let potreeViewer: any = null;

interface Props {
  show?: boolean;
  layers?: ViewLayer[];
  handleCameraMoved: (threeScene: any) => void;
}

type Ref = {
  zoomToFileNode: (fileNode: FileNode) => void;
  //changeNodeVisibility: (layer: any, visible: boolean) => void;
} | null;

const PotreeViewer = forwardRef<Ref, Props>((props, ref) => {
  const { dispatch, units, interactionMode, openedFileNode } = useConsumeDataDeliveryState();
  const queryClient = useQueryClient();
  const openedViewLayerQuery = useViewLayer(null, openedFileNode?.viewLayer?.id);
  const [openedViewLayer, setOpenedViewLayer] = useState<ViewLayer>(null);
  const [viewConfigUpdatingThrottle, setViewConfigUpdatingThrottle] = useState<boolean>(false);
  const potreeContainerDiv = useRef(null);
  const [statePotreeViewer, setStatePotreeViewer] = useState<any>(null);
  const { handleStartMeasurement } = usePotreeInteractions(statePotreeViewer);
  const [appearance3D, setAppearance3D] = useState({
    pointBudget: 3000000,
    fov: 60,
    eyeDomeLighting: {
      enabled: false,
      radius: 1.4,
      strength: 1.4,
      opacity: 1,
    },
    highQuality: false,
    pointSize: 0.5,
    pointSizing: PotreePointSizing.ADAPTIVE,
    pointShape: PotreePointShape.SQUARE,
  });
  const previousInteractionModeRef = useRef(null);
  const [positionInitialized, setPositionInitialized] = useState(false);
  const layerComponentRefs = React.useRef<any>({});
  const layersRef = React.useRef<any>(props.layers);
  const openedViewLayerRef = React.useRef<any>(null);

  const [refKeys, setRefKeys] = useState<string[]>([]);

  useEffect(() => {
    // initialize Potree viewer
    const viewerElem = potreeContainerDiv.current;
    if (statePotreeViewer === null) {
      potreeViewer = new Potree.Viewer(viewerElem, {
        useDefaultRenderLoop: true,
      });
    } else {
      potreeViewer = statePotreeViewer;
      potreeViewer.scene.clear();
    }

    const axesHelper = new THREE.AxesHelper(250);
    potreeViewer.scene.scene.add(axesHelper);

    potreeViewer.setEDLEnabled(true);
    potreeViewer.setFOV(65);
    potreeViewer.setPointBudget(10 * 1000 * 1000);
    potreeViewer.setBackground(/*displayCesium || displayLeaflet ? */ null);
    if (!isIOS) {
      potreeViewer.useHQ = true;
    }
    potreeViewer.setControls(potreeViewer.orbitControls);

    potreeViewer.loadGUI(() => {
      potreeViewer.setLanguage('en');
    });
    setStatePotreeViewer(potreeViewer);
    dispatch({type: "SET_POTREE_ACTIVE", payload: true});
    return ()=>{
      dispatch({type: "SET_POTREE_ACTIVE", payload: false});
    }
  }, []);

  useEffect(() => {
    // TODO: find out where to get this fro:

    potreeViewer.setDescription(null);
    potreeViewer.setFOV(appearance3D.fov);
    potreeViewer.setPointBudget(appearance3D.pointBudget);
    if (!isIOS) {
      potreeViewer.useHQ = appearance3D.highQuality;
    }
  }, []);

  // INITIAL POSITIONING
  useEffect(() => {
    const viewLayer = openedViewLayerQuery.data;
    openedViewLayerRef.current = openedViewLayerQuery.data;
    setOpenedViewLayer(viewLayer);
  }, [openedViewLayerQuery.isSuccess, openedViewLayerQuery.data, openedViewLayerQuery.isRefetching]);

  // INITIAL POSITIONING
  useEffect(() => {
    if (positionInitialized) return;
    if (openedFileNode && !openedViewLayer) return;
    if (openedViewLayer) {
      if (
        openedViewLayer.paramsMap?.camera &&
        openedViewLayer.paramsMap.camera.position &&
        openedViewLayer.paramsMap.camera.position
      ) {
        potreeViewer.scene.view.position.set(
          openedViewLayer.paramsMap.camera.position.x,
          openedViewLayer.paramsMap.camera.position.y,
          openedViewLayer.paramsMap.camera.position.z
        );
        potreeViewer.scene.view.lookAt(
          openedViewLayer.paramsMap.camera.target.x,
          openedViewLayer.paramsMap.camera.target.y,
          openedViewLayer.paramsMap.camera.target.z
        );
        console.log('Repositionning');
        setPositionInitialized(true);
        return;
      }
    }
    // Zoom to first layer available
    const loadedLayerIds = Object.keys(layerComponentRefs.current);
    if (loadedLayerIds.length) {
      layerComponentRefs.current[loadedLayerIds[0]].zoomTo();
    }
    console.log('Repositionning');
    setPositionInitialized(true);
  }, [openedViewLayer, refKeys, positionInitialized]);

  useEffect(() => {
    if (statePotreeViewer && units) {
      potreeViewer.lengthUnitDisplay = unitToPotreeLengthUnits(units);
    }
  }, [statePotreeViewer, units]);

  useEffect(() => {
    if (!statePotreeViewer) return;
    if (previousInteractionModeRef.current !== interactionMode) {
      if (previousInteractionModeRef.current === InteractionMode.NAVIGATION) {
        handleStartMeasurement(interactionMode);
      }
    }
    previousInteractionModeRef.current = interactionMode;
  }, [statePotreeViewer, interactionMode]);

  useImperativeHandle(ref, () => ({
    zoomToFileNode,
    //changeNodeVisibility,
  }));

  const zoomToFileNode = (fileNode: FileNode) => {
    //if (potreeNodes[layer.id]) {
    //  potreeViewer.zoomTo(potreeNodes[layer.id], 1, 1000);
    //}
  };

  //const changeNodeVisibility = (layer: any, visible: boolean) => {
  //  if (potreeNodes[layer.id]) {
  //    potreeNodes[layer.id].visible = visible && props.show;
  //  }
  //};

  const handleCameraPositionChanged = (position: Vector3, target: Vector3) => {
    if (openedViewLayer) {
      layersRef.current.forEach((layer: any) => {
        if (layer.id === openedViewLayer.id) {
          const newViewLayer = JSON.parse(JSON.stringify(layer));
          updateLayerParam(newViewLayer, 'camera', { position, target });
          queryClient.setQueryData(['viewLayer', layer.id], newViewLayer);
        }
      });
    }
  };

  useEffect(() => {
    layersRef.current = props.layers;
  }, [props.layers]);
  useEffect(() => {
    openedViewLayerRef.current = openedViewLayer;
  }, [openedViewLayer]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative',
        opacity: props.show ? 1 : 0,
        pointerEvents: props.show ? 'auto' : 'none',
        zIndex: props.show ? 2 : -1,
      }}
    >
      <CameraPositionTracker potreeViewer={statePotreeViewer} onCameraPositionChange={handleCameraPositionChanged} />
      <div className="position-relative w-100 h-100">
        <div
          className="position-absolute top-0 end-0 zindex-popover p-3 mt-1"
          style={{
            zIndex: 1,
            display: viewConfigUpdatingThrottle ? 'block' : 'none',
          }}
        >
          <Loader size="small" type={'converging-spinner'} themeColor={'secondary'} />
        </div>
        <div id="potree_render_area" ref={potreeContainerDiv}></div>

        <Objects>
          {props.layers &&
            props.layers.length > 0 &&
            props.layers.map((viewLayer) => {
              if (!viewLayer) return null;
              return (
                <DataDeliveryObject
                  ref={(el) => (layerComponentRefs.current[viewLayer.id] = el)}
                  viewLayer={viewLayer}
                  key={viewLayer?.id}
                  show={true}
                  zIndex={1}
                  appearance3D={appearance3D}
                  potreeViewer={statePotreeViewer}
                ></DataDeliveryObject>
              );
            })}
        </Objects>
      </div>

      <div
        className=""
        style={{
          position: 'absolute',
          zIndex: '1000',
          display: 'flex',
          flexDirection: 'column',
          bottom: 0,
          left: '50%',
        }}
      ></div>
    </div>
  );
});

PotreeViewer.displayName = 'PotreeViewer';

export default PotreeViewer;
