import { useMutation } from 'react-query';
import { useAxiosInstance } from '../../common/';

interface Params {
  sapflowid: string;
  processnodeid?: string;
}

const useRunSapFlow = () => {
  const axios = useAxiosInstance();

  return useMutation(async (params: Params) => {
    return axios.post("/sapflow/" + params.sapflowid + "/run_async", {}, {params: {processNodeId: params.processnodeid}});
  },
  {
    onSuccess: () => {
      //queryClient.invalidateQueries(['transactions'], { refetchInactive: true });
    },
  });
};

export default useRunSapFlow;
