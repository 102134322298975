import React, { useEffect, useState, useRef } from 'react';
import { Checkbox } from '@progress/kendo-react-inputs';
import { SketchPicker } from 'react-color';
import { Input } from '../../../../../../components/form';
import { capitalize } from '../../../../../../common/stringHelper';

interface ClassificationToggleProps {
  index: number;
  editingName: boolean;
  classificationData: any;
  onChange: any;
  onEditName: any;
}

const ClassificationToggle: React.FC<ClassificationToggleProps> = (props: ClassificationToggleProps) => {
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
  const [color, setColor] = React.useState<any>({ r: 241, g: 112, b: 19, a: 1 });
  const [name, setName] = React.useState<any>('');

  useEffect(() => {
    if (props.classificationData) {
      setName(props.classificationData.name);
      if (props.classificationData.color) {
        setColor({
          r: Math.round(props.classificationData.color[0] * 255),
          g: Math.round(props.classificationData.color[1] * 255),
          b: Math.round(props.classificationData.color[2] * 255),
          a: 1,
        });
      }
    }
  }, [props.index, props.classificationData]);

  const classificationVisibilityAvailable =
    props.classificationData !== null &&
    props.classificationData !== undefined &&
    props.classificationData.visible !== null &&
    props.classificationData.visible !== undefined;

  const defaultVisible = classificationVisibilityAvailable ? props.classificationData.visible : true;

  return (
    <div className="d-flex align-items-center">
      <Checkbox
        className="layers-checkbox"
        defaultChecked={defaultVisible}
        label={''}
        onChange={(e) => {
          props.onChange(props.index, props.classificationData?.name, e.value, [
            color.r / 255,
            color.g / 255,
            color.b / 255,
            1,
          ]);
        }}
      />
      <div
        style={{ display: 'flex', alignItems: 'center' }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            props.onChange(props.index, name, props.classificationData?.visible, [
              color.r / 255,
              color.g / 255,
              color.b / 255,
              1,
            ]);
          }
        }}
        onBlur={(event) => {
          props.onChange(props.index, name, props.classificationData?.visible, [
            color.r / 255,
            color.g / 255,
            color.b / 255,
            1,
          ]);
        }}
      >
        <span style={{ fontWeight: 'bold', paddingRight: '0.5rem', paddingLeft: '1rem' }}>{`${props.index} - `}</span>
        {!props.editingName && (
          <span style={{ fontWeight: 'bold', paddingRight: '0.5rem', paddingLeft: '1rem' }} onClick={props.onEditName}>
            {capitalize(props.classificationData?.name)}
          </span>
        )}
        {props.editingName && (
          <Input
            style={{ fontWeight: 'bold', flex: '1', paddingRight: '0.5rem' }}
            inputStyle={{}}
            type="text"
            name=""
            value={name}
            onChange={(e) => {
              setName(e.value);
            }}
          ></Input>
        )}
      </div>
      <div
        style={{
          padding: '4px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
          marginLeft: 'auto',
          height: 'fit-content',
        }}
        onClick={() => {
          setDisplayColorPicker(!displayColorPicker);
        }}
      >
        <div
          style={{
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
          }}
        />
      </div>
      {displayColorPicker ? (
        <div style={{ position: 'absolute', zIndex: 100 }}>
          <div
            style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }}
            onClick={() => {
              setDisplayColorPicker(false);
            }}
          />
          <SketchPicker
            color={color}
            onChange={(newColor) => {
              props.onChange(props.index, props.classificationData?.name, props.classificationData?.visible, [
                newColor.rgb.r / 255,
                newColor.rgb.g / 255,
                newColor.rgb.b / 255,
                1,
              ]);
              //props.onColorChange(newColor.rgb);
              setColor(newColor.rgb);
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ClassificationToggle;
