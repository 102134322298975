import { useQueryClient, useMutation } from 'react-query';
import { useAxiosInstance } from '../../common';
import { ClientProject } from '../../../types';

interface Params {
    name: string;
    description: string;
    path: string;
}

const useAddClientProject = (orgId: string) => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: Params) => {
        console.log("Params " + JSON.stringify(params));
      const response = await axios.post<ClientProject>(`data_wrangling/project`, params);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['clientProjects', orgId], { refetchInactive: true });
      },
    }
  );
};

export default useAddClientProject;
