import React, { useEffect } from 'react';
import { RangeSlider, Slider } from '@progress/kendo-react-inputs';
import { CLASSIFICATION, ELEVATION, PCAttributeVisualization, ViewLayer } from '../../../../../../types';
import ClassificationToggle from './ClassificationToggle';
// To avoid transpiler error on window.Potree  https://stackoverflow.com/questions/56457935/typescript-error-property-x-does-not-exist-on-type-window
declare const window: any;

interface ClassificationControlsProps {
  layer: ViewLayer;
  onLayerUpdate?: (layer: ViewLayer, persistent?: boolean) => void;
  onAttributeValueChange: (visualizationParamPath: string, value: any) => void;
  onAttributeRangeChange: (visualizationParamPath: string, newMin: any, newMax: any) => void;
  onClassificationUpdate: (
    layer: ViewLayer,
    attributeName: string,
    id: number,
    name: string,
    visible: boolean,
    color: number[]
  ) => void;
}

const ClassificationControls: React.FC<ClassificationControlsProps> = (props: ClassificationControlsProps) => {
  const [editingAttributeName, setEditingAttributeName] = React.useState(null);
  const visualization = props.layer?.paramsMap?.visualization;
  const classification: PCAttributeVisualization = visualization?.attributeValues
    ? visualization?.attributeValues[CLASSIFICATION]
    : null;

  if (!classification || !classification.classificationSchemas) {
    return null;
  }
  const classificationIds = Object.keys(classification.classificationSchemas);
  const classificationSchemas = classification.classificationSchemas;
  return (
    <div className="d-flex flex-column m-2">
      {classificationIds.map((id, index) => {
        const classificationData = classificationSchemas[id];
        return (
          <ClassificationToggle
            key={id}
            index={parseInt(id)}
            classificationData={classificationData}
            editingName={editingAttributeName === id}
            onEditName={() => {
              setEditingAttributeName(id);
            }}
            onChange={(id: number, name: string, visible: boolean, color: number[]) => {
              props.onClassificationUpdate(props.layer, CLASSIFICATION, id, name, visible, color);
              setEditingAttributeName(null);
            }}
          />
        );
      })}
    </div>
  );
};

export default ClassificationControls;
