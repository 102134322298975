import { useQuery } from 'react-query';
import { useAxiosInstance } from '../common';
import { SapFlowGroup } from '../../types';

const useSapGroups = () => {
  const axios = useAxiosInstance();
  return useQuery(['sapGroups'], async () => {
    const result = await axios.get<SapFlowGroup[]>('file_sapgroup');
    return result.data;
  });
};

export default useSapGroups;
