import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import { ViewLayer } from '../../../types';
import { FileNode, FileNodeStatus, FileNodeType } from '../../../types/DataDelivery';
import Map from './Map';
import { Layers, ViewLayerOLV2, BaseMapLayer } from './Layers';
import { Controls, FullScreenControl, MouseTrackerControl } from './Controls';
import MapEvent from 'ol/MapEvent';
import { fromLonLat, toLonLat } from 'ol/proj';
import OLMap from 'ol/Map';
import 'ol/ol.css';
import { Polygon } from 'ol/geom';
import { Feature } from 'ol';
import DataDeliveryNavigationOptions from '../../../types/DataDelivery/FileNodeNavigationOptions';

//https://openlayers.org/en/latest/examples/center.html

declare const window: any;
declare const proj4: any;

const THREE = window.THREE;

interface Props {
  rootFileNode: FileNode;
  selectedFileNode: FileNode;
  focusedFileNode: FileNode;
  layers: ViewLayer[];
  selectedLayer: ViewLayer;
  show?: boolean;
  coordinates?: number[];
  height?: number;
  navigationOptions: DataDeliveryNavigationOptions;
  onLayerUpdated?: (layer: ViewLayer) => void;
  onFileNodeHovered?: (fileNode: FileNode) => void;
  onFileNodeSelected?: (fileNode: FileNode) => void;
}

type Ref = {
  zoomToFileNode: (fileNode: any) => void;
} | null;
const OLViewer = forwardRef<Ref, Props>((props, ref) => {
  const [mapInitialized, setMapInitialized] = useState<boolean>(false);
  const [olMap, setOLMap] = useState<OLMap>(null);
  const [loadingMapData, setLoadingMapData] = useState<boolean>(false);
  const [cameraPosition, setCameraPosition] = useState<any>([-8726319.082769498, 5281472.8611597875]);
  const [cameraZoom, setCameraZoom] = useState<number>(6.25);
  const [lastFileNodeZoomedTo, setLastFileNodeZoomedTo] = useState<FileNode>(null);

  useEffect(() => {
    if (props.selectedFileNode && props.selectedFileNode.geolocation) {
      if (lastFileNodeZoomedTo === null || lastFileNodeZoomedTo.id !== props.selectedFileNode.id) {
        zoomToFileNode(props.selectedFileNode);
      }
    }
  }, [props.selectedFileNode, olMap]);

  const handleMapEvent = (event: MapEvent, map: OLMap) => {
    if (event.type === 'postrender') {
      setMapInitialized(true);
      if (!olMap) {
        setOLMap(map);
      }
    } else if (event.type === 'loadstart') {
      setLoadingMapData(true);
    } else if (event.type === 'loadend') {
      setLoadingMapData(false);
    } else if (event.type === 'pointermove') {
      const pixel = map.getEventPixel((event as any).originalEvent);
      let fileNodeFound: FileNode = null;
      if (!map.hasFeatureAtPixel(pixel)) {
        props.onFileNodeHovered(null);
        return;
      }

      map.forEachFeatureAtPixel((event as any).pixel, function (f: Feature) {
        const featureFileNode = f.getProperties()['fileNode'];
        if (!fileNodeFound && featureFileNode) {
          if (f.getProperties()['interactable']) {
            fileNodeFound = featureFileNode;
          }
        }
      });

      if (fileNodeFound) {
        (map as any).getTarget().style.cursor = 'pointer';
        props.onFileNodeHovered(fileNodeFound);
      } else {
        (map as any).getTarget().style.cursor = '';
        props.onFileNodeHovered(null);
      }
    } else if (event.type === 'click') {
      const pixel = map.getEventPixel((event as any).originalEvent);
      let fileNodeFound: FileNode = null;
      map.forEachFeatureAtPixel((event as any).pixel, function (f: Feature) {
        const featureFileNode = f.getProperties()['fileNode'];
        if (!fileNodeFound && featureFileNode) {
          if (f.getProperties()['interactable']) {
            fileNodeFound = featureFileNode;
          }
        }
      });

      if (fileNodeFound && (fileNodeFound.isDir || fileNodeFound.status === FileNodeStatus.READY)) {
        props.onFileNodeSelected(fileNodeFound);
      }
    }
  };

  const handleCameraMoved = (newPos: any, newZoom: number) => {
    //setCameraPosition(newPos);
    //console.log('Position: ' + JSON.stringify(newPos));
    //console.log('Position Lon/Lat: ' + JSON.stringify(toLonLat(newPos)));
    //console.log('Zoom: ' + newZoom);
  };

  const zoomToFileNode = (fileNode: FileNode) => {
    if (fileNode.geolocation) {
      if (olMap) {
        const sitePoints = [];
        sitePoints.push(fromLonLat([fileNode.geolocation.lonMin, fileNode.geolocation.latMin]));
        sitePoints.push(fromLonLat([fileNode.geolocation.lonMin, fileNode.geolocation.latMax]));
        sitePoints.push(fromLonLat([fileNode.geolocation.lonMax, fileNode.geolocation.latMax]));
        sitePoints.push(fromLonLat([fileNode.geolocation.lonMax, fileNode.geolocation.latMin]));
        sitePoints.push(sitePoints[0]);
        olMap.getView().fit(new Polygon([sitePoints]), { padding: [50, 50, 50, 520], duration: 1000 });
      } else {
        setCameraPosition(fromLonLat([fileNode.geolocation.lonCenter, fileNode.geolocation.latCenter]));
        console.log('Centering: ' + fileNode.geolocation.lonCenter + ', ' + fileNode.geolocation.latCenter);
      }
      setLastFileNodeZoomedTo(fileNode);
    }
  };

  useImperativeHandle(ref, () => ({
    zoomToFileNode,
  }));

  return (
    <div
      id="olContainer"
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: props.height + 'px',
        backgroundColor: 'rgb(242,239,233)',
        opacity: props.show ? 1 : 0,
        zIndex: props.show ? 1 : -1,
      }}
    >
      {/*<OLMenuBar
        selectedLayer={props.selectedLayer}
        onLayerUpdated={props.onLayerUpdated}
        handleStyleChange={handleOLStyleChange}
    ></OLMenuBar>*/}
      <Map
        center={cameraPosition}
        zoom={cameraZoom}
        handleCameraMoved={handleCameraMoved}
        handleMapEvent={handleMapEvent}
      >
        <Layers>
          <BaseMapLayer baseMap={props.navigationOptions?.baseMap} zIndex={0} show={true} />
          {props.selectedFileNode &&
            props.selectedFileNode.children &&
            props.selectedFileNode.children.map((fileNode: FileNode, index) => {
              if (fileNode.isSupport && fileNode.supportingFile) {
                // FileNodes that are supporting another file shall be displayed for that FileNode.
                return;
              }
              return (
                <ViewLayerOLV2
                  ref={(el: any) => {
                    //layerRefs.current[layer.id] = el;
                  }}
                  fileNode={fileNode}
                  key={fileNode.name + '_' + index}
                  show={true}
                  zIndex={1}
                  highlight={fileNode.id === props.focusedFileNode?.id}
                ></ViewLayerOLV2>
              );
            })}
        </Layers>
        <Controls>
          <FullScreenControl />
          <MouseTrackerControl />
        </Controls>
      </Map>
      <div
        style={{
          display: loadingMapData ? 'flex' : 'none',
          position: 'absolute',
          bottom: 0,
          right: 0,
          zIndex: 10,
          padding: '1rem',
          borderTopLeftRadius: '1rem',
          backgroundColor: 'rgba(200,200,200,0.6)',
        }}
      >
        <Loader size="small" type={'converging-spinner'} themeColor={'primary'} />
      </div>

      <div
        className=""
        style={{
          position: 'absolute',
          zIndex: '1000',
          display: 'flex',
          flexDirection: 'column',
          bottom: 20,
          left: '20px',
        }}
      >
        <div id="location"></div>
      </div>
    </div>
  );
});

OLViewer.displayName = 'OLViewer';

export default OLViewer;
