import { useMutation, useQueryClient } from 'react-query';
import { SapFlow } from '../../../types';
import { useAxiosInstance } from '../../common/';

interface Params {
  transid: string;
}

const useDeleteTransaction = (onFinish: () => void) => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: Params) => {
      const result = await axios.delete<string>("/sapflow/" + params.transid);
      return result.data;
    },
    {
      onSuccess: (_data, variables) => {
        // Invalidate projects because the associated project might have gotten deleted.
        queryClient.invalidateQueries(['projects'], { refetchInactive: true });
        // Remove the transaction from cached data.
        queryClient.removeQueries(['transaction', variables.transid]);
        onFinish();
      },
      onError: () => {
        onFinish();
      },
    }
  );
};

export default useDeleteTransaction;
