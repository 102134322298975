import { useQuery, useQueryClient } from 'react-query';
import { FileNode } from '../../types/DataDelivery';
import { useAxiosInstance } from '../common';

export const useFileNodeQueries = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();

  // Fetch the root structure by orgId
  const fetchRootStructure = async (orgId: string): Promise<FileNode> => {
    console.log("Querying ROOT");
    const { data } = await axios.get<FileNode>(`/filenode/root_structure`);
    return data;
  };

  // Fetch the filenode overview by rootFileNodeId
  const fetchFileNodeOverview = async (fileNodeId: string): Promise<FileNode | null> => {
    if (fileNodeId === null || fileNodeId === undefined || fileNodeId === "00000000-0000-0000-0000-000000000000") {
      return null;
    }
    console.log("Querying OVERVIEW");
    const { data } = await axios.get<FileNode>(`/filenode/overview/${fileNodeId}`);
    return data;
  };

  return {
    // Query for root structure
    useRootStructureQuery: (orgId: string, enabled: boolean) =>
      useQuery(['rootStructure', orgId], () => fetchRootStructure(orgId), {
        enabled: (!!orgId && enabled), // Ensure the query only runs if orgId is provided
      }),

    // Query for filenode overview
    useFileNodeOverviewQuery: (fileNodeId: string) =>
      useQuery(['fileNodeStructure', fileNodeId], () => fetchFileNodeOverview(fileNodeId), {
        enabled: fileNodeId !== null && fileNodeId !== undefined && fileNodeId !== "00000000-0000-0000-0000-000000000000",
      }),

    // Manually refetch root structure
    refetchRootStructure: async (orgId: string) => {
      return queryClient.fetchQuery(['rootStructure', orgId], () => fetchRootStructure(orgId));
    },

    // Manually refetch filenode overview
    refetchFileNodeOverview: async (fileNodeId: string) => {
      return queryClient.fetchQuery(['fileNodeStructure', fileNodeId], () =>
        fetchFileNodeOverview(fileNodeId)
      );
    },

    // Invalidate specific queries
    invalidateRootStructure: (orgId: string) =>
      queryClient.invalidateQueries(['rootStructure', orgId]),

    invalidateFileNodeOverview: (rootFileNodeId: string) =>
      queryClient.invalidateQueries(['fileNodeStructure', rootFileNodeId]),

    // Is Fetching statuses
    isFetchingRootStructure: (orgId: string) =>
      queryClient.isFetching({queryKey: ['rootStructure', orgId]}),
    isFetchingFileNodeOverview: (fileNodeId: string) =>
      queryClient.isFetching({queryKey: ['fileNodeStructure', fileNodeId]})
  };
};
