import React, { useEffect, useState } from 'react';
import SapCategory from '../../../../types/SapCategory';
import { useConsumeDataProcessorState } from '../../../../context/dataProcessorContext';
import { useCategories } from '../../../../hooks/sapflow';
interface Tool {
  name: string;
  category: string;
  canUpgrade: boolean;
  selected: boolean;
}
const ToolPanel: React.FC = () => {
  const { dispatch, selectedCategory } = useConsumeDataProcessorState();
  const cateroriesQuery = useCategories();

  const [categories, setCategories] = useState<SapCategory[]>([
    { name: 'Imagery Tools', id: '1' },
    { name: 'Point Cloud Tools', id: '2' },
    { name: 'Analysis Tools', id: '3' },
  ]);
  const [upgradeableSaps, setUpgradeableSaps] = useState<SapCategory[]>([
    { name: 'Riegl Processing', id: '1' },
    { name: 'Trajectory Processing', id: '2' },
    { name: 'Strip Alignment', id: '3' },
    { name: 'Hydraulic Analysis', id: '4' },
  ]);

  useEffect(() => {
    if (cateroriesQuery.data) {
      setCategories(cateroriesQuery.data);
    }
  }, [cateroriesQuery.data, cateroriesQuery.isRefetching]);

  return (
    <div
      className=""
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        position: 'relative',
        color: 'white',
        padding: '4px',
      }}
    >
      {categories.map((category) => {
        const selected = category.id === selectedCategory?.id;
        return (
          <div
            key={category.id}
            style={{
              margin: '8px 0px',
              cursor: 'pointer',
            }}
            onClick={() => {
              dispatch({ type: 'SET_SELECTED_CATEGORY', payload: category });
            }}
          >
            <div
              className="animated-background"
              style={{
                padding: '8px 16px',
                backgroundColor: selected ? '#FF4C4C' : 'inherit',
              }}
            >
              <h3 style={{ margin: 0, fontSize: '18px' }}>{category.name}</h3>
            </div>
          </div>
        );
      })}

      <div
        style={{
          backgroundColor: '#444',
        }}
      >
        {upgradeableSaps.map((upgradeableSap) => (
          <div
            key={upgradeableSap.id}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#666',
              margin: '6px 0',
              padding: '8px 16px',
              borderRadius: '4px',
            }}
          >
            <span style={{ fontSize: '16px' }}>{upgradeableSap.name}</span>
            <button
              style={{
                backgroundColor: '#FF4C4C',
                color: '#FFF',
                border: 'none',
                padding: '4px 8px',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              Upgrade
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ToolPanel;
