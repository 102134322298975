import { SapCategory, SapFlow, SapFlowConfig, SapFlowProject, SapFlowType } from '../../types';

export enum SapFlowWizardStep {
  SELECT_SAPFLOW = 0,
  PROVIDE_DETAILS = 1,
  UPLOAD = 2,
}

export interface SapFlowWizardState {
  step: SapFlowWizardStep;
  projectName: string | null;
  project: SapFlowProject | null;
  sapFlowConfig: SapFlowConfig | null;
  sapFlow: SapFlow | null;
  processName: string | null;
  processDescription: string | null;
}

export const initialState: SapFlowWizardState = {
  step: SapFlowWizardStep.SELECT_SAPFLOW,
  projectName: null,
  project: null,
  sapFlowConfig: null,
  sapFlow: null,
  processName: null,
  processDescription: null,
};
