import { TusUploadStatus } from ".";
import { Upload } from 'tus-js-client'; // Adjust the path as needed
import { FileNode } from "./DataDelivery";

export enum UploadType {
  FILENODE = "FILENODE",
  SAPFLOW = "SAPFLOW"
}

export interface TusUpload {
  fileId: string;
  file: File;
  url: string;
  progress: number;
  status: TusUploadStatus;
  errorMessage?: string;
  totalSize: number;
  uploaded: number;
  upload?: Upload;
  uploadType: UploadType;
}

export interface TusFileNodeUpload extends TusUpload {
  path: string;
  parent: FileNode;
}

export interface TusSapFlowUpload extends TusUpload {
  projectId: string;
  sapflowId: string;
  itemNodeId: string;
}
  
export const extractTusUploadType=(upload: TusUpload) => {
  if ((upload as TusFileNodeUpload).path !== undefined && 
         (upload as TusFileNodeUpload).parent !== undefined) {
          return UploadType.FILENODE;
  }
  else if((upload as TusSapFlowUpload).projectId !== undefined && 
           (upload as TusSapFlowUpload).sapflowId !== undefined && 
           (upload as TusSapFlowUpload).itemNodeId !== undefined) {
            return UploadType.SAPFLOW;
  }
  else {
    return null;
  }
}