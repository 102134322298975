import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AuthorityLevel, AuthorityType, DashboardTransaction, ESapFlowStatus } from '../../types';
import { useUser } from '../../hooks/authentication';
import { StyledTextButton } from '../../components/styled';
import { RestrictedStyledTextButton, RestrictedA, RestrictedLink } from '../../components/restricted';
import { toSapFlow } from '../../converters/dashboardTransaction';
import DownloadButton from './DownloadButton';

const StyledErrorTextButton = styled(StyledTextButton)`
  color: var(--geosap-error);
  &:hover {
    color: var(--geosap-error-link-hover);
  }
`;

interface ActionsCellProps {
  dataItem: DashboardTransaction;
  onErrorClick: (dataItem: DashboardTransaction) => void;
  onStartClick: (transid: string) => void;
  onDeleteClick: (transid: string) => void;
  onDownloadClick: (transid: string) => void;
}

import { useAxiosInstance } from '../../hooks/common/';

const ActionsCell: React.FC<ActionsCellProps> = ({
  dataItem,
  onErrorClick,
  onStartClick,
  onDeleteClick,
  onDownloadClick,
}) => {
  const { getUser } = useUser();
  const axios = useAxiosInstance();
  const filesIdToDownload =
    !dataItem.outputFiles || dataItem.outputFiles.length === 0
      ? null
      : dataItem.outputFiles
          .map((file) => {
            return file.id;
          })
          .join();

  if (dataItem.errored) {
    return (
      <td>
        <div className="d-flex gap-3">
          <div className="d-flex gap-3">
            <StyledErrorTextButton type="button" onClick={() => onErrorClick(dataItem)}>
              View Errors
            </StyledErrorTextButton>
            <RestrictedStyledTextButton
              authorityType={AuthorityType.TRANSACTION_AUTHORITY}
              authorityLevel={AuthorityLevel.DELETE}
              type="button"
              onClick={() => onDeleteClick(dataItem.id)}
            >
              Delete
            </RestrictedStyledTextButton>
          </div>
        </div>
      </td>
    );
  } else if (dataItem.status === ESapFlowStatus.TRANSACTION_CREATED) {
    return (
      <td>
        <div className="d-flex gap-3">
          <>
            <Link to={`/sapflow`} state={{ sapflow: toSapFlow(dataItem) }}>
              Upload Files
            </Link>
            <RestrictedLink
              to={`/dashboard`}
              onClick={() => onDeleteClick(dataItem.id)}
              authorityType={AuthorityType.TRANSACTION_AUTHORITY}
              authorityLevel={AuthorityLevel.DELETE}
            >
              Delete
            </RestrictedLink>
          </>
        </div>
      </td>
    );
  } else if (dataItem.status === ESapFlowStatus.TRANSACTION_FINISHED) {
    return (
      <td>
        <div className="d-flex gap-3">
          <>
            <Link
              to={`/viewer/${dataItem.id}`}
              className={dataItem.visualizableFiles.length > 0 ? '' : 'link-disabled'}
            >
              View
            </Link>
            <Link to={`/details/${dataItem.id}`}>Details</Link>
            <RestrictedA
              authorityType={AuthorityType.TRANSACTION_AUTHORITY}
              authorityLevel={AuthorityLevel.DELETE}
              href=""
              onClick={(e) => {
                e.preventDefault();
                onDeleteClick(dataItem.id);
              }}
            >
              Delete
            </RestrictedA>
            <DownloadButton
              downloading={dataItem.downloading}
              onDownload={() => {
                onDownloadClick(dataItem.id);
              }}
              disabled={filesIdToDownload === null}
              message={filesIdToDownload === null ? 'No output files for transaction.' : ''}
            />
          </>
        </div>
      </td>
    );
  } else if (dataItem.status === ESapFlowStatus.TRANSACTION_READY) {
    // Transaction files uploaded but not started.
    return (
      <td>
        <div className="d-flex gap-3">
          <>
            <Link to={`/details/${dataItem.id}`}>Details</Link>
            <Link to={`/dashboard`} onClick={() => onStartClick(dataItem.id)}>
              Start
            </Link>
            <RestrictedLink
              to={`/dashboard`}
              onClick={() => onDeleteClick(dataItem.id)}
              authorityType={AuthorityType.TRANSACTION_AUTHORITY}
              authorityLevel={AuthorityLevel.DELETE}
            >
              Delete
            </RestrictedLink>
          </>
        </div>
      </td>
    );
  } else {
    return (
      <td>
        <div className="d-flex gap-3">
          <>
            <Link to={`/details/${dataItem.id}`}>Details</Link>
            <RestrictedLink
              to={`/dashboard`}
              className={'link-disabled'}
              authorityType={AuthorityType.TRANSACTION_AUTHORITY}
              authorityLevel={AuthorityLevel.DELETE}
            >
              Delete
            </RestrictedLink>
          </>
        </div>
      </td>
    );
  }
};

export default ActionsCell;
