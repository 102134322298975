import React, { useContext, useEffect } from 'react';
import { MousePosition } from 'ol/control';
import { createStringXY } from 'ol/coordinate';
import MapContext from '../Map/MapContext';
import { useConsumeDataDeliveryState } from '../../../../context/dataDeliveryContext';

const FullScreenControl: React.FC = () => {
  const { map } = useContext<any>(MapContext);
  const { displayedProjection2D, currentPerspective } = useConsumeDataDeliveryState();

  useEffect(() => {
    if (!map) return;

    const mousePositionControl = new MousePosition({
      className: 'custom-mouse-position',
      target: document.getElementById('olCursorLocation'),
      coordinateFormat: createStringXY(5),
      projection: displayedProjection2D,
    });

    map.controls.push(mousePositionControl);

    return () => map.controls.remove(mousePositionControl);
  }, [map, displayedProjection2D, currentPerspective]);

  return null;
};

export default FullScreenControl;
