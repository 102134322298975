import React from 'react';
import { eyeIcon, eyeSlashIcon, moreVerticalIcon } from '@progress/kendo-svg-icons';
import { SvgIcon } from '@progress/kendo-react-common';
import { useConsumeDataDeliveryState } from '../../../../context/dataDeliveryContext';
import { useAppContext } from '../../../../context/app';
import { ViewLayer } from '../../../../types';

interface ListItemProps {
  viewLayer: ViewLayer;
  //onToggleVisibility: (fileNode: ViewLayer) => void;
  //onExpandFileNode(FileNode: any): void;
  onMoreOptions: (e: React.MouseEvent<HTMLElement>) => void;
}

const ListItem: React.FC<ListItemProps> = ({ viewLayer, onMoreOptions }) => {
  const { dispatch, hoveredLayer } = useConsumeDataDeliveryState();
  const { dispatch: appDispatch } = useAppContext();
  const hovered = viewLayer?.id === hoveredLayer?.id;
  return (
    <div
      className="d-flex align-items-center justify-between p-2 bg-gray-800 rounded-md shadow-md animated-background cursor-pointer"
      style={{ background: hovered ? '#4c4c4c' : 'transparent' }}
      onMouseEnter={() => {
        dispatch({ type: 'SET_HOVERED_LAYER', payload: viewLayer });
      }}
      onMouseLeave={() => {
        dispatch({ type: 'SET_HOVERED_LAYER', payload: null });
      }}
      onClick={(e) => {
        e.stopPropagation();
        dispatch({ type: 'SET_SELECTED_LAYER', payload: viewLayer });
      }}
    >
      {/* Drag handle */}
      <div className="text-gray-500 cursor-move mr-2">⠿</div>
      {/* Visibility toggle */}
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="text-white flex-shrink-0 mx-1"
        aria-label={viewLayer.active ? 'Hide' : 'Show'}
      >
        <SvgIcon icon={viewLayer.active ? eyeIcon : eyeSlashIcon} size="xlarge" color={'white'} />
      </div>
      {/* Label */}
      <span className="mx-1 flex-grow text-white truncate">{viewLayer.displayName}</span>
      {/* More options */}
      <SvgIcon
        style={{ marginLeft: 'auto' }}
        icon={moreVerticalIcon}
        size="large"
        color={'white'}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          onMoreOptions(e);
        }}
      />
    </div>
  );
};

export default ListItem;
