import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useAppContext } from '../../../context/app';
import { ProcessorNavigationMode, useConsumeDataProcessorState } from '../../../context/dataProcessorContext';
import AvailableSapConfigsList from './AvailableSapConfigs';
import SapConfig from './SapConfig';

const Processing = () => {
  const { screenWidth, dispatch: appDispatch } = useAppContext();
  const { dispatch, navigationMode, selectedSapConfig } = useConsumeDataProcessorState();

  const renderContent = () => {
    if (selectedSapConfig) {
      return <SapConfig />;
    } else {
      return <AvailableSapConfigsList />;
    }
  };

  return (
    <div className="d-flex flex-column" style={{ height: '100%' }}>
      {renderContent()}
    </div>
  );
};

export default Processing;
