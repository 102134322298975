import React, { useState } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { SvgIcon } from '@progress/kendo-react-common';
import { xCircleIcon } from '@progress/kendo-svg-icons';
import { FileNode } from '../../../types/DataDelivery';
import Dropzone from '../../Dropzone';
import { SubmitButton } from '../../form';
import {
  FileNodeUploadWizardStep,
  UploadWizardType,
  useConsumeFileNodeUploadWizardState,
} from '../../../context/fileNodeUploadWizard';

interface Props {
  onDone: () => void;
}
const SelectFiles: React.FC<Props> = (props: Props) => {
  const { dispatch, files, type, sapflowId } = useConsumeFileNodeUploadWizardState();

  const handleDrop = (acceptedFiles: File[]) => {
    dispatch({ type: 'SELECT_FILES', payload: { files: acceptedFiles } });
  };

  const removeFile = (fileToRemove: File) => {
    dispatch({ type: 'SELECT_FILES', payload: { files: files.filter((file) => file !== fileToRemove) } });
  };

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
      <Dropzone onDrop={handleDrop}></Dropzone>
      {files.length !== 0 && (
        <div style={{ marginTop: '1rem' }}>
          <h2 style={{ fontSize: '1.25rem', fontWeight: 'semibold', marginBottom: '0.5rem' }}>Uploaded Files</h2>
          <ul style={{ listStyleType: 'disc', paddingLeft: '1.25rem' }}>
            {files.map((file, index) => (
              <li key={index} style={{ color: '#4b5563', display: 'flex', justifyContent: 'space-between' }}>
                {file.name}
                <SvgIcon
                  icon={xCircleIcon}
                  onClick={() => removeFile(file)}
                  size="medium"
                  style={{ fontSize: '1.2rem', position: 'relative', cursor: 'pointer' }}
                ></SvgIcon>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {type === UploadWizardType.FILENODE && (
          <SubmitButton
            label={'Select Destination'}
            onClick={() => {
              dispatch({ type: 'CHANGE_STEP', payload: { step: FileNodeUploadWizardStep.SELECT_DESTINATION } });
            }}
            disabled={files.length === 0}
            style={{ justifySelf: 'flex-end' }}
            xMargin="mx-0"
            full={false}
          ></SubmitButton>
        )}
        {type === UploadWizardType.SAPFLOW && (
          <SubmitButton
            label={'Upload'}
            onClick={() => {
              props.onDone();
            }}
            disabled={files.length === 0}
            style={{ justifySelf: 'flex-end' }}
            xMargin="mx-0"
            full={false}
          />
        )}
      </div>
    </div>
  );
};

export default SelectFiles;
