import React from 'react';
import { Navigate, Routes, Route, useLocation } from 'react-router-dom';
import { useUser } from '../../hooks/authentication';
import {
  Servers,
  Management,
  OrganizationManagement,
  UserManagement,
  DataProject,
  DataProjectProcessing,
} from '../../pages';
import Page from './Page';
import { UserRole } from '../../types';

const PrivateRoutes: React.FC = () => {
  const { userHasRole } = useUser();

  if (!userHasRole(UserRole.ROLE_SYSTEMADMIN)) {
    return <Navigate to="/delivery" replace />;
  }

  return (
    <Routes>
      <Route
        path="/servers"
        element={
          <Page title="Servers overview">
            <Servers />
          </Page>
        }
      />
      <Route
        path="/dataproject"
        element={
          <Page title="Data Projects">
            <DataProject />
          </Page>
        }
      />
      <Route
        path="/dataprocessing"
        element={
          <Page title="Data Projects Processing">
            <DataProjectProcessing />
          </Page>
        }
      />
      <Route
        path="/management"
        element={
          <Page title="Management">
            <Management />
          </Page>
        }
      >
        <Route
          path="/management/organizations"
          element={
            <Page title="Organization Management">
              <OrganizationManagement />
            </Page>
          }
        />
        <Route
          path="/management/users"
          element={
            <Page title="User Management">
              <UserManagement />
            </Page>
          }
        />
      </Route>
    </Routes>
  );
};

export default PrivateRoutes;
