import React, { useEffect, useState } from 'react';
import { Slider, SliderLabel } from '@progress/kendo-react-inputs';
import DraggableWindow from '../../../../../../components/DraggableWindow';
import {
  BandInfo,
  LayerType,
  ViewLayer,
  VisualizationType,
  RAMP_COLORS,
  PointcloudVisualization,
  PCAttributeType,
  PotreePointcloudAttribute,
  DEFAULT_ATTRIBUTE_VALUES,
  IGNORED_ATTRIBUTES,
  HEIGHT,
  ELEVATION,
  RGBA,
  CLASSIFICATION,
} from '../../../../../../types';
import { useConsumeViewerState } from '../../../../../../context/viewer';
import { updateLayerParam } from '../../../../../../common/viewerConfigHelper';
import { getOrGenerateDefaultVisualizationParams } from '../../../../../../converters/viewLayerHelper';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { buildSchemasFromClassificationIds, formatAttributeName } from './OctreeHelper';
import RGBAControls from './RGBAControls';
import HeightControls from './HeightControls';
import ElevationControls from './ElevationControls';
import ClassificationControls from './ClassificationControls';
//import VectorControls from './VectorControls';

declare const window: any;

const Potree = window.Potree;
const THREE = window.THREE;

interface PointcloudControlsProps {
  layer: ViewLayer;
  onLayerUpdated?: (layer: ViewLayer) => void;
}

const PointcloudControls: React.FC<PointcloudControlsProps> = (props: PointcloudControlsProps) => {
  const [currentPointcloudAttributes, setCurrentPointcloudAttributes] = useState<any[]>([]);
  const [selectedPointcloudAttribute, setSelectedPointcloudAttribute] = useState<any>(null);
  const [internalSelectedLayer, setInternalSelectedLayer] = useState<ViewLayer>(null);
  const [currentOpacity, setCurrentOpacity] = useState<number>(1);

  const extractVisConfig = async (layer: ViewLayer): Promise<PointcloudVisualization> => {
    let visualization: PointcloudVisualization = layer.paramsMap['visualization'];
    if (!visualization) {
      visualization = {
        attribute: null,
        attributeValues: {},
      };
    }
    if (!visualization.attributeValues) {
      visualization.attributeValues = {};
    }
    if (!visualization.attributeValues.rgba) {
      visualization.attributeValues.rgba = {
        attributeType: PCAttributeType.COLORED,
        gamma: 2, // Range [0, 4]
        brightness: 0, // Range [-1, 1]
        contrast: 0, // Range [-1, 1]
      };
    }
    let e: any = null;
    try {
      e = await Potree.loadPointCloud(layer.uri);
      console.log('LOADED POINTCLOOUF');
    } catch (e: any) {
      console.log('ERROR: ', e);
      return;
    }
    const pointcloud = e.pointcloud;
    if (!pointcloud) {
      return visualization;
    }
    const pointcloudAttributes: PotreePointcloudAttribute[] = JSON.parse(
      JSON.stringify(pointcloud.getAttributes().attributes)
    );
    const [minElevation, maxElevation] = extractPointcloudElevationRange(pointcloud);
    pointcloudAttributes.push({
      name: ELEVATION,
      type: {
        ordinal: 0,
        name: '',
        size: 0,
      },
      numElements: 0,
      byteSize: 0,
      description: '',
      range: [minElevation, maxElevation],
      distinctValues: [],
      initialRange: [],
    });
    pointcloudAttributes.forEach((potreeAttr) => {
      if (!IGNORED_ATTRIBUTES.includes(potreeAttr.name)) {
        if (!visualization.attributeValues[potreeAttr.name]) {
          if (potreeAttr.distinctValues && potreeAttr.distinctValues.length > 0) {
            // Classification attribute
            const classificationIds = potreeAttr.distinctValues;
            classificationIds.sort(function (a, b) {
              return a - b;
            });
            const classificationSchemas = buildSchemasFromClassificationIds(classificationIds, potreeAttr.name);
            visualization.attributeValues[potreeAttr.name] = {
              attributeType: PCAttributeType.CLASSIFICATION,
              classificationSchemas: classificationSchemas,
            };
          } else {
            // Range attribute
            if (visualization || !visualization.attributeValues[potreeAttr.name]) {
              visualization.attributeValues[potreeAttr.name] = {
                attributeType: PCAttributeType.RANGE,
                min: potreeAttr.range[0],
                max: potreeAttr.range[1],
                currentMin: potreeAttr.range[0],
                currentMax: potreeAttr.range[1],
              };
            }
          }
        }

        if (!visualization.attribute) {
          if (potreeAttr.name === 'TreeID') {
            visualization.attribute = potreeAttr.name;
          } else if (potreeAttr.name === 'Rgba') {
            visualization.attribute = potreeAttr.name;
          } else if (potreeAttr.name === ELEVATION) {
            visualization.attribute = potreeAttr.name;
          }
        }
      }
    });
    return visualization;
  };

  const extractPointcloudElevationRange = (potreeNode: any) => {
    const aPosition = potreeNode.getAttribute('position');

    let bMin, bMax;

    if (aPosition) {
      // for new format 2.0 and loader that contain precomputed min/max of attributes
      const min = aPosition.range[0][2];
      const max = aPosition.range[1][2];
      const width = max - min;

      bMin = min - 0.2 * width;
      bMax = max + 0.2 * width;
    } else {
      // for format up until exlusive 2.0
      let box = [potreeNode.pcoGeometry.tightBoundingBox, potreeNode.getBoundingBoxWorld()].find(
        (v) => v !== undefined
      );

      potreeNode.updateMatrixWorld(true);
      box = Potree.Utils.computeTransformedBoundingBox(box, potreeNode.matrixWorld);

      const bWidth = box.max.z - box.min.z;
      bMin = box.min.z - 0.2 * bWidth;
      bMax = box.max.z + 0.2 * bWidth;
    }
    const [min, max] = potreeNode.material.elevationRange;
    return [min, max];
  };

  useEffect(() => {
    let newInternalLayer = { ...props.layer };
    //console.log('NewInternal Layer: ' + JSON.stringify(newInternalLayer));
    if (!props.layer) {
      // No selectd layer
    } else if (
      props.layer.id === internalSelectedLayer?.id &&
      props.layer.lastUpdated === internalSelectedLayer.lastUpdated
    ) {
      // No new changes to consider
    } else {
      extractVisConfig(props.layer).then((visualization) => {
        props.layer.paramsMap['visualization'] = visualization;

        if (!props.layer.paramsMap['visualization']) {
          const newLayer = JSON.parse(JSON.stringify(props.layer));
          updateLayerParam(newLayer, 'visualization', visualization);
          newInternalLayer = newLayer;
          props.onLayerUpdated(newLayer);
        }

        const attributes = Object.keys(visualization.attributeValues).map((attrName) => {
          return { id: attrName, text: formatAttributeName(attrName) };
        });
        const selectedAttribute = {
          id: visualization.attribute,
          text: formatAttributeName(visualization.attribute),
        };
        setCurrentPointcloudAttributes(attributes);
        setSelectedPointcloudAttribute(selectedAttribute);
      });
    }
    setInternalSelectedLayer(newInternalLayer);
  }, [props.layer]);

  const handleAttributeChanged = (attributeValue: { id: string; text: string }) => {
    const newLayer = JSON.parse(JSON.stringify(props.layer));
    const vis = newLayer.paramsMap.visualization;

    const visualization: PointcloudVisualization = newLayer.paramsMap['visualization'];
    //if (!visualization) {
    //  visualization = extractVisConfig(selectedLayerClone, potreeNodes[selectedLayerClone.id]);
    //}
    visualization.attribute = attributeValue.id;

    updateLayerParam(newLayer, 'visualization', visualization);
    console.log('OnAttrChange vis: ' + JSON.stringify(newLayer.paramsMap['visualization']));
    setSelectedPointcloudAttribute(attributeValue);
    setInternalSelectedLayer(newLayer);
    props.onLayerUpdated(newLayer);
  };

  const handleAttributeValueChange = (visualizationParamPath: string, value: any) => {
    const newLayer = JSON.parse(JSON.stringify(props.layer));
    const visualization: PointcloudVisualization = newLayer.paramsMap['visualization'];
    let paramToChange: any = visualization.attributeValues;
    const pathElements = visualizationParamPath.split('.');
    for (let i = 0; i < pathElements.length - 1; i++) {
      paramToChange = paramToChange[pathElements[i]];
    }
    paramToChange[pathElements[pathElements.length - 1]] = value;
    updateLayerParam(newLayer, 'visualization', visualization);
    props.onLayerUpdated(newLayer);
  };

  const handleAttributeRangeChange = (visualizationAttribute: string, newMin: any, newMax: any) => {
    const newLayer = JSON.parse(JSON.stringify(props.layer));
    const visualization: PointcloudVisualization = newLayer.paramsMap['visualization'];
    const modifiedAttribute = visualization.attributeValues[visualizationAttribute];
    modifiedAttribute.attributeType = PCAttributeType.RANGE;
    modifiedAttribute.currentMin = newMin;
    modifiedAttribute.currentMax = newMax;
    updateLayerParam(newLayer, 'visualization', visualization);
    props.onLayerUpdated(newLayer);
  };

  const handleClassificationUpdate = (
    layer: ViewLayer,
    attributeName: string,
    id: number,
    name: string,
    visible: boolean,
    color: number[]
  ) => {
    const newLayer = JSON.parse(JSON.stringify(props.layer));
    const visualization: PointcloudVisualization = newLayer.paramsMap['visualization'];
    let classificationAttribute = visualization.attributeValues[attributeName];
    if (!classificationAttribute) {
      classificationAttribute = {
        attributeType: PCAttributeType.CLASSIFICATION,
        classificationSchemas: {},
      };
    } else if (!classificationAttribute.classificationSchemas) {
      classificationAttribute.classificationSchemas = {};
    }
    classificationAttribute.classificationSchemas[id] = {
      visible: visible,
      index: id,
      name: name,
      color: color,
    };
    visualization.attributeValues[attributeName] = classificationAttribute;
    updateLayerParam(newLayer, 'visualization', visualization);
    props.onLayerUpdated(newLayer);
    //updatePotreeClassification(attributeName, id, name, visible, color);
  };

  if (
    !internalSelectedLayer ||
    (internalSelectedLayer.layerType !== LayerType.Pointcloud &&
      internalSelectedLayer.layerType !== LayerType.OctreeBin)
  )
    return null;

  return (
    <div>
      <div className="d-flex flex-column p-2">
        <FloatingLabel
          label={'Visualization Type'}
          editorId={'visType'}
          editorValue={true}
          className="w-75 mx-auto pb-2"
        >
          <DropDownList
            style={{
              width: '200px',
            }}
            size="small"
            data={currentPointcloudAttributes}
            value={selectedPointcloudAttribute}
            dataItemKey="id"
            textField="text"
            onChange={(event: any) => {
              handleAttributeChanged(event.target.value);
            }}
            disabled={props.layer === null}
            popupSettings={{ width: 250 }}
          />
        </FloatingLabel>
      </div>
      {/*<div className="d-flex flex-column p-2">
        <FloatingLabel
          label={'Opacity'}
          editorId={'opacity'}
          editorValue={selectedPointcloudAttribute ? '' + selectedPointcloudAttribute : ''}
          className="w-75 mx-auto pb-2"
        >
          <Slider
            min={0}
            max={1}
            step={0.01}
            value={currentOpacity}
            onChange={(e) => {
              const newLayer = JSON.parse(JSON.stringify(props.layer));
              const vis = newLayer.paramsMap.visualization;
              vis.opacity = e.value;
              updateLayerParam(newLayer, 'visualization', vis);
              setCurrentOpacity(e.value);
              setInternalSelectedLayer(newLayer);
              props.onLayerUpdated(newLayer);
            }}
          >
            <SliderLabel position={0}>0%</SliderLabel>
            <SliderLabel position={1}>100%</SliderLabel>
          </Slider>
        </FloatingLabel>
      </div>*/}
      {selectedPointcloudAttribute && selectedPointcloudAttribute.id === RGBA && (
        <RGBAControls
          layer={internalSelectedLayer}
          onLayerUpdate={props.onLayerUpdated}
          onAttributeValueChange={handleAttributeValueChange}
          onAttributeRangeChange={handleAttributeRangeChange}
        />
      )}
      {selectedPointcloudAttribute && selectedPointcloudAttribute.id === HEIGHT && (
        <HeightControls
          layer={internalSelectedLayer}
          onLayerUpdate={props.onLayerUpdated}
          onAttributeValueChange={handleAttributeValueChange}
          onAttributeRangeChange={handleAttributeRangeChange}
        />
      )}
      {selectedPointcloudAttribute && selectedPointcloudAttribute.id === ELEVATION && (
        <ElevationControls
          layer={internalSelectedLayer}
          onLayerUpdate={props.onLayerUpdated}
          onAttributeValueChange={handleAttributeValueChange}
          onAttributeRangeChange={handleAttributeRangeChange}
        />
      )}
      {selectedPointcloudAttribute && selectedPointcloudAttribute.id === CLASSIFICATION && (
        <ClassificationControls
          layer={internalSelectedLayer}
          onLayerUpdate={props.onLayerUpdated}
          onAttributeValueChange={handleAttributeValueChange}
          onAttributeRangeChange={handleAttributeRangeChange}
          onClassificationUpdate={handleClassificationUpdate}
        />
      )}
    </div>
  );
};

export default PointcloudControls;
