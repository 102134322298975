import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Typography } from '@progress/kendo-react-common';
import {
  useAddClientProject,
  useBuildConfigs,
  useClearConfig,
  useClearConfigs,
  useClientProjects,
  useDeleteClientProject,
  useGenerateSapFlows,
  useGenerateSapFlowsForConfig,
} from '../../hooks/data_delivery';
import { useUser } from '../../hooks/authentication';
import { Skeleton } from '@progress/kendo-react-indicators';
import { AuthorityLevel, AuthorityType, ClientProject } from '../../types';
import StyledExpansionPanel from '../../components/styled/StyledExpansionPanel';
import { truncateText } from '../../common/stringHelper';
import { ExpansionPanelActionEvent } from '@progress/kendo-react-layout';
import { trashIcon } from '@progress/kendo-svg-icons';
import { Reveal } from '@progress/kendo-react-animation';
import { GenericFormModal, SubmitButton } from '../../components/form';
import { RestrictedSubmitButton } from '../../components/restricted';
import { OverlayedLoader } from '../../components';
import DataCollectionWizard from '../../components/DataCollectionWizard/DataCollectionWizard';
import { useNavigate } from 'react-router-dom';
import { SapConfig } from '../../types/DataDelivery';
import ConfigPathBrowser from './ConfigPathBrowser/ConfigPathBrowser';
import { useAppContext } from '../../context/app';

const clientProjectFields = [
  {
    name: 'name',
    label: 'Name',
    placeholder: 'Enter project name',
    required: true,
  },
  {
    name: 'description',
    label: 'Description',
    placeholder: 'Enter project description',
  },
  {
    name: 'path',
    label: 'Path',
    placeholder: 'Enter project path',
    required: true,
  },
];

const DataProject: React.FC = () => {
  const { dispatch } = useAppContext();
  const { getCurrentOrganization } = useUser();
  const navigate = useNavigate();
  const clientProjectsQuery = useClientProjects(getCurrentOrganization().id);
  const addClientProjectMutation = useAddClientProject(getCurrentOrganization().id);
  const deleteClientProjectMutation = useDeleteClientProject(getCurrentOrganization().id);
  const buildConfigsMutation = useBuildConfigs(getCurrentOrganization().id);
  const generateSapFlowsMutation = useGenerateSapFlows(getCurrentOrganization().id);
  const generateSapFlowsForConfigMutation = useGenerateSapFlowsForConfig(getCurrentOrganization().id);
  const clearConfigsMutation = useClearConfigs(getCurrentOrganization().id);
  const clearConfigMutation = useClearConfig(getCurrentOrganization().id);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [addingClientProject, setAddingClientProject] = useState(false);
  const [showConfigPathBrowser, setShowConfigPathBrowser] = useState(null);
  const [addingCollection, setAddingCollection] = useState(false);

  const handleExpansionPanelAction = (clientProject: ClientProject | null, event: ExpansionPanelActionEvent) => {
    setSelectedProjectId(selectedProjectId === clientProject.id ? null : clientProject.id);
  };

  const handleBuildConfigs = (clientProject: ClientProject) => {
    buildConfigsMutation.mutateAsync(clientProject.id);
  };
  const handleAddNewClientProject = (name: string, description: string, path: string) => {
    addClientProjectMutation.mutateAsync(
      { name, description, path },
      {
        onSuccess: () => {
          setAddingClientProject(false);
          dispatch({
            type: 'SHOW_NOTIFICATION',
            payload: {
              notification: {
                content: 'Client Project created',
                type: 'success',
              },
            },
          });
        },
      }
    );
  };
  const handleClear = (clientProject: ClientProject) => {
    clearConfigsMutation.mutateAsync(clientProject.id);
  };
  const handleDelete = (clientProject: ClientProject) => {
    deleteClientProjectMutation.mutateAsync(clientProject.id);
  };
  const handleClearConfig = (clientProject: ClientProject, config: SapConfig) => {
    clearConfigMutation.mutateAsync({ clientProjectId: clientProject.id, configId: config.id });
  };

  const renderClientProjectConfig = (clientProject: ClientProject, config: any) => {
    return (
      <div key={config.id} style={{ display: 'flex', justifyContent: 'space-between', padding: '0.5rem 0' }}>
        <span>{config.name}</span>
        <div>
          <RestrictedSubmitButton
            label={'Generate SapFlows'}
            fillMode="outline"
            themeColor={'primary'}
            size="small"
            height="fit-content"
            full={false}
            onClick={() => {
              //handleGenerateSapFlowsForConfig(clientProject, config);
              console.log('Project', clientProject);
              setShowConfigPathBrowser({ projectId: clientProject.id, configId: config.id });
            }}
            authorityType={AuthorityType.DATA_AUTHORITY}
            authorityLevel={AuthorityLevel.EXECUTE}
            xMargin="mx-4"
          ></RestrictedSubmitButton>
          <RestrictedSubmitButton
            label={'Clear'}
            fillMode="outline"
            themeColor={'error'}
            size="small"
            height="fit-content"
            full={false}
            onClick={() => {
              handleClearConfig(clientProject, config);
            }}
            authorityType={AuthorityType.DATA_AUTHORITY}
            authorityLevel={AuthorityLevel.EXECUTE}
            xMargin="mx-4"
          ></RestrictedSubmitButton>
        </div>
      </div>
    );
  };

  const renderClientProjectInfos = (clientProject: ClientProject, index: number) => {
    const loading =
      buildConfigsMutation.isLoading ||
      generateSapFlowsMutation.isLoading ||
      generateSapFlowsForConfigMutation.isLoading ||
      clearConfigMutation.isLoading ||
      clearConfigsMutation.isLoading;
    return (
      <StyledExpansionPanel
        key={clientProject.id}
        title={
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography.h3 className="flex-grow-1 text-capitalize">
                {truncateText(clientProject.name, 46)}
              </Typography.h3>
              <Typography.h6 className="flex-grow-1" style={{ textTransform: 'none' }}>
                {truncateText(clientProject.id, 46)}
              </Typography.h6>
            </div>
            <RestrictedSubmitButton
              label={'Build Configs'}
              fillMode="outline"
              themeColor={'primary'}
              size="medium"
              height="fit-content"
              full={false}
              onClick={() => {
                handleBuildConfigs(clientProject);
              }}
              authorityType={AuthorityType.DATA_AUTHORITY}
              authorityLevel={AuthorityLevel.EXECUTE}
              xMargin="mx-4"
            ></RestrictedSubmitButton>
          </div>
        }
        className="position-relative"
        expanded={selectedProjectId === clientProject.id}
        tabIndex={0}
        onAction={handleExpansionPanelAction.bind(undefined, clientProject)}
        expandIcon="k-icon k-i-arrow-chevron-right animated-transform rotated-0"
        collapseIcon="k-icon k-i-arrow-chevron-right animated-transform rotated--90"
      >
        <OverlayedLoader show={loading} />
        <Reveal>
          <div
            style={{
              flexDirection: 'column',
              paddingBlock: 'var(--kendo-spacing-4)',
              paddingInline: 'var(--kendo-spacing-6)',
              display: selectedProjectId === clientProject.id ? 'flex' : 'none',
            }}
          >
            <div className="pb-4">
              <Typography.h4 className="">{clientProject.path}</Typography.h4>
            </div>
            {clientProject.configs.length > 1 && (
              <div>
                <div className="pb-2">
                  <Typography.h5 className="">Custom Configurations</Typography.h5>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {clientProject.configs.map((config) => {
                    return renderClientProjectConfig(clientProject, config);
                  })}
                </div>
              </div>
            )}
            {clientProject.configs.length > 1 && (
              <div>
                <div className="pb-2">
                  <Typography.h5 className="">Custom Configurations</Typography.h5>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {clientProject.configs.map((config) => {
                    return renderClientProjectConfig(clientProject, config);
                  })}
                </div>
              </div>
            )}

            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '1rem' }}>
              <RestrictedSubmitButton
                label={'Create Processing Module'}
                fillMode="outline"
                themeColor={'success'}
                size="medium"
                height="fit-content"
                full={false}
                onClick={() => {
                  setAddingCollection(true);
                }}
                authorityType={AuthorityType.DATA_AUTHORITY}
                authorityLevel={AuthorityLevel.EXECUTE}
                xMargin="mx-4"
              ></RestrictedSubmitButton>
              <div>
                <RestrictedSubmitButton
                  label={''}
                  fillMode="outline"
                  themeColor={'error'}
                  size="small"
                  height="fit-content"
                  full={false}
                  onClick={() => {
                    handleDelete(clientProject);
                  }}
                  icon={trashIcon}
                  authorityType={AuthorityType.DATA_AUTHORITY}
                  authorityLevel={AuthorityLevel.EXECUTE}
                  xMargin="mx-4"
                ></RestrictedSubmitButton>
                <RestrictedSubmitButton
                  label={'Clear All'}
                  fillMode="outline"
                  themeColor={'error'}
                  size="medium"
                  height="fit-content"
                  full={false}
                  onClick={() => {
                    handleClear(clientProject);
                  }}
                  authorityType={AuthorityType.DATA_AUTHORITY}
                  authorityLevel={AuthorityLevel.EXECUTE}
                  xMargin="mx-4"
                ></RestrictedSubmitButton>
              </div>
            </div>
          </div>
        </Reveal>
      </StyledExpansionPanel>
    );
  };

  return (
    <div className="container-fluid p-5">
      <GenericFormModal
        show={addingClientProject}
        title="Create Client Project"
        fields={clientProjectFields}
        handleClose={() => setAddingClientProject(false)}
        onConfirm={(formData) => {
          handleAddNewClientProject(formData.name, formData.description, formData.path);
        }}
        submitLabel="Create Project"
      />
      <DataCollectionWizard
        clientProjectId={selectedProjectId}
        show={addingCollection}
        handleClose={() => {
          setAddingCollection(false);
        }}
      ></DataCollectionWizard>
      <ConfigPathBrowser
        show={showConfigPathBrowser}
        handleClose={() => setShowConfigPathBrowser(null)}
        projectId={showConfigPathBrowser?.projectId}
        configId={showConfigPathBrowser?.configId}
      ></ConfigPathBrowser>
      <Typography.h3
        fontWeight="normal"
        className="text-uppercase"
        style={{ display: 'flex', width: '100%', paddingBottom: '1rem', justifyContent: 'space-between' }}
      >
        Client Projects Explorer
        <RestrictedSubmitButton
          label={'Go to Processing'}
          fillMode="outline"
          themeColor={'primary'}
          size="medium"
          height="fit-content"
          full={false}
          onClick={() => {
            navigate('/dataprocessing');
          }}
          style={{ marginLeft: 'auto' }}
          authorityType={AuthorityType.DATA_AUTHORITY}
          authorityLevel={AuthorityLevel.EXECUTE}
          xMargin="mx-4"
        ></RestrictedSubmitButton>
      </Typography.h3>
      <div className="m-auto mt-4 w-75">
        {!clientProjectsQuery.data && (
          <div>
            {new Array(5)
              .fill(<Skeleton shape="rectangle" style={{ width: '100%', height: '74px' }} className="my-3" />)
              .map((skeleton, i) => (
                <Fragment key={i}>{skeleton}</Fragment>
              ))}
          </div>
        )}
        {clientProjectsQuery.data &&
          clientProjectsQuery.data.length > 0 &&
          clientProjectsQuery.data.map(renderClientProjectInfos)}
        <StyledExpansionPanel
          title={
            <>
              <Typography.h3 className="flex-grow-1 text-capitalize" style={{ color: 'var(--geosap-primary-color)' }}>
                {truncateText('Add new Client Project', 46)}
              </Typography.h3>
            </>
          }
          expanded={false}
          tabIndex={0}
          onAction={() => {
            setAddingClientProject(true);
          }}
          expandIcon="k-icon k-i-plus"
          collapseIcon="k-icon k-i-plus"
        />
      </div>
    </div>
  );
};

export default DataProject;
