import { uuidv4 } from '../common/uuid';
import { useAppContext } from '../context/app';
import { useUser } from '../hooks/authentication';
import {
  useCleanFileNode,
  useCreateFolderFileNode,
  useDeleteFileNode,
  useDownloadFileNode,
  useFileNodeGeolocationUpdate,
  useFileNodeNavigationOptionsUpdate,
  useFilenodeStructure,
  useForgetFileNode,
  useGenerateFileNodes,
  useMoveFileNode,
  usePrepareFileNode,
  useProcessFileNode,
  useRootStructure,
} from '../hooks/data_delivery';
import { FileNode, FileNodeStatus } from '../types/DataDelivery';

const dataDeliveryActions = () => {
  const { dispatch } = useAppContext();
  const {
    getCurrentOrganization,
  } = useUser();
  const createFolderFileNodeMutation = useCreateFolderFileNode(getCurrentOrganization().id);
  const prepareFileNodeMutation = usePrepareFileNode();
  const processFileNodeMutation = useProcessFileNode();
  const updateFileNodeGeolocationMutation = useFileNodeGeolocationUpdate();
  const updateFileNodeNavigationOptionsMutation = useFileNodeNavigationOptionsUpdate();
  const generateFileNodesMutation = useGenerateFileNodes();
  const moveFileNodeMutation = useMoveFileNode(getCurrentOrganization().id);
  const cleanFileNodeMutation = useCleanFileNode(getCurrentOrganization().id);
  const forgetFileNodeMutation = useForgetFileNode(getCurrentOrganization().id);
  const deleteFileNodeMutation = useDeleteFileNode(getCurrentOrganization().id);
  const downloadFileNodeMutation = useDownloadFileNode();

  const handleCreateFolderFileNode = async (name: string, parent: FileNode) => {
    return await createFolderFileNodeMutation.mutateAsync({ name, parentId: parent?.id, rootId: parent?.rootId });
  };

  const handleDownloadFileNode = (fileNode: FileNode) => {
    downloadFileNodeMutation.mutateAsync({ fileNodeId: fileNode.id, fileNodeName: fileNode.name });
  };

  const handlePrepareFileNode = (fileNode: FileNode) => {
    prepareFileNodeMutation.mutateAsync(fileNode?.path).then(() => {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { content: 'Preparing ' + fileNode.name + ' for Visualization.', type: 'success' } },
      });
    });
  };

  const handleProcessFileNode = (fileNode: FileNode) => {
    /*if (fileNode?.status !== FileNodeStatus.CREATED) {
      //setConfirmingReprocessFilenode(fileNode);
    } else*/ {
      processFileNodeMutation.mutateAsync({ fileNodeId: fileNode?.id, force: false }).then(() => {
        dispatch({
          type: 'SHOW_NOTIFICATION',
          payload: { notification: { content: 'Started FileNode Processing', type: 'success' } },
        });
      });
    }
  };

  const handleUpdateFileNode = (fileNode: FileNode, onSuccess?: ()=>void, onError?: ()=>void) => {
    generateFileNodesMutation.mutateAsync(fileNode?.path, {onSuccess, onError}).then(() => {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { content: 'Update FileNode ' + fileNode.name + ' structure.', type: 'success' } },
      });
    });
    return {
      isLoading: generateFileNodesMutation.isLoading,
      isSuccess: generateFileNodesMutation.isSuccess,
    };
  };

  const handleUpdateFileNodeGeolocation = (fileNode: FileNode) => {
    updateFileNodeGeolocationMutation.mutateAsync({ fileNodeId: fileNode?.id }).then(() => {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { content: 'Updated FileNode ' + fileNode.name + ' geolocation.', type: 'success' } },
      });
    });
  };

  const handleCleanFileNode = (fileNode: FileNode) => {
    cleanFileNodeMutation.mutateAsync({ fileNodeId: fileNode.id, rootId: fileNode.rootId, parentId: fileNode.parentId }).then(() => {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { content: 'Cleaned FileNode ' + fileNode.name + '.', type: 'success' } },
      });
    });
  };
  const handleDeleteFileNode = (fileNode: FileNode) => {
    deleteFileNodeMutation.mutateAsync({ fileNodeId: fileNode.id, rootId: fileNode.rootId, parentId: fileNode.parentId });
  };
  const handleForgetFileNode = (fileNode: FileNode) => {
    forgetFileNodeMutation.mutateAsync({ fileNodeId: fileNode.id, rootId: fileNode.rootId }).then(() => {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { content: 'Forgot FileNode ' + fileNode.name + '.', type: 'success' } },
      });
    });
  };
  const handleMoveFileNode = (fileNode: FileNode, newParent: FileNode) => {
    moveFileNodeMutation.mutateAsync({ fileNodeId: fileNode.id, newParent: newParent.id, rootId: fileNode.rootId, newRootId: newParent.rootId }).then(() => {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { content: 'Moved File ' + fileNode.name + '.', type: 'success' } },
      });
    });
    return {
      isLoading: moveFileNodeMutation.isLoading,
      isSuccess: moveFileNodeMutation.isSuccess,
    };
  };

  const handleUploadFileNode = (file: File, parentFileNode: FileNode) => {
    // IMPORTANT The Notification itself is responsible for the upload (because of it's persistency in the app)
    dispatch({
      type: 'UPDATE_UPLOAD_NOTIFICATION',
      payload: {
        uploadNotification: {
          id: uuidv4(),
          parentFileNode: parentFileNode,
          fileName: file.name,
          file: file,
          progress: 0,
          started: false,
        },
      },
    });
  };

  return {
    handleCreateFolderFileNode,
    handlePrepareFileNode,
    handleDownloadFileNode,
    handleProcessFileNode,
    handleUpdateFileNode,
    handleUpdateFileNodeGeolocation,
    handleCleanFileNode,
    handleDeleteFileNode,
    handleForgetFileNode,
    handleMoveFileNode,
    handleUploadFileNode,
    movingFileNode: moveFileNodeMutation.isLoading,
    movedFileNode: moveFileNodeMutation.isSuccess,
  };
};

export default dataDeliveryActions;
