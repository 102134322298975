import { Button } from '@progress/kendo-react-buttons';
import { useEffect, useState } from 'react';
import React from 'react';
import { useSapFlowActions } from '../../actions';

import GeneralizedContextMenu from '../../components/GenericContextMenu';
import IconButton from '../../components/form/IconButton';
import { playIcon, stopIcon } from '@progress/kendo-svg-icons';
import { useUser } from '../../hooks/authentication';
import { ESapFlowStatus, SapFlow, SapFlowProcessNode } from '../../types';

interface ExecutionOptionsButtonProps {
  sapflow: SapFlow;
  processNode: SapFlowProcessNode;
}

const ExecutionOptionsButton: React.FC<ExecutionOptionsButtonProps> = ({ sapflow, processNode }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuOffset, setMenuOffset] = useState({ left: 0, top: 0 });
  const { getCurrentOrganization } = useUser();
  const { handleRunSapFlow, handleStopSapFlow } = useSapFlowActions();

  useEffect(() => {
    document.addEventListener('click', () => {
      if (menuVisible) {
        setMenuVisible(false);
      }
    });
  }, [menuVisible]);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setMenuOffset({ left: event.pageX, top: event.pageY });
    setMenuVisible(true);
  };

  const handleStopSapFlowClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    handleStopSapFlow(sapflow);
  };

  const isTransactionRunnable = ![ESapFlowStatus.TRANSACTION_STARTED, ESapFlowStatus.TRANSACTION_IN_PROCESS].includes(
    sapflow.status
  );

  const menuItems = [
    {
      text: 'Run this node',
      disabled: false, //!isTransactionRunnable,
      action: () => {
        //handleClose();
      },
    },
    {
      text: 'Run sapflow from here',
      disabled: false, //!isTransactionRunnable,
      action: () => {
        //handleClose();
        handleRunSapFlow(sapflow, processNode);
      },
    },
  ];

  if (sapflow.status === ESapFlowStatus.TRANSACTION_IN_PROCESS) {
    return (
      <div>
        <IconButton
          style={{ color: 'var(--geosap-light-error)' }}
          hoveredStyle={{ color: 'var(--geosap-dark-error)' }}
          icon={stopIcon}
          onClick={handleStopSapFlowClick}
        />
        <GeneralizedContextMenu
          show={menuVisible}
          offset={menuOffset}
          items={menuItems}
          onClose={() => setMenuVisible(false)}
        />
      </div>
    );
  } else {
    return (
      <div>
        <IconButton
          style={{ color: 'var(--geosap-card-secondary-bg-color)' }}
          hoveredStyle={{ color: 'var(--geosap-primary-color)' }}
          icon={playIcon}
          onClick={handleContextMenu}
        />
        <GeneralizedContextMenu
          show={menuVisible}
          offset={menuOffset}
          items={menuItems}
          onClose={() => setMenuVisible(false)}
        />
      </div>
    );
  }
};

export default ExecutionOptionsButton;
