import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from '@progress/kendo-react-indicators';
import {
  DataProcessorProvider,
  ProcessorNavigationMode,
  useCreateDataProcessorState,
} from '../../context/dataProcessorContext';
import { AuthorityLevel, AuthorityType, TusUpload } from '../../types';
import { useUser } from '../../hooks/authentication';
import NavigationSidebar from './NavigationSidebar/NavigationSidebar';
import Dashboard from './Dashboard';
import Processing from './Processing';
import { useUploadContext } from '../../context/uploadContext';
//import StatusBar from './StatusBar';

const DataDelivery: React.FC = () => {
  const navigate = useNavigate();
  const dataProcessorState = useCreateDataProcessorState();
  const { dispatch: uploadDispatch } = useUploadContext();
  const { getCurrentOrganization, userHasAuthority, getViewAccessToken } = useUser();
  const [contextMenuFileNode, setContextMenuFileNode] = React.useState(null);

  return (
    <DataProcessorProvider value={dataProcessorState}>
      <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex' }}>
        <NavigationSidebar />
        <div
          style={{
            position: 'absolute',
            left: 'var(--geosap-datadelivery-navigation-width)',
            right: 0,
            top: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {dataProcessorState.navigationMode === ProcessorNavigationMode.DASHBOARD && <Dashboard></Dashboard>}
          {dataProcessorState.navigationMode === ProcessorNavigationMode.PROCESSING && <Processing></Processing>}
          {/*<StatusBar />*/}
        </div>
      </div>
    </DataProcessorProvider>
  );
};

export default DataDelivery;
