import { InteractionMode, useConsumeDataDeliveryState } from '../../../context/dataDeliveryContext';
import { useEffect, useRef } from 'react';

const usePotreeInteractions = (potreeViewer: any) => {
  const { dispatch, profiles, interactionMode } = useConsumeDataDeliveryState();
  const handleProfileWindowClosedRef = useRef<() => void>();

  handleProfileWindowClosedRef.current = () => {
    potreeViewer.profileWindowController.reset();
    profiles.forEach((profile, index) => {
      potreeViewer.scene.removeProfile(profile);
    });
    dispatch({ type: 'CLEAR_PROFILES' });
  };

  useEffect(() => {
    const handleEvent = () => {
      handleProfileWindowClosedRef.current();
    };
    if (potreeViewer?.profileWindow) {
      potreeViewer.profileWindow.addEventListener('profileWindowClosed', handleEvent);
    }
    return () => {
      if (potreeViewer?.profileWindow) {
        potreeViewer.profileWindow.removeEventListener('profileWindowClosed', handleEvent);
      }
    };
  }, [potreeViewer?.profileWindow]);

  const handleStartMeasurement = async (interactionMode: InteractionMode) => {
    if (interactionMode === InteractionMode.POINT_MEASUREMENT_3D) {
      const measurement = potreeViewer.measuringTool.startInsertion({
        showDistances: false,
        showAngles: false,
        showCoordinates: true,
        showArea: false,
        closed: true,
        maxMarkers: 1,
        name: 'Point',
        callback: clearInteractionMode,
      });
      measurement.addEventListener('marker_dropped', clearInteractionMode);
    }
    if (interactionMode === InteractionMode.DISTANCE_MEASUREMENT_3D) {
      const measurement = potreeViewer.measuringTool.startInsertion({
        showDistances: true,
        showArea: false,
        closed: false,
        name: 'Distance',
        callback: clearInteractionMode,
      });
      measurement.addEventListener('marker_dropped', clearInteractionMode);
    }
    if (interactionMode === InteractionMode.HEIGHT_MEASUREMENT_3D) {
      const measurement = potreeViewer.measuringTool.startInsertion({
        showDistances: false,
        showHeight: true,
        showArea: false,
        closed: false,
        maxMarkers: 2,
        name: 'Height',
        callback: clearInteractionMode,
      });
      measurement.addEventListener('marker_dropped', clearInteractionMode);
    }
    if (interactionMode === InteractionMode.VOLUME_MEASUREMENT_3D) {
      const measurement = potreeViewer.measuringTool.startInsertion({
        showDistances: true,
        showArea: true,
        closed: true,
        name: 'Area',
        callback: clearInteractionMode,
      });
      measurement.addEventListener('marker_dropped', clearInteractionMode);
    }
    if (interactionMode === InteractionMode.PROFILE_MEASUREMENT_3D) {
      console.log('starting profil...');
      const profile = potreeViewer.profileTool.startInsertion({
        name: 'Profile',
        callback: clearInteractionMode,
      });
      profile.addEventListener('marker_dropped', clearInteractionMode);
      potreeViewer.profileWindow.show();
      potreeViewer.profileWindowController.setProfile(profile);

      dispatch({ type: 'ADDED_PROFILE', payload: profile });
    }
  };

  const clearInteractionMode = async () => {
    if (interactionMode === InteractionMode.POINT_MEASUREMENT_3D) {
      dispatch({ type: 'SET_INTERACTION_MODE', payload: InteractionMode.NAVIGATION });
    }
  };

  return {
    handleStartMeasurement,
  };
};

export default usePotreeInteractions;
