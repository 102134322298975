import React from 'react';
import { FileNode, FileNodeStatus, FileNodeType } from '../../../types/DataDelivery';
import {
  caretAltRightIcon,
  caretAltDownIcon,
  caretAltToRightIcon,
  eyeIcon,
  eyeSlashIcon,
  moreVerticalIcon,
} from '@progress/kendo-svg-icons';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/error.svg';
import { SvgIcon } from '@progress/kendo-react-common';
import { useConsumeDataDeliveryState } from '../../../context/dataDeliveryContext';
import { useAppContext } from '../../../context/app';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Loader } from '@progress/kendo-react-indicators';

interface ListItemProps {
  fileNode: FileNode;
  hideExpandButton?: boolean;
  hideVisibilityIcon?: boolean;
  draggable: boolean;
  onToggleVisibility: (fileNode: FileNode) => void;
  onExpandFileNode(FileNode: any): void;
  onMoreOptions: (e: React.MouseEvent<HTMLElement>) => void;
}

const ListItem: React.FC<ListItemProps> = ({
  fileNode,
  onToggleVisibility,
  onExpandFileNode,
  onMoreOptions,
  hideExpandButton,
  hideVisibilityIcon,
  draggable,
}) => {
  const { dispatch, hoveredFileNode } = useConsumeDataDeliveryState();
  const { dispatch: appDispatch } = useAppContext();
  const hovered = fileNode === hoveredFileNode;
  const falsies = false;

  const renderStatus = () => {
    if (fileNode.status === FileNodeStatus.READY && !hideVisibilityIcon) {
      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onToggleVisibility(fileNode);
          }}
          className="text-white flex-shrink-0 mx-1"
          aria-label={fileNode.hidden ? 'Show' : 'Hide'}
        >
          <SvgIcon icon={fileNode.hidden ? eyeSlashIcon : eyeIcon} size="xlarge" color={'white'} />
        </div>
      );
    } else if (fileNode.status === FileNodeStatus.CREATED) {
      return (
        <div className="text-white flex-shrink-0 mx-1" style={{ position: 'relative' }}>
          <a
            style={{ display: 'flex', width: '24px', justifyContent: 'center' }}
            title={'Data is not viewable. Process to view.'}
          >
            <WarningIcon
              style={{
                width: '100%',
              }}
            />
          </a>
        </div>
      );
    } else if (fileNode.status === FileNodeStatus.PROCESSING) {
      return (
        <div className="text-white flex-shrink-0 mx-1">
          <a
            style={{ display: 'flex', width: '24px', justifyContent: 'center' }}
            title={'Please wait while data is processing.'}
          >
            <Loader size="small" style={{ color: 'white' }} />
          </a>
        </div>
      );
    } else if (fileNode.status === FileNodeStatus.ERRORED_IN_PROCESSING) {
      return (
        <div className="text-white flex-shrink-0 mx-1" style={{ position: 'relative' }}>
          <a style={{ display: 'flex', width: '24px', justifyContent: 'center' }} title={fileNode.errorMessage}>
            <ErrorIcon
              style={{
                width: '100%',
              }}
            />
          </a>
        </div>
      );
    }
  };

  return (
    <div
      className="d-flex align-items-center justify-between p-2 bg-gray-800 rounded-md shadow-md animated-background cursor-pointer"
      style={{ background: hovered ? '#4c4c4c' : 'transparent' }}
      onMouseEnter={() => {
        console.log();
        dispatch({ type: 'SET_HOVERED_FILE_NODE', payload: fileNode });
      }}
      onMouseLeave={() => {
        console.log();
        dispatch({ type: 'SET_HOVERED_FILE_NODE', payload: null });
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (fileNode.isDir) {
          onExpandFileNode(fileNode);
        } else if (fileNode.status === FileNodeStatus.READY) {
          if (
            fileNode.fileType === FileNodeType.TEXT ||
            fileNode.fileType === FileNodeType.PDF ||
            fileNode.fileType === FileNodeType.IMAGE
          ) {
            window.open(fileNode.viewLayer.uri, '_blank');
          } else {
            dispatch({ type: 'SET_OPENED_FILE_NODE', payload: fileNode });
          }
        } else {
          appDispatch({
            type: 'SHOW_NOTIFICATION',
            payload: {
              notification: {
                content: fileNode.name + ' is not yet ready for visualization.',
                type: 'error',
              },
            },
          });
        }
      }}
    >
      {/* Drag handle */}
      {draggable && <div className="text-gray-500 cursor-move mr-2">⠿</div>}
      {/* Status/Visibility toggle */}
      {renderStatus()}
      {/* Expand icon */}
      {!hideExpandButton && fileNode.isDir && (
        <div className="mx-1">
          <SvgIcon icon={fileNode.expanded ? caretAltDownIcon : caretAltRightIcon} size="xlarge" color={'white'} />
        </div>
      )}
      {/* Label */}
      <span
        className="mx-1 text-white"
        style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        title={fileNode.name}
      >
        {fileNode.name}
      </span>
      {/* More options */}
      <SvgIcon
        style={{ marginLeft: 'auto' }}
        icon={moreVerticalIcon}
        size="large"
        color={'white'}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          onMoreOptions(e);
        }}
      />
    </div>
  );
};

export default ListItem;
