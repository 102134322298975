import React, { useState } from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { SvgIcon } from '@progress/kendo-react-common';
import { SVGIcon, shareIcon } from '@progress/kendo-svg-icons';
import { Dialog as ASD, DialogActionsBar } from '@progress/kendo-react-dialogs';
import SubmitButton from './SubmitButton';
import Dialog from '../Dialog';
import CopyableTextBox from './CopyableTextBox';
import { useShareFileNode, useShareTransaction } from '../../hooks/user_management';
import { useUser } from '../../hooks/authentication';

interface Props {
  icon?: SVGIcon;
  disabled?: boolean;
  style?: any;
  hoveredStyle?: any;
  tooltip?: string;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

const ShareResourceButton: React.FC<Props> = ({ disabled, style, hoveredStyle, tooltip, loading, onClick, icon }) => {
  const [hovered, setHovered] = useState(false);

  let appliedIconStyle = null;
  if (hoveredStyle && hovered) {
    appliedIconStyle = hoveredStyle;
  } else if (style) {
    appliedIconStyle = style;
  }

  const containerStyle = { ...{ display: 'flex', justifyContent: 'center', alignItems: 'center' }, ...style };

  return (
    <div style={containerStyle}>
      {loading ? (
        <Loader size="small" type={'converging-spinner'} themeColor={'light'} />
      ) : (
        <Tooltip anchorElement="target" position="top">
          <a
            style={{ display: 'flex' }}
            title={tooltip}
            href=""
            onClick={onClick}
            className={disabled ? 'grayed' : ''}
            onMouseEnter={() => {
              setHovered(true);
            }}
            onMouseLeave={() => {
              setHovered(false);
            }}
          >
            <SvgIcon
              icon={icon}
              className="animated-color"
              style={{ ...{ fontSize: '1.2rem' }, ...appliedIconStyle }}
            />
          </a>
        </Tooltip>
      )}
    </div>
  );
};

export default ShareResourceButton;
