import { CollectionType } from "../../types";

export enum DataCollectionWizardStep {
  SELECT_CONFIG_TYPE = 0,
  ENTER_PATH = 1,
  ADJUST_CONFIG = 2
}

export interface DataCollectionWizardState {
  step: DataCollectionWizardStep;
  clientProjectId: string | null;
  configType: CollectionType | null;
  path: string | null;
  configId: string | null;
  done: boolean;
}

export const initialState: DataCollectionWizardState = {
  step: DataCollectionWizardStep.SELECT_CONFIG_TYPE,
  clientProjectId: null,
  configType: null,
  path: null,
  configId: null,
  done: false,
}; 