import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Popup, Offset } from '@progress/kendo-react-popup';
import { Menu, MenuItem, MenuItemModel } from '@progress/kendo-react-layout';
import { AuthorityLevel, AuthorityType, LayerType, ViewLayer } from '../../../types';
import { useUser } from '../../../hooks/authentication';
import { FileNode, FileNodeType, FileNodeStatus, FileNodeGeolocation } from '../../../types/DataDelivery';
import { useUploadContext } from '../../../context/uploadContext';

interface ContextMenuProps {
  show: boolean;
  offset: Offset;
  onShowWebsocketMonitor: () => void;
  onCloseContextMenu(): void;
}

const ContextMenu: React.FC<ContextMenuProps> = (props: ContextMenuProps) => {
  const { userHasAuthority, getViewAccessToken, getSharedFileNode } = useUser();
  const { dispatch: uploadDispatch } = useUploadContext();
  const navigate = useNavigate();
  const availableOptions: MenuItemModel[] = [];
  availableOptions.push({
    text: 'Data Proc',
    data: {
      action: () => {
        navigate('dataproject');
        props.onCloseContextMenu();
      },
    },
  });
  availableOptions.push({
    text: 'Servers',
    data: {
      action: () => {
        navigate('servers');
        props.onCloseContextMenu();
      },
    },
  });
  availableOptions.push({
    text: 'User Management',
    data: {
      action: () => {
        navigate('management/users');
        props.onCloseContextMenu();
      },
    },
  });
  availableOptions.push({
    text: 'Websocket Monitor',
    data: {
      action: () => {
        props.onShowWebsocketMonitor();
        props.onCloseContextMenu();
      },
    },
  });
  availableOptions.push({
    text: 'Upload Manager',
    data: {
      action: () => {
        uploadDispatch({ type: 'UPLOADS_VISIBILITY', payload: { visibility: true } });
        props.onCloseContextMenu();
      },
    },
  });
  return (
    <Popup show={props.show && props.offset !== null} offset={props.offset} popupClass={'popup-content'}>
      <Menu
        vertical={true}
        style={{ display: 'inline-block' }}
        items={availableOptions}
        onSelect={({ item, itemId, nativeEvent, syntheticEvent, target }) => {
          item.data.action();
          syntheticEvent.stopPropagation();
        }}
      ></Menu>
    </Popup>
  );
};

export default ContextMenu;
