import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Handle, Position, NodeProps, HandleType, useReactFlow, ReactFlowInstance } from 'reactflow';
import { Typography } from '@progress/kendo-react-common';
import { BuilderBlockItemDetail, BuilderBlockItemState } from '../../../../types';

const StyledHandleLabel = styled.span`
  position: absolute;
  font-size: 0.4rem;
  font-weight: 200;
  white-space: nowrap;
  pointer-events: none;
`;

const handleStyle = { left: 10 };

const BuilderProcessNode: React.FC<NodeProps> = ({ data, isConnectable, selected }) => {
  const ref = useRef(null);
  const reactFlowInstance: ReactFlowInstance = useReactFlow();
  const [inputDistance, setInputDistance] = useState(null);
  const [outputDistance, setOutputDistance] = useState(null);

  useEffect(() => {
    if (ref.current && ref.current.offsetWidth && data.block) {
      setInputDistance(ref.current.offsetWidth / (data.block.inputs.length + 1));
      setOutputDistance(ref.current.offsetWidth / (data.block.outputs.length + 1));
    }
  }, [ref.current, data?.block?.inputs, data?.block?.outputs]);

  const getBackgroundColor = (state: BuilderBlockItemState) => {
    return {
      [BuilderBlockItemState.READY]: 'black',
      [BuilderBlockItemState.VALID]: 'green',
      [BuilderBlockItemState.INVALID]: 'gray',
    }[state];
  };

  const renderHandle = (
    input: BuilderBlockItemDetail,
    index: number,
    handleType: HandleType,
    connectingHandleInfos: any
  ) => {
    let position = Position.Bottom;
    let leftOffset = (index + 1) * outputDistance;
    let labelTransform = 'translate(-50%, -100%)';
    if (handleType === 'target') {
      position = Position.Top;
      leftOffset = (index + 1) * inputDistance;
      labelTransform = 'translate(-50%, 50%)';
    }
    return (
      <Handle
        key={'input_' + index}
        id={input.name}
        type={handleType}
        position={position}
        isConnectable={input.state !== BuilderBlockItemState.INVALID}
        style={{ left: leftOffset, background: getBackgroundColor(input.state) }}
      >
        <StyledHandleLabel style={{ transform: labelTransform }}>
          {input.name + ' (' + input.fileformat + ')'}
        </StyledHandleLabel>
      </Handle>
    );
  };

  return (
    <div
      ref={ref}
      style={{
        position: 'relative',
        border: selected ? '1px solid #888' : '1px solid #eee',
        padding: '8px 12px',
        borderRadius: '5px',
        background: 'white',
        width: '200px',
      }}
    >
      {data.block.inputs.map((input: BuilderBlockItemDetail, index: number) =>
        renderHandle(input, index, 'target', data.connectingHandleInfos)
      )}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography.p fontSize="xsmall" style={{ marginBottom: '0px' }}>
          Process
        </Typography.p>
        <Typography.h4 fontSize="medium" style={{ textAlign: 'center' }}>
          {data.block.sapname}
        </Typography.h4>
      </div>
      {data.block.outputs.map((output: BuilderBlockItemDetail, index: number) =>
        renderHandle(output, index, 'source', data.connectingHandleInfos)
      )}
    </div>
  );
};

export default BuilderProcessNode;
