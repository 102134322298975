import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { useAppContext } from '../context/app';
import { SubmitButton } from '../components/form';
import useAcceptInvitation from '../hooks/user_management/useAcceptInvitation';

const ConfirmEmail: React.FC = () => {
  const { dispatch } = useAppContext();
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const acceptInvitationMutation = useAcceptInvitation();

  useEffect(() => {
    if (queryParameters.get('invitationCode') !== null) {
      acceptInvitationMutation.mutate({ invitationCode: queryParameters.get('invitationCode') });
    } else {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { type: 'error', content: 'Invitation Code invalid. Redirecting.' } },
      });
      setTimeout(() => {
        navigate('/');
      }, 1500);
    }
  }, [queryParameters.get('activationCode')]);

  useEffect(() => {
    if (acceptInvitationMutation.isSuccess) {
      //setHandledCreationResponse(true);
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { type: 'success', content: 'You have joined .' } },
      });
      setTimeout(() => {
        navigate('/delivery');
      }, 1500);
    } else if (acceptInvitationMutation.isError) {
      setTimeout(() => {
        navigate('/delivery');
      }, 1500);
    }
  }, [acceptInvitationMutation.isSuccess, acceptInvitationMutation.isError]);

  return (
    <div className="container-fluid p-2 p-lg-5">
      <div className="row">
        <Typography.h3>Accepting Invitation</Typography.h3>
      </div>
      <div className="row">
        <Typography.h5>We are processing your invitation...</Typography.h5>
      </div>
      <SubmitButton label="Continue" loading={true} />
    </div>
  );
};

export default ConfirmEmail;
