import React, { useEffect } from 'react';
import { Checkbox } from '@progress/kendo-react-inputs';
import { ViewLayer } from '../../../../../../types';
import { updateLayerParam } from '../../../../../../common/viewerConfigHelper';
import { trackChangesEnableIcon, checkIcon } from '@progress/kendo-svg-icons';
import { SvgIcon } from '@progress/kendo-react-common';
import { Input } from '../../../../../../components/form';

interface WFSPropertiesControlsProps {
  layer: ViewLayer;
  onLayerUpdated?: (layer: ViewLayer) => void;
}

const RasterPropertiesControls: React.FC<WFSPropertiesControlsProps> = (props) => {
  const [internalLayer, setInternalLayer] = React.useState(null);
  const [hovering, setHovering] = React.useState(null);
  const [modifying, setModifying] = React.useState(null);
  const [modifyingValue, setModifyingValue] = React.useState(null);
  useEffect(() => {
    setModifying(null);
    setModifyingValue(null);
    setHovering(null);
    setInternalLayer(props.layer);
  }, [props.layer]);

  const handleModify = (propertyName: string) => {
    const newViewLayer = JSON.parse(JSON.stringify(internalLayer));
    let newValue: string = modifyingValue;
    if (propertyName === 'uri') {
      if (newValue.endsWith('/wfs')) {
        newValue = newValue.slice(0, -4); // Removing last 4 characters ('/wfs')
      }
    }
    if (propertyName === 'wmsStyle') {
      if (newValue.toLowerCase() === 'null') {
        newValue = null;
      }
    }
    updateLayerParam(newViewLayer, propertyName, newValue);
    props.onLayerUpdated(newViewLayer);
  };

  const handleToggleCheckValue = (propertyName: string) => {
    const newViewLayer = JSON.parse(JSON.stringify(internalLayer));
    updateLayerParam(newViewLayer, propertyName, !internalLayer.paramsMap[propertyName]);
    props.onLayerUpdated(newViewLayer);
  };

  const renderPropertyData = (
    title: string,
    propertyName: string,
    value: string | number | JSX.Element,
    modifiable: boolean
  ) => {
    const modifyingThisProperty = modifying === propertyName;
    return (
      <div
        style={{ display: 'flex', position: 'relative', alignItems: modifyingThisProperty ? 'center' : 'flex-start' }}
        onMouseEnter={() => {
          if (!modifying && modifiable) setHovering(propertyName);
        }}
        onMouseLeave={() => {
          setHovering(null);
        }}
      >
        <span style={{ flex: '1' }}>{title + ':'}&nbsp;</span>
        <div style={{ display: 'flex', alignItems: 'center', flex: '4' }}>
          {!modifyingThisProperty && value}
          {modifyingThisProperty && (
            <Input
              className={'w-100 me-4'}
              style={{ padding: '0.25rem !important' }}
              type="text"
              name={propertyName}
              value={modifyingValue}
              onChange={(e) => {
                setModifyingValue(e.value);
              }}
            />
          )}
        </div>
        {hovering === propertyName && (
          <SvgIcon
            icon={trackChangesEnableIcon}
            style={{
              color: 'black',
              fontSize: '1.2rem',
              cursor: 'pointer',
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              margin: 'auto',
            }}
            onClick={() => {
              setModifying(propertyName);
              setModifyingValue(value);
              setHovering(null);
            }}
          />
        )}
        {modifyingThisProperty && (
          <SvgIcon
            icon={checkIcon}
            style={{
              color: 'black',
              fontSize: '1.2rem',
              cursor: 'pointer',
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              margin: 'auto',
            }}
            onClick={() => {
              handleModify(propertyName);
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div className={'mt-4 mb-4'} style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <label>Default No Data: </label>
          <Checkbox
            style={{ marginLeft: '0.75rem' }}
            checked={internalLayer?.paramsMap['defaultNodata']}
            onChange={(e) => {
              handleToggleCheckValue('defaultNodata');
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RasterPropertiesControls;
