import React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Typography } from '@progress/kendo-react-common';
import { SubmitButton } from '../../form';
import { useDataCollectionWizardState } from '../../../context/dataCollectionWizard';
import TransitionAnimation from '../../../components/TransitionAnimation';
import { CollectionType } from '../../../types';

const CONFIG_TYPES = Object.values(CollectionType)
  .filter((value) => isNaN(Number(value)))
  .map((value) => ({
    text: value,
    value: value,
  }));

const SelectConfigType: React.FC = () => {
  const { dispatch, state } = useDataCollectionWizardState();
  const [selectedType, setSelectedType] = React.useState<CollectionType | null>(null);

  const handleNext = () => {
    dispatch({
      type: 'SELECT_CONFIG_TYPE',
      payload: { configType: selectedType! },
    });
  };

  return (
    <TransitionAnimation>
      <div className="text-center">
        <Typography.h5 style={{ margin: '25px' }}>Select Configuration Type</Typography.h5>

        <div style={{ width: '300px', margin: '0 auto' }}>
          <DropDownList
            data={CONFIG_TYPES}
            textField="text"
            dataItemKey="value"
            value={CONFIG_TYPES.find((t) => t.value === selectedType)}
            onChange={(e) => setSelectedType(e.value?.value)}
            //placeholder="Select type..."
          />
        </div>

        <div className="pt-3">
          <SubmitButton label="Next" uppercase={false} full={false} disabled={!selectedType} onClick={handleNext} />
        </div>
      </div>
    </TransitionAnimation>
  );
};

export default SelectConfigType;
