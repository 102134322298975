import { CLASSIFICATION, DEFAULT_ATTRIBUTE_VALUES, PCClassification } from "../../../../../../types";

export const formatAttributeName = (attributeName: string) => {
    attributeName = attributeName[0].toLowerCase() + attributeName.slice(1);
    // attrName = attrName.replace(/(?<==)([A-Z])/g, ' $1');
    attributeName = attributeName.replace(/([^ ][a-z]|[^a-z]|^)\.|\.(?=[^ ])/g, '$1 ');

    attributeName = attributeName.replace(/\b\w/g, function (c: any) {
      return c.toUpperCase();
    });
    // Add a regular expression to insert space before capital letters not at the start
    attributeName = attributeName.replace(/([a-z])([A-Z])/g, '$1 $2');
    return attributeName;
  };

export const buildSchemasFromClassificationIds = (classificationIds: number[], attributeName: string) : { [key: string]: PCClassification } => {
  const defaultUnknownValue = {
    visible: true,
    name: 'Unknown',
    color: [0.5, 0.5, 0.5, 1],
  };
  const classificationSchemas: any = {};
  classificationIds.forEach((classId) => {
    const defaultSchemas = DEFAULT_ATTRIBUTE_VALUES["DEFAULT"];
    if(attributeName === CLASSIFICATION && defaultSchemas[classId]) {
      classificationSchemas[classId] = {
        index: classId,
        visible: true,
        name: defaultSchemas[classId].name,
        color: defaultSchemas[classId].color,
      };
    }
    else {
      classificationSchemas[classId] = defaultUnknownValue;
      classificationSchemas[classId].index = classId;
    }
  });
  return classificationSchemas;
}