import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Avatar } from '@progress/kendo-react-layout';
import { SvgIcon, Typography } from '@progress/kendo-react-common';
import { useUser } from '../../../hooks/authentication';
import WebsocketMonitor from '../../../components/WebsocketMonitor';
import HamburgerMenu from '../../../components/HamburgerMenu';
import { ReactComponent as ServersIcon } from '../../../assets/icons/servers.svg';
import { UserRole } from '../../../types';
import { useAppContext } from '../../../context/app';
import MobileMenu from './MobileMenu';
import { caretAltDownIcon, uploadIcon } from '@progress/kendo-svg-icons';
import UnsubscribedDialog from '../../../components/UnsubscribedDialog';
import UserContextMenu from './UserContextMenu';
import AdminContextMenu from './AdminContextMenu';

const Container = styled.div`
  background: var(--geosap-navbar-color);
  width: 100%;
  height: var(--geosap-navbar-width);
`;

interface MenuOption {
  path: string;
  icon?: JSX.Element;
  style?: React.CSSProperties;
  requiredRole?: UserRole;
  hide?: boolean;
}

console.log('NODEENV', process.env.NODE_ENV);

const Navbar: React.FC = () => {
  const { dispatch, unsubscribedPopupVisible } = useAppContext();
  const location = useLocation();
  const [showWebsockMonitor, setShowWebsockMonitor] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [username, setUsername] = useState('');
  const [showUserContextMenu, setShowUserContextMenu] = React.useState(false);
  const [userContextOffset, setUserContextOffset] = React.useState(null);
  const [hoveringUserOptions, setHoveringUserOptions] = React.useState(false);
  const [showAdminContextMenu, setShowAdminContextMenu] = React.useState(false);
  const [adminContextOffset, setAdminContextOffset] = React.useState(null);
  const navigate = useNavigate();
  const mobileMenuAnchorRef = useRef<HTMLDivElement>(null);
  const { getUser, getCurrentOrganization, userHasRole } = useUser();

  useEffect(() => {
    document.addEventListener('click', () => {
      setShowUserContextMenu(false);
      setShowAdminContextMenu(false);
      setMenuOpen(false);
    });
  }, [showAdminContextMenu]);

  const isOptionSelected = (path: string) => location.pathname.slice(1, location.pathname.length).startsWith(path);

  useEffect(() => {
    const user = getUser();
    if (user?.email) {
      const emailName = user.email.split('@').shift();
      setUsername(emailName.length > 10 ? `${emailName.slice(0, 10)}...` : emailName);
    }
  }, []);

  return (
    <Container className="d-flex">
      <UserContextMenu
        show={showUserContextMenu}
        offset={userContextOffset}
        onCloseContextMenu={() => {
          setShowUserContextMenu(false);
        }}
      ></UserContextMenu>
      <AdminContextMenu
        show={showAdminContextMenu}
        offset={adminContextOffset}
        onShowWebsocketMonitor={() => {
          setShowWebsockMonitor(true);
        }}
        onCloseContextMenu={() => {
          setShowAdminContextMenu(false);
        }}
      ></AdminContextMenu>
      <UnsubscribedDialog
        show={unsubscribedPopupVisible}
        onClose={() => {
          dispatch({ type: 'CHANGE_SHOW_UNSBSCRIBED_DIALOG', payload: { show: false } });
        }}
      ></UnsubscribedDialog>
      <WebsocketMonitor
        show={showWebsockMonitor}
        onClose={() => {
          setShowWebsockMonitor(false);
        }}
      ></WebsocketMonitor>
      <div
        style={{
          width: '160px',
          height: '100%',
          background: 'black',
          cursor: 'pointer',
        }}
        onClick={() => {
          navigate('dashboard');
        }}
      >
        <img
          src="/assets/brand-white-v2.png"
          style={{ width: '100%', height: '100%', padding: '1.1rem', objectFit: 'contain' }}
        ></img>
      </div>
      <div
        style={{
          width: '160px',
          height: '100%',
          background: isOptionSelected('delivery') ? 'var(--gs-lightgray-bg)' : 'transparent',
          cursor: 'pointer',
          color: 'white',
          display: 'flex',
        }}
        onClick={() => {
          navigate('delivery');
        }}
      >
        <span className="font-exo" style={{ margin: 'auto', fontWeight: '300', fontSize: '1.1rem' }}>
          Delivery
        </span>
      </div>
      <div
        style={{
          width: '160px',
          height: '100%',
          background: isOptionSelected('processor') ? 'var(--gs-lightgray-bg)' : 'transparent',
          cursor: 'pointer',
          color: 'white',
          display: 'flex',
        }}
        onClick={() => {
          navigate('processor');
        }}
      >
        <span className="font-exo" style={{ margin: 'auto', fontWeight: '300', fontSize: '1.1rem' }}>
          Processor
        </span>
      </div>
      {/*<ul className="flex-grow-1 d-flex overflow-auto">
        {menuOptions.map(({ path, icon, style, requiredRole, hide }) =>
          (requiredRole && !userHasRole(requiredRole)) || hide ? null : (
            <li key={path} style={style}>
              {icon && (
                <StyledLink
                  to={`/${path}`}
                  className={`d-block p-4 text-center ${isOptionSelected(path) && 'selected'}`}
                >
                  {icon}
                </StyledLink>
              )}
            </li>
          )
        )}
      </ul>*/}
      <div
        ref={mobileMenuAnchorRef}
        className="d-lg-none position-relative"
        style={{
          ...{ marginLeft: 'auto', padding: '1.5rem' },
          ...(menuOpen ? { background: 'white', color: 'var(--geosap-navbar-color)' } : {}),
        }}
      >
        <HamburgerMenu
          open={menuOpen}
          toggle={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.preventDefault();
            e.stopPropagation();
            setMenuOpen(!menuOpen);
          }}
          bgColor={menuOpen ? 'var(--geosap-navbar-color)' : 'white'}
        />
        <MobileMenu show={menuOpen} anchor={mobileMenuAnchorRef.current} handleClose={() => setMenuOpen(false)} />
      </div>

      <div className="align-items-center d-flex" style={{ marginLeft: 'auto' }}>
        {userHasRole(UserRole.ROLE_SYSTEMADMIN) && (
          <div
            style={{ width: 'auto', height: '100%', cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              if (showAdminContextMenu) {
                setShowAdminContextMenu(false);
              } else {
                setShowAdminContextMenu(true);
                const rect = e.currentTarget.getBoundingClientRect();
                setAdminContextOffset({ left: e.clientX, top: rect.bottom });
              }
            }}
          >
            <ServersIcon style={{ width: '100%', height: '100%', objectFit: 'contain', padding: '1.1rem' }} />
          </div>
        )}
        <div
          className="d-flex flex-column"
          style={{
            height: '100%',
            padding: '0.5rem',
          }}
        >
          <div
            className="d-flex"
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: '0.25rem',
              border: '1px solid white',
              borderRadius: '0.5rem',
              cursor: 'pointer',
            }}
            onMouseEnter={() => {
              setHoveringUserOptions(true);
            }}
            onMouseLeave={() => {
              setHoveringUserOptions(false);
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (showUserContextMenu) {
                setShowUserContextMenu(false);
              } else {
                setShowUserContextMenu(true);
                const rect = e.currentTarget.getBoundingClientRect();
                setUserContextOffset({ left: e.clientX, top: rect.bottom });
              }
            }}
          >
            <div className="d-flex flex-column">
              <Typography.p
                fontSize="xsmall"
                themeColor="light"
                className="text-center"
                style={{ fontSize: '1rem', fontWeight: '200', marginBottom: '0' }}
              >
                {getCurrentOrganization()?.name}
              </Typography.p>
              <Typography.p
                fontSize="xsmall"
                themeColor="light"
                className="text-center"
                style={{ fontSize: '0.8rem', fontWeight: '500', marginBottom: '0' }}
              >
                {username}
              </Typography.p>
            </div>
            <SvgIcon
              icon={caretAltDownIcon}
              className="animated-color"
              style={{ marginLeft: '0.5rem', fontSize: '1.2rem', color: hoveringUserOptions ? 'white' : 'gray' }}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Navbar;
