import { DataDeliveryState, DeliveryNavigationMode, initialState, InteractionMode } from './dataDeliveryState';
import { FileNode } from '../../types/DataDelivery';
import DataDeliveryNavigationOptions from '../../types/DataDelivery/FileNodeNavigationOptions';
import { LayerType, SapFlowViewConfig, Unit, ViewLayer, ViewLayerFeature } from '../../types';
import OLMap from 'ol/Map';
import { Projection } from 'ol/proj';

export type DataDeliveryAction =
  | { type: 'SET_VIEW_CONFIG'; payload: SapFlowViewConfig | null }
  | { type: 'SET_ROOT'; payload: FileNode | null }
  | { type: 'SET_OPENLAYERS_ACTIVE'; payload: boolean }
  | { type: 'SET_POTREE_ACTIVE'; payload: boolean }
  | { type: 'SET_OPENED_FILE_NODE'; payload: FileNode | null }
  | { type: 'SET_HOVERED_FILE_NODE'; payload: FileNode | null }
  | { type: 'SET_HOVERED_LAYER'; payload: ViewLayer | null }
  | { type: 'SET_SELECTED_LAYER'; payload: ViewLayer | null }
  | { type: 'SET_SELECTED_FEATURE'; payload: ViewLayerFeature | null }
  | { type: 'SET_SEARCH_TEXT'; payload: string }
  | { type: 'SET_FILTERED_FILE_NODES'; payload: FileNode[] | null }
  | { type: 'SET_VIEWER_HEIGHT'; payload: number }
  | { type: 'SET_NAVIGATION_OPTIONS'; payload: DataDeliveryNavigationOptions }
  | { type: 'SET_NAVIGATION_MODE'; payload: DeliveryNavigationMode }
  | { type: 'SET_INTERACTION_MODE'; payload: InteractionMode }
  | { type: 'SET_VIEWER_3D_OPENED'; payload: boolean }
  | { type: 'SET_OL_MAP'; payload: OLMap | null }
  | { type: 'SET_DISPLAYED_PROJ_2D'; payload: Projection | null }
  | { type: 'SET_POTREE_PROJ'; payload: string | null }
  | { type: 'SET_UNITS'; payload: Unit }
  | { type: 'ZOOM_TO_FILENODE'; payload: FileNode | null }
  | { type: 'ADDED_PROFILE'; payload: any }
  | { type: 'CLEAR_PROFILES' };

export function dataDeliveryReducer(
  state: DataDeliveryState = initialState,
  action: DataDeliveryAction
): DataDeliveryState {
  switch (action.type) {
    case 'SET_VIEW_CONFIG': {
      return parseViewConfig(action.payload, state);
    }
    case 'SET_ROOT':
      return { ...state, rootFileNode: action.payload };
    case 'SET_OPENED_FILE_NODE':
      return { ...state, openedFileNode: action.payload };
    case 'SET_HOVERED_FILE_NODE':
      return { ...state, hoveredFileNode: action.payload };
    case 'SET_HOVERED_LAYER':
      return { ...state, hoveredLayer: action.payload };
    case 'SET_SELECTED_LAYER':
      return { ...state, selectedLayer: action.payload };
    case 'SET_SELECTED_FEATURE':
      return { ...state, selectedFeature: action.payload };
    case 'SET_SEARCH_TEXT':
      return { ...state, searchText: action.payload };
    case 'SET_FILTERED_FILE_NODES':
      return { ...state, filteredFileNodes: action.payload };
    case 'SET_VIEWER_HEIGHT':
      return { ...state, viewerHeight: action.payload };
    case 'SET_NAVIGATION_OPTIONS':
      return { ...state, navigationOptions: action.payload };
    case 'SET_NAVIGATION_MODE':
      return { ...state, navigationMode: action.payload };
    case 'SET_INTERACTION_MODE':
      return { ...state, interactionMode: action.payload };
    case 'SET_VIEWER_3D_OPENED':
      return { ...state, viewer3dOpened: action.payload };
    case 'SET_OPENLAYERS_ACTIVE':
      return { ...state, openLayersActive: action.payload };
    case 'SET_POTREE_ACTIVE':
      return { ...state, potreeActive: action.payload };
    case 'SET_OL_MAP':{
      const proj = action.payload?.getView()?.getProjection();
      return { ...state, olMap: action.payload, olMapProjection: proj, olMapInitialized: true, displayedProjection2D: state.displayedProjection2D ? state.displayedProjection2D : proj, };
    }
    case 'SET_DISPLAYED_PROJ_2D':
      return { ...state, displayedProjection2D: action.payload };
      
    case 'SET_POTREE_PROJ': {
      return {
        ...state,
        potreeProjection: action.payload,
        //units: newUnits,
      };
    }
    case 'SET_UNITS':
      return { ...state, units: action.payload };
    case 'ZOOM_TO_FILENODE':
      return { ...state, zoomToFileNode: action.payload };
    case 'ADDED_PROFILE': {
      const newProfiles = [...state.profiles, action.payload];
      return {
        ...state,
        profiles: newProfiles,
      };
    }
    case 'CLEAR_PROFILES': {
      return {
        ...state,
        profiles: [],
      };
    }
    default:
      return state;
  }
}

const parseViewConfig = (stateViewConfig: SapFlowViewConfig, currentState: DataDeliveryState): DataDeliveryState => {
  // TODO: Deep copy the view config to avoid modifying the actual query data which might get cached
  const newState: DataDeliveryState = { ...currentState };
  if(!stateViewConfig) {
    newState.viewConfig = null;
    return newState;
  }
  const viewConfig = JSON.parse(JSON.stringify(stateViewConfig));
  newState.viewConfig = viewConfig;
  newState.measurementLayers = [];
  newState.drawingLayers = [];
  const newStatePropertyAssignment = (propertyName: string, defaultValue: any) => {
    newState[propertyName] = currentState[propertyName]
      ? currentState[propertyName]
      : viewConfig.paramsMap[propertyName]
      ? viewConfig.paramsMap[propertyName]
      : defaultValue;
  };
  newStatePropertyAssignment('baseMap', { id: '1', type: 'OpenStreetMap', displayName: 'OpenStreetMap' });
  newState.units = viewConfig.paramsMap.units ? viewConfig.paramsMap.units : newState.units;
  //newState.appearance3D = viewConfig.paramsMap.appearance3D ? viewConfig.paramsMap.appearance3D : newState.appearance3D;

  let i = 0;
  for (i = 0; i < viewConfig.layers.length; i++) {
    const layer: ViewLayer = viewConfig.layers[i];
    if(layer.layerType === LayerType.Drawing2D) {
      newState.drawingLayers.push(layer);
    }
    if(layer.layerType === LayerType.Measurement2D || layer.layerType === LayerType.Measurement3D) {
      newState.measurementLayers.push(layer);
    }
  }
  newState.selectedLayer = null;
  return newState;
};