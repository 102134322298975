import React, { useReducer, useContext } from 'react';
import { DataProcessorState, initialState } from './dataProcessorState';
import { dataProcessorReducer, DataProcessorAction } from './dataProcessorReducer';

export interface DataProcessorProviderValue extends DataProcessorState {
  dispatch: (action: DataProcessorAction) => void;
}

interface Props {
  children: JSX.Element | JSX.Element[];
  value: DataProcessorProviderValue;
}

const DataProcessorContext = React.createContext({
  ...initialState,
  dispatch: () => {
    // not implemented
  },
} as DataProcessorProviderValue);

export const DataProcessorProvider: React.FC<Props> = ({ children, value }) => {
  return <DataProcessorContext.Provider value={value}>{children}</DataProcessorContext.Provider>;
};

/*
  Create Data Processor state.
*/
export const useCreateDataProcessorState = (): DataProcessorProviderValue => {
  const [state, dispatch] = useReducer(dataProcessorReducer, {
    ...initialState,
  });
  return {
    ...state,
    dispatch,
  };
};

/*
  Consume Data Processor state.
*/
export const useConsumeDataProcessorState = () => {
  return useContext(DataProcessorContext);
};
