import React, { useState, useRef, useEffect } from 'react';
import { Input, SubmitButton } from '../../../../components/form';
import { Typography } from '@progress/kendo-react-common';
import { Upload, UploadFileInfo } from '@progress/kendo-react-upload';
import { useConsumeAddLayerWizardState } from '../../../../context/addLayerWizard';
import TransitionAnimation from '../../../../components/TransitionAnimation';
import { useUser } from '../../../../hooks/authentication';
import { useSapFlow, useCreateTransactionItemNode } from '../../../../hooks/sapflow';
import ExpansionPanel from '../../../../components/ExpansionPanel';
import { useAxiosInstance } from '../../../../hooks/common';
import { useView, useViewerConfigUpdate } from '../../../../hooks/viewerconfig';
import { useAppContext } from '../../../../context/app';
import { SapFlowViewConfig, BandInfo, SapFlow } from '../../../../types';
import useInspectGeotiff from '../../../../hooks/viewerconfig/useInspectGeotiff';
import { AxiosProgressEvent } from 'axios';

const URL = 'URL';
const UPLOAD = 'UPLOAD';

const SelectGeotif: React.FC = () => {
  const appContext = useAppContext();
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const { dispatch, viewerConfigId } = useConsumeAddLayerWizardState();
  const { getUploadUrl } = useSapFlow();
  const [url, setUrl] = useState<string>('');
  const uploadRef = useRef<Upload>(null);
  const { getUser } = useUser();
  const axios = useAxiosInstance();
  const transactionItemMutation = useCreateTransactionItemNode(viewerConfigId);
  const viewConfigQuery = useView(viewerConfigId);
  const viewerConfigUpdateMutation = useViewerConfigUpdate();
  const inspectGeotiffMutation = useInspectGeotiff();

  const addGeotiffMetadata = async (url: string, transactionItem: SapFlow) => {
    // Now for Geotiff, extract metadata
    const urlToInspect = '/vsicurl/' + url;
    const geotiffInfos: { bands: BandInfo[] } = await inspectGeotiffMutation.mutateAsync({ url: urlToInspect });
    geotiffInfos.bands.forEach((band: BandInfo) => {
      band.currentMin = band.min;
      band.currentMax = band.max;
    });
    const result = await viewConfigQuery.refetch();
    if (result.isSuccess) {
      const updatedViewConfig: SapFlowViewConfig = result.data;
      let i = 0;
      for (i = 0; i < updatedViewConfig.layers.length; i++) {
        if (updatedViewConfig.layers[i].id === transactionItem.id) {
          updatedViewConfig.layers[i].params.push({ key: 'bands', value: JSON.stringify(geotiffInfos.bands) });
          break;
        }
      }

      viewerConfigUpdateMutation.mutateAsync({ layers: updatedViewConfig.layers, viewerId: updatedViewConfig.id });
    } else {
      console.log("Couldn't retrieve view config.");
    }
  };

  const handleAddGeotifUrl = async () => {
    const fileName = url.substring(url.lastIndexOf('/') + 1);
    const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
    const transactionItem = await transactionItemMutation.mutateAsync({
      name: fileName ? fileName : 'urlFile',
      fileformat: fileExtension ? fileExtension : '.temp',
      visualize: true,
      url: url,
    });
    await addGeotiffMetadata(url, transactionItem);
  };

  const handleUploadGeotif = async (
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any },
    onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
  ) => {
    // First, parse the added files and identify the file types
    let i = 0;
    let fileName = 'Testfile';
    let fileExtension: any = null;
    for (i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.extension === '.shp') {
        fileName = file.name;
        fileExtension = '.shp';
      } else if (file.extension === '.tif') {
        fileName = file.name;
        fileExtension = '.tif';
      }
    }

    // TODO: Then, validate provided files...

    // Create the transaction node for the specified files.
    if (fileExtension !== null) {
      const transactionItem = await transactionItemMutation.mutateAsync({
        name: fileName,
        fileformat: fileExtension,
        visualize: true,
        url: '',
      });

      i = 0;
      for (i = 0; i < files.length; i++) {
        const formData = new FormData();
        const file = files[i];
        const rawFile = file.getRawFile();
        formData.append('files', rawFile);
        await axios.post(getUploadUrl(viewerConfigId, transactionItem.id, true), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            onProgress(
              file.uid,
              new ProgressEvent('progress', {
                loaded: progressEvent.loaded,
                total: progressEvent.total,
                lengthComputable: progressEvent.total !== undefined,
              })
            );
            const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            if (percentCompleted === 100) {
              uploadRef.current.onUploadSuccess(file.uid);
            }
          },
        });
      }
    } else {
      for (i = 0; i < files.length; i++) {
        const file = files[i];
        //onProgress(file.uid, new AxiosProgressEvent('abort'));
      }
      return;
    }

    return { uid: '' };
  };

  useEffect(() => {
    if (transactionItemMutation.isSuccess) {
      appContext.dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { type: 'success', content: 'Successfully added Layer.' } },
      });
      dispatch({ type: 'DONE', payload: {} });
    } else if (transactionItemMutation.isError) {
      appContext.dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          notification: { type: 'error', content: 'Unable to add layer at this moment. Pleas try again later.' },
        },
      });
    }
  }, [transactionItemMutation.isSuccess, transactionItemMutation.isError]);

  return (
    <TransitionAnimation>
      <div style={{ paddingTop: '2rem' }}>
        {/* URL */}
        <ExpansionPanel
          key={URL}
          id={URL}
          title={URL}
          expanded={selectedOption === URL}
          onAction={(expanded) => {
            setSelectedOption(expanded ? null : URL);
          }}
        >
          <Typography.h5
            style={{
              margin: '25px',
            }}
          >
            {'Please enter a Geotif URL.'}
          </Typography.h5>
          <div
            style={{
              padding: '0px 25px',
            }}
          >
            <Input
              type="text"
              name="URL"
              value={url}
              onChange={(e: any) => {
                setUrl(e.target.value);
              }}
            />
          </div>
          <div className="d-flex justify-content-end my-4 me-4">
            <SubmitButton
              label={`Add Geotif layer`}
              uppercase={false}
              full={false}
              onClick={() => {
                handleAddGeotifUrl();
              }}
            />
          </div>
        </ExpansionPanel>

        {/* UPLOAD */}
        <ExpansionPanel
          key={UPLOAD}
          id={UPLOAD}
          title={UPLOAD}
          expanded={selectedOption === UPLOAD}
          onAction={(expanded) => {
            setSelectedOption(expanded ? null : UPLOAD);
          }}
          disabled // Will get enabled when backend can handle geotifs and get them available through gs-image-server
        >
          <div style={{ margin: '25px' }}>
            <Upload
              ref={uploadRef}
              autoUpload={false}
              batch={true}
              defaultFiles={[]}
              withCredentials={false}
              saveUrl={handleUploadGeotif}
              accept={'.tif'}
              restrictions={{
                allowedExtensions: ['.tif'],
              }}
              selectMessageUI={() => <span>Upload visualization files</span>}
            />
          </div>
        </ExpansionPanel>
      </div>
    </TransitionAnimation>
  );
};

export default SelectGeotif;
