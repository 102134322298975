import React, { useEffect, useState } from 'react';
import { Slider, SliderLabel } from '@progress/kendo-react-inputs';
import DraggableWindow from '../../../../../../components/DraggableWindow';
import { BandInfo, LayerType, ViewLayer, VisualizationType, RAMP_COLORS } from '../../../../../../types';
import { useConsumeViewerState } from '../../../../../../context/viewer';
import { updateLayerParam } from '../../../../../../common/viewerConfigHelper';
import { getOrGenerateDefaultVisualizationParams } from '../../../../../../converters/viewLayerHelper';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import SinglebandControls from './SinglebandControls';
import RgbControls from './RgbControls';
import HillshadeControls from './HillshadeControls';
import RasterPropertiesControls from './RasterPropertiesControls';
//import VectorControls from './VectorControls';

interface RasterControlsProps {
  layer: ViewLayer;
  onLayerUpdated?: (layer: ViewLayer) => void;
}

const RasterControls: React.FC<RasterControlsProps> = (props: RasterControlsProps) => {
  const [availableVisualizations, setAvailableVisualizations] = useState<VisualizationType[]>([]);
  const [currentlySelectedVisualization, setCurrentlySelectedVisualization] = useState<VisualizationType>(null);
  const [internalSelectedLayer, setInternalSelectedLayer] = useState<ViewLayer>(null);
  const [currentOpacity, setCurrentOpacity] = useState<number>(1);
    const [showRasterProperties, setShowRasterProperties] = useState(false);

  useEffect(() => {
    let newInternalLayer = { ...props.layer };
    //console.log('NewInternal Layer: ' + JSON.stringify(newInternalLayer));
    if (!props.layer) {
      // No selectd layer
    } else if (
      props.layer.id === internalSelectedLayer?.id &&
      props.layer.lastUpdated === internalSelectedLayer.lastUpdated
    ) {
      // No new changes to consider
    } else {
      const visualizations: VisualizationType[] = [];
      const visualization = getOrGenerateDefaultVisualizationParams(props.layer);
      if (props.layer.layerType === LayerType.GeoTif || props.layer.layerType === LayerType.GSRAS) {
        if (visualization?.singleband) {
          visualizations.push(VisualizationType.SINGLEBAND);
          visualizations.push(VisualizationType.HILLSHADE);
        }
        if (visualization?.rgb) {
          visualizations.push(VisualizationType.RGB);
        }
      }
      if (visualizations.length <= 0) {
        visualizations.push(VisualizationType.UNAVAILABLE);
      }
      props.layer.paramsMap['visualization'] = visualization;
      setAvailableVisualizations(visualizations);
      setCurrentlySelectedVisualization(visualization?.type);
      if (visualization && visualization['opacity'] !== null && visualization['opacity'] !== undefined) {
        setCurrentOpacity(visualization['opacity']);
      }
      if (!props.layer.paramsMap['visualization']) {
        const newLayer = JSON.parse(JSON.stringify(props.layer));
        updateLayerParam(newLayer, 'visualization', visualization);
        newInternalLayer = newLayer;
        props.onLayerUpdated(newLayer);
      }
    }
    setInternalSelectedLayer(newInternalLayer);
  }, [props.layer]);

  if (!internalSelectedLayer || internalSelectedLayer.layerType !== LayerType.GeoTif) return null;

  return (
    <div>
      {internalSelectedLayer.layerType === LayerType.GeoTif && (
        <div
          style={{ width: '100%', height: '10px' }}
          onClick={() => {
            setShowRasterProperties(!showRasterProperties);
          }}
        ></div>
      )}
      {showRasterProperties && (
        <RasterPropertiesControls layer={internalSelectedLayer} onLayerUpdated={props.onLayerUpdated} />
      )}
      <div className="d-flex flex-column p-2">
        <FloatingLabel
          label={'Visualization Type'}
          editorId={'visType'}
          editorValue={true}
          className="w-75 mx-auto pb-2"
        >
          <DropDownList
            style={{
              width: '200px',
            }}
            size="small"
            data={availableVisualizations}
            value={currentlySelectedVisualization}
            onChange={(event: any) => {
              const newLayer = JSON.parse(JSON.stringify(props.layer));
              const vis = newLayer.paramsMap.visualization;
              vis.type = event.target.value;
              updateLayerParam(newLayer, 'visualization', vis);
              setCurrentlySelectedVisualization(event.target.value);
              setInternalSelectedLayer(newLayer);
              props.onLayerUpdated(newLayer);
            }}
            disabled={props.layer === null}
            popupSettings={{ width: 250 }}
          />
        </FloatingLabel>
      </div>
      <div className="d-flex flex-column p-2">
        <FloatingLabel
          label={'Opacity'}
          editorId={'opacity'}
          editorValue={currentlySelectedVisualization ? '' + currentlySelectedVisualization : ''}
          className="w-75 mx-auto pb-2"
        >
          <Slider
            min={0}
            max={1}
            step={0.01}
            value={currentOpacity}
            onChange={(e) => {
              const newLayer = JSON.parse(JSON.stringify(props.layer));
              const vis = newLayer.paramsMap.visualization;
              vis.opacity = e.value;
              updateLayerParam(newLayer, 'visualization', vis);
              setCurrentOpacity(e.value);
              setInternalSelectedLayer(newLayer);
              props.onLayerUpdated(newLayer);
            }}
          >
            <SliderLabel position={0}>0%</SliderLabel>
            <SliderLabel position={1}>100%</SliderLabel>
          </Slider>
        </FloatingLabel>
      </div>
      {internalSelectedLayer && currentlySelectedVisualization === VisualizationType.SINGLEBAND && (
        <SinglebandControls layer={internalSelectedLayer} onLayerUpdated={props.onLayerUpdated} />
      )}
      {internalSelectedLayer && currentlySelectedVisualization === VisualizationType.RGB && (
        <RgbControls layer={internalSelectedLayer} onLayerUpdated={props.onLayerUpdated} />
      )}
      {internalSelectedLayer && currentlySelectedVisualization === VisualizationType.HILLSHADE && (
        <HillshadeControls layer={internalSelectedLayer} onLayerUpdated={props.onLayerUpdated} />
      )}
      {/*internalSelectedLayer && currentlySelectedVisualization === VisualizationType.VECTOR && (
        <VectorControls layer={internalSelectedLayer} onLayerUpdated={props.onLayerUpdated} />
      )*/}
    </div>
  );
};

export default RasterControls;
