import React, { useEffect } from 'react';
import { RangeSlider, Slider } from '@progress/kendo-react-inputs';
import ClassificationToggle from './ClassificationToggle';
import {
  AuthorityLevel,
  AuthorityType,
  PCAttributeType,
  PointcloudVisualization,
  RGBA,
  ViewLayer,
} from '../../../../../../types';
import { updateLayerParam } from '../../../../../../common/viewerConfigHelper';
import { useUser } from '../../../../../../hooks/authentication';

// To avoid transpiler error on window.Potree  https://stackoverflow.com/questions/56457935/typescript-error-property-x-does-not-exist-on-type-window
declare const window: any;
const Potree = window.Potree;

interface RGBAControlsProps {
  layer: ViewLayer;
  onLayerUpdate?: (layer: ViewLayer, persistent?: boolean) => void;
  onAttributeValueChange: (visualizationParamPath: string, value: any) => void;
  onAttributeRangeChange: (visualizationParamPath: string, newMin: any, newMax: any) => void;
  onClassificationUpdate?: (
    layer: ViewLayer,
    attributeName: string,
    id: number,
    name: string,
    visible: boolean,
    color: number[]
  ) => void;
}

const RGBAControls: React.FC<RGBAControlsProps> = (props: RGBAControlsProps) => {
  const visualization = props.layer?.paramsMap?.visualization;
  const rgba = visualization?.attributeValues ? visualization?.attributeValues[RGBA] : null;

  if (!rgba) {
    return null;
  }

  const gamma = rgba.gamma;
  const brightness = rgba.brightness;
  const contrast = rgba.contrast;
  return (
    <div className="flex-column d-flex m-2">
      <span>Gamma</span>
      <Slider
        min={0}
        max={4}
        step={0.01}
        defaultValue={gamma}
        onChange={(e) => {
          props.onAttributeValueChange('rgba.gamma', e.value);
        }}
      ></Slider>
      <span>Brightness</span>
      <Slider
        min={-1}
        max={1}
        step={0.01}
        defaultValue={brightness}
        onChange={(e) => {
          props.onAttributeValueChange('rgba.brightness', e.value);
        }}
      ></Slider>
      <span>Contrast</span>
      <Slider
        min={-1}
        max={1}
        step={0.01}
        defaultValue={contrast}
        onChange={(e) => {
          props.onAttributeValueChange('rgba.contrast', e.value);
        }}
      ></Slider>
    </div>
  );
};

export default RGBAControls;
