import DataDeliveryNavigationOptions from '../../types/DataDelivery/FileNodeNavigationOptions';
import { FileNode } from '../../types/DataDelivery';
import { DrawingMode, FeatureAttributeType, SapFlowViewConfig, Unit, ViewLayer, ViewLayerFeature, ViewLayerFeatureFilter } from '../../types';
import OLMap from 'ol/Map';
import { Projection } from 'ol/proj';
import { Feature } from 'ol';

export interface DataDeliveryState {
  viewConfig: SapFlowViewConfig | null;
  rootFileNode: FileNode | null;
  openedFileNode: FileNode | null;
  hoveredFileNode: FileNode | null;
  selectedLayer: ViewLayer | null;
  selectedFeature: ViewLayerFeature | null;
  measurementLayers: ViewLayer[];
  drawingLayers: ViewLayer[];
  hoveredLayer: ViewLayer | null;
  searchText: string;
  filteredFileNodes: FileNode[] | null;
  viewerHeight: number;
  navigationOptions: DataDeliveryNavigationOptions;
  navigationMode: DeliveryNavigationMode;
  zoomToFileNode: FileNode | null;
  interactionMode: InteractionMode;
  viewer3dOpened: boolean;
  openLayersActive: boolean;
  potreeActive: boolean;
  units: Unit;
  potreeProjection: string;
  olMap: OLMap,
  olMapInitialized: boolean,
  olMapProjection: Projection,
  displayedProjection2D: Projection;
  profiles: any[];
  featureFilter: ViewLayerFeatureFilter;
  [x: string]: any;
}

export enum DeliveryNavigationMode {
  LAYERS,
  MEASUREMENTS,
  PROCESSES
}

export enum InteractionMode {
  NAVIGATION="NAVIGATION",
  LINE_MEASUREMENT_2D="LINE_MEASUREMENT_2D",
  POLYGON_MEASUREMENT_2D="POLYGON_MEASUREMENT_2D",
  POINT_MEASUREMENT_3D="POINT_MEASUREMENT_3D",
  DISTANCE_MEASUREMENT_3D="DISTANCE_MEASUREMENT_3D",
  HEIGHT_MEASUREMENT_3D="HEIGHT_MEASUREMENT_3D",
  VOLUME_MEASUREMENT_3D="VOLUME_MEASUREMENT_3D",
  PROFILE_MEASUREMENT_3D="PROFILE_MEASUREMENT_3D",
  POINT_DRAWING_2D="POINT_DRAWING_2D",
  LINESTRING_DRAWING_2D="LINESTRING_DRAWING_2D",
  POLYGON_DRAWING_2D="POLYGON_DRAWING_2D",
  CIRCLE_DRAWING_2D="CIRCLE_DRAWING_2D"
}

export const initialState: DataDeliveryState = {
  viewConfig: null,
  rootFileNode: null,
  openedFileNode: null,
  openLayersActive: false,
  potreeActive: false,
  hoveredFileNode: null,
  selectedLayer: null,
  selectedFeature: null,
  measurementLayers: [],
  drawingLayers: [],
  hoveredLayer: null,
  searchText: "",
  filteredFileNodes: null,
  viewerHeight: 900,
  navigationOptions: { showSupport: false, baseMap: null },
  navigationMode: DeliveryNavigationMode.LAYERS,
  zoomToFileNode: null,
  interactionMode: InteractionMode.NAVIGATION,
  viewer3dOpened: false,
  units: Unit.FEET,
  potreeProjection:
    '+proj=lcc +lat_0=32.1666666666667 +lon_0=-116.25 +lat_1=33.8833333333333 +lat_2=32.7833333333333 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +units=us-ft +vunits=us-ft +no_defs +type=crs',
  olMap: null,
  olMapInitialized: false,
  olMapProjection: null,
  displayedProjection2D: null,
  featureFilter: null,
  profiles: []
};
