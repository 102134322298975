import React, { Fragment, useEffect, useState } from 'react';
import { useGenerateSapFlowForCollection } from '../../../hooks/data_wrangling';
import { useUser } from '../../../hooks/authentication';
import { useDataCollectionWizardState } from '../../../context/dataCollectionWizard';
import { useAppContext } from '../../../context/app';

const AdjustConfig: React.FC = () => {
  const { dispatch, state } = useDataCollectionWizardState();
  const { dispatch: appDispatch } = useAppContext();
  const { getCurrentOrganization } = useUser();
  const generateSapflowMutation = useGenerateSapFlowForCollection(getCurrentOrganization().id);
  const [generating, setGenerating] = useState(false);

  const generateSapflow = async () => {
    if (generating) return;
    setGenerating(true);
    generateSapflowMutation
      .mutateAsync({
        projectId: state.clientProjectId,
        collectionType: state.configType,
        collectionPath: state.path,
      })
      .then(() => {
        appDispatch({
          type: 'SHOW_NOTIFICATION',
          payload: { notification: { type: 'success', content: 'Successfully generated Sapflow.' } },
        });
        dispatch({
          type: 'COMPLETE_WIZARD',
          payload: {},
        });
      })
      .catch(() => {
        appDispatch({
          type: 'SHOW_NOTIFICATION',
          payload: { notification: { type: 'error', content: 'Problem generating sapflow. try again later.' } },
        });
        dispatch({
          type: 'COMPLETE_WIZARD',
          payload: {},
        });
      });
  };

  useEffect(() => {
    generateSapflow();
  });

  return <div style={{ paddingTop: '4rem' }}></div>;
};

export default AdjustConfig;
