import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useAppContext } from '../../../../context/app';

interface AnalysisItemProps {
  title: string;
  description: string;
  output: string;
}

const AnalysisItem: React.FC<AnalysisItemProps> = ({ title, description, output }) => {
  const { dispatch } = useAppContext();
  const [isExpanded, setIsExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState<string | number>(0);
  const contentRef = React.useRef<HTMLDivElement>(null);

  const toggleExpand = () => {
    if (contentRef.current) {
      setContentHeight(isExpanded ? 0 : contentRef.current.scrollHeight);
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <div
      style={{ margin: '10px 0', overflow: 'hidden', background: isExpanded ? '#747373' : 'transparent' }}
      className="animated-background"
    >
      <div
        onClick={toggleExpand}
        style={{
          cursor: 'pointer',
          //background: isExpanded ? '#333' : '#444',
          color: 'white',
          padding: '10px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <svg width="24" height="24" viewBox="0 0 24 24" style={{ transition: 'transform 0.3s ease' }}>
          <line
            x1="4"
            y1="12"
            x2="20"
            y2="12"
            stroke="white"
            strokeWidth="3"
            style={{
              transformOrigin: 'center',
              transition: 'transform 0.3s ease',
              transform: isExpanded ? 'rotate(0deg)' : 'rotate(90deg)',
            }}
          />
          <line x1="4" y1="12" x2="20" y2="12" stroke="white" strokeWidth="3" style={{ transformOrigin: 'center' }} />
        </svg>
        <span style={{ paddingLeft: '1rem', fontWeight: 550, fontSize: '1.2rem' }}>{title}</span>
      </div>
      <div
        ref={contentRef}
        style={{
          height: contentHeight,
          overflow: 'hidden',
          transition: 'height 0.3s ease',
        }}
      >
        <div style={{ padding: '15px', display: 'flex', flexDirection: 'column' }}>
          <p>
            <strong>Description:</strong> {description}
          </p>
          <p>
            <strong>Output:</strong> {output}
          </p>
          <button
            style={{
              backgroundColor: 'rgb(224, 73, 59)',
              color: 'white',
              padding: '5px 10px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              marginLeft: 'auto',
            }}
            onClick={() => {
              dispatch({
                type: 'SHOW_NOTIFICATION',
                payload: { notification: { type: 'success', content: "That would be cool wouldn't it." } },
              });
            }}
          >
            Run Analysis
          </button>
        </div>
      </div>
    </div>
  );
};
export default AnalysisItem;
