import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useAppContext } from '../../../context/app';
import { ProcessorNavigationMode, useConsumeDataProcessorState } from '../../../context/dataProcessorContext';

const Dashboard = () => {
  const { screenWidth, dispatch: appDispatch } = useAppContext();
  const { dispatch, navigationMode } = useConsumeDataProcessorState();

  let style: React.CSSProperties = { position: 'absolute', left: 0, top: 0, bottom: 0, zIndex: 1 };
  if (screenWidth < 922) {
    style = { width: '100%', height: '300px', zIndex: 1, borderRadius: 0 };
  }

  return <div className="d-flex flex-column"></div>;
};

export default Dashboard;
