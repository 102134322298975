import { useQuery } from 'react-query';
import { useAxiosInstance } from '../../common';
import { SapFlowProject } from '../../../types';

const useProject = (projectId: string) => {
  const axios = useAxiosInstance();
  return useQuery(['project', projectId], async () => {
    const result = await axios.get<SapFlowProject>("project/" + projectId);
    return result.data;
  });
};

export default useProject;
