import React, { useEffect } from 'react';
import { RangeSlider, Slider } from '@progress/kendo-react-inputs';
import { ELEVATION, PCAttributeVisualization, ViewLayer } from '../../../../../../types';
// To avoid transpiler error on window.Potree  https://stackoverflow.com/questions/56457935/typescript-error-property-x-does-not-exist-on-type-window
declare const window: any;

interface ElevationControlsProps {
  layer: ViewLayer;
  onLayerUpdate?: (layer: ViewLayer, persistent?: boolean) => void;
  onAttributeValueChange: (visualizationParamPath: string, value: any) => void;
  onAttributeRangeChange: (visualizationParamPath: string, newMin: any, newMax: any) => void;
  onClassificationUpdate?: (
    layer: ViewLayer,
    attributeName: string,
    id: number,
    name: string,
    visible: boolean,
    color: number[]
  ) => void;
}

const ElevationControls: React.FC<ElevationControlsProps> = (props: ElevationControlsProps) => {
  const visualization = props.layer?.paramsMap?.visualization;
  const elevation: PCAttributeVisualization = visualization?.attributeValues
    ? visualization?.attributeValues[ELEVATION]
    : null;

  if (!elevation) {
    return null;
  }
  return (
    <div className="flex-column d-flex m-2">
      <span>
        Range: {elevation.currentMin.toFixed(2)} to {elevation.currentMax.toFixed(2)}
      </span>
      <RangeSlider
        min={elevation.min}
        max={elevation.max}
        step={0.01}
        defaultValue={{ start: elevation.currentMin, end: elevation.currentMax }}
        onChange={(e: any) => {
          props.onAttributeRangeChange(ELEVATION, e.value.start, e.value.end);
        }}
      ></RangeSlider>
    </div>
  );
};

export default ElevationControls;
