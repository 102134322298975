import React from 'react';
import { ReactComponent as DashboardIcon } from '../../../assets/icons/dataprocessor_dashboard.svg';
import { ReactComponent as ProcessingIcon } from '../../../assets/icons/dataprocessor_processing.svg';
import { ProcessorNavigationMode, useConsumeDataProcessorState } from '../../../context/dataProcessorContext';

const NavigationModeBar: React.FC = () => {
  const { dispatch, navigationMode } = useConsumeDataProcessorState();
  const modeOptions = [
    //{ Icon: DashboardIcon, navigationMode: ProcessorNavigationMode.DASHBOARD },
    { Icon: ProcessingIcon, navigationMode: ProcessorNavigationMode.PROCESSING },
  ];
  return (
    <div
      className="d-flex flex-column"
      style={{ width: 'var(--geosap-delivery-navbar-mode-width)', height: '100%', background: 'black' }}
    >
      {modeOptions.map((mode, index) => {
        return (
          <div
            className="p-4"
            style={{
              position: 'relative',
              opacity: mode.navigationMode === navigationMode ? 1 : 0.5,
              cursor: 'pointer',
            }}
            key={index}
            onClick={() => {
              dispatch({ type: 'SET_NAVIGATION_MODE', payload: mode.navigationMode });
            }}
          >
            <mode.Icon style={{ width: '100%', height: '100%' }} fill={'white'} stroke="white" />
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: '10%',
                bottom: '10%',
                width: '6px',
              }}
            ></div>
          </div>
        );
      })}
    </div>
  );
};

export default NavigationModeBar;
