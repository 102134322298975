import { useContext, useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';
import {
  Appearance3D,
  BandInfo,
  PCAttributeVisualization,
  PointcloudVisualization,
  PCAttributeType,
  PotreePointcloudAttribute,
  RGBA,
  INTENSITY,
  ELEVATION,
  CLASSIFICATION,
  IGNORED_ATTRIBUTES,
} from '../../../../types';
import { ViewLayer } from '../../../../types';

declare const window: any;

const Potree = window.Potree;
const THREE = window.THREE;
const proj4 = window.proj4 || require('proj4');

interface Props {
  layer: ViewLayer;
  show: boolean;
  potreeViewer: any;
  appearance3D: Appearance3D;
  zIndex: number;
  onReady?: () => void;
}

type Ref = {
  zoomTo: () => void;
  //handleMapClick: (evt: MapBrowserEvent<any>) => Promise<Layer2DClickResult>;
} | null;
const PointcloudObject = forwardRef<Ref, Props>((props, ref) => {
  const [potreeNode, setPotreeNode] = useState(null);
  const [opacity, setOpacity] = useState(1);
  const previousParamsMapRef = useRef<any>(null);

  const loadOctreeLayer = async () => {
    const layer: ViewLayer = props.layer;
    const appearance3D: Appearance3D = props.appearance3D;
    const potreeViewer: any = props.potreeViewer;
    let newPotreeNode: any = {};

    let e: any = null;
    try {
      e = await Potree.loadPointCloud(layer.uri);
    } catch (e: any) {
      console.log('ERROR: ', e);
      return;
    }
    const pointcloud = e.pointcloud;
    const material = pointcloud.material;

    material.activeAttributeName = '';
    material.size = appearance3D.pointSize;
    material.pointSizeType = appearance3D.pointSizing;
    material.shape = appearance3D.pointShape;

    material.gradient = Potree.Gradients.RAINBOW;

    const visualization: PointcloudVisualization = extractVisConfig(layer, pointcloud);
    Object.keys(visualization.attributeValues).forEach((attrName) => {
      const attribute: PCAttributeVisualization = visualization.attributeValues[attrName];
      if (attrName === RGBA) {
        material.rgbGamma = attribute.gamma;
        material.rgbBrightness = attribute.brightness;
        material.rgbContrast = attribute.contrast;
      } else if (attrName === INTENSITY) {
        material.intensityRange = [attribute.min, attribute.max];
      } else if (attrName === ELEVATION) {
        material.heightMin = attribute.min;
        material.heightMax = attribute.max;
      } else if (attrName === CLASSIFICATION) {
        Object.keys(attribute.classificationSchemas).forEach((classSchemaId) => {
          updatePotreeClassification(
            attrName,
            classSchemaId,
            attribute.classificationSchemas[classSchemaId].name,
            attribute.classificationSchemas[classSchemaId].visible,
            attribute.classificationSchemas[classSchemaId].color,
            potreeViewer
          );
        });
      } else {
        if (visualization.attributeValues[attrName].attributeType === PCAttributeType.RANGE) {
          const rangeAttribute: PCAttributeVisualization = visualization.attributeValues[attrName];
          material.setRange(attrName, [rangeAttribute.min, rangeAttribute.max]);
        } else if (visualization.attributeValues[attrName].attributeType === PCAttributeType.CLASSIFICATION) {
          Object.keys(attribute.classificationSchemas).forEach((classSchemaId) => {
            updatePotreeClassification(
              attrName,
              classSchemaId,
              attribute.classificationSchemas[classSchemaId].name,
              attribute.classificationSchemas[classSchemaId].visible,
              attribute.classificationSchemas[classSchemaId].color,
              potreeViewer
            );
          });
        }
      }
    });

    pointcloud.visible = layer.active && props.show;
    newPotreeNode = pointcloud;
    setPotreeNode(newPotreeNode);

    potreeViewer.scene.addPointCloud(pointcloud);
    if (props.onReady) props.onReady();
  };

  //useEffect(() => {
  //  if (potreeNode) {
  //    setTimeout(() => {
  //      zoomTo();
  //    }, 500);
  //  }
  //}, [potreeNode]);

  const extractVisConfig = (layer: ViewLayer, potreeNode: any): PointcloudVisualization => {
    let visualization: PointcloudVisualization = layer.paramsMap['visualization'];
    if (!visualization) {
      visualization = {
        attribute: null,
        attributeValues: {},
      };
    }
    if (!visualization.attributeValues) {
      visualization.attributeValues = {};
    }
    if (!visualization.attributeValues.rgba) {
      visualization.attributeValues.rgba = {
        attributeType: PCAttributeType.COLORED,
        gamma: 2, // Range [0, 4]
        brightness: 0, // Range [-1, 1]
        contrast: 0, // Range [-1, 1]
      };
    }
    if (!potreeNode) {
      return visualization;
    }
    const pointcloudAttributes: PotreePointcloudAttribute[] = potreeNode.getAttributes().attributes;
    pointcloudAttributes.forEach((potreeAttr) => {
      if (!IGNORED_ATTRIBUTES.includes(potreeAttr.name)) {
        if (potreeAttr.distinctValues && potreeAttr.distinctValues.length > 0) {
          // Classification attribute
        } else {
          // Range attribute
          if (!visualization.attributeValues[potreeAttr.name]) {
            visualization.attributeValues[potreeAttr.name] = {
              attributeType: PCAttributeType.RANGE,
              min: potreeAttr.range[0],
              max: potreeAttr.range[1],
            };
          }
        }
      }
    });
    return visualization;
  };

  const updatePotreeClassification = (
    attributeName: string,
    id: number | string,
    name: string,
    visible: boolean,
    color: number[],
    potreeViewer: any
  ) => {
    const potreeAttributeName = attributeName === CLASSIFICATION ? 'DEFAULT' : attributeName;
    if (!potreeViewer.classifications[potreeAttributeName]) {
      potreeViewer.classifications[potreeAttributeName] = {};
    }
    if (!potreeViewer.classifications[potreeAttributeName][id]) {
      potreeViewer.classifications[potreeAttributeName][id] = {};
    }
    potreeViewer.classifications[potreeAttributeName][id].visible = visible;
    potreeViewer.classifications[potreeAttributeName][id].name = name;
    potreeViewer.classifications[potreeAttributeName][id].color = color;
  };

  useEffect(() => {
    if (!props.potreeViewer || potreeNode) return;

    loadOctreeLayer();

    return () => {
      //if (map) {
      //  map.removeLayer(newTileLayer);
      //}
    };
  }, [props.potreeViewer]);

  useEffect(() => {
    if (!potreeNode) return;

    // Detect changes in the visualization and update the potree node's material accordingly
    const currentParamsMap = props.layer.paramsMap;

    //if (previousParamsMapRef.current) {
    //  currentParamsMap = deepDiff(previousParamsMapRef.current, currentParamsMap);
    //  console.log('Diff: ' + JSON.stringify(currentParamsMap));
    //}
    //previousParamsMapRef.current = props.layer.paramsMap;
    const visualization: PointcloudVisualization = currentParamsMap['visualization'];
    if (visualization) assignVisualizationToPotreeNode(visualization, potreeNode);

    return () => {
      //if (map) {
      //  map.removeLayer(newTileLayer);
      //}
    };
  }, [potreeNode, props.layer]);

  const assignVisualizationToPotreeNode = (visualization: PointcloudVisualization, potreeNode: any) => {
    //console.log('Visual: ' + JSON.stringify(visualization));
    const material = potreeNode.material;
    if (material.activeAttributeName !== visualization.attribute) {
      material.activeAttributeName = visualization.attribute;
    }
    Object.keys(visualization.attributeValues).forEach((attrName) => {
      const attribute: PCAttributeVisualization = visualization.attributeValues[attrName];
      if (attrName === RGBA) {
        material.rgbGamma = attribute.gamma;
        material.rgbBrightness = attribute.brightness;
        material.rgbContrast = attribute.contrast;
      } else if (attrName === INTENSITY) {
        material.intensityRange = [attribute.currentMin, attribute.currentMax];
      } else if (attrName === ELEVATION) {
        material.heightMin = attribute.currentMin;
        material.heightMax = attribute.currentMax;
      } else if (attrName === CLASSIFICATION) {
        Object.keys(attribute.classificationSchemas).forEach((classSchemaId) => {
          updatePotreeClassification(
            attrName,
            classSchemaId,
            attribute.classificationSchemas[classSchemaId].name,
            attribute.classificationSchemas[classSchemaId].visible,
            attribute.classificationSchemas[classSchemaId].color,
            props.potreeViewer
          );
        });
      } else {
        if (visualization.attributeValues[attrName].attributeType === PCAttributeType.RANGE) {
          const rangeAttribute: PCAttributeVisualization = visualization.attributeValues[attrName];
          material.setRange(attrName, [rangeAttribute.min, rangeAttribute.max]);
        } else if (visualization.attributeValues[attrName].attributeType === PCAttributeType.CLASSIFICATION) {
          Object.keys(attribute.classificationSchemas).forEach((classSchemaId) => {
            updatePotreeClassification(
              attrName,
              classSchemaId,
              attribute.classificationSchemas[classSchemaId].name,
              attribute.classificationSchemas[classSchemaId].visible,
              attribute.classificationSchemas[classSchemaId].color,
              props.potreeViewer
            );
          });
        }
      }
    });
  };

  const zoomTo = () => {
    if (potreeNode) {
      props.potreeViewer.zoomTo(potreeNode, 1, 1000);
    }
  };

  //const handleMapClick = async (evt: MapBrowserEvent<any>): Promise<Layer2DClickResult> => {
  //  const data: any = tileLayer.getData(evt.pixel);
  //  if (data && data.length > 0) {
  //    // Consider No data value
  //    if (data[data.length - 1] !== 0) {
  //      return { layer: props.layer };
  //    }
  //  }
  //  return null;
  //};

  useImperativeHandle(ref, () => ({
    zoomTo,
    //handleMapClick,
  }));

  return null;
});

PointcloudObject.displayName = 'PointcloudObject';

export default PointcloudObject;
