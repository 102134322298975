import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { useAppContext } from '../../../../context/app';
import {
  DeliveryNavigationMode,
  InteractionMode,
  useConsumeDataDeliveryState,
} from '../../../../context/dataDeliveryContext';
import { ReactComponent as LinesLogo } from '../../../../assets/icons/toolbar-draw-lines.svg';
import { ReactComponent as ProfileLogo } from '../../../../assets/icons/toolbar-profile.svg';
import { ReactComponent as PolygonLogo } from '../../../../assets/icons/toolbar-polygon.svg';
import { ReactComponent as PointLogo } from '../../../../assets/icons/toolbar-point.svg';
import { ReactComponent as DistanceLogo } from '../../../../assets/icons/toolbar-distance.svg';
import { ReactComponent as HeightLogo } from '../../../../assets/icons/toolbar-height.svg';
import { useUser } from '../../../../hooks/authentication';
import { SvgIcon, Typography } from '@progress/kendo-react-common';
import { useParams } from 'react-router-dom';
import ViewLayerItem from './ViewLayerItem';
import { AuthorityLevel, AuthorityType, ViewLayer } from '../../../../types';
import { useRemoveLayer } from '../../../../hooks/viewerconfig';
import GeneralizedContextMenu from '../../../../components/GenericContextMenu';
import { chartLineStacked100Icon } from '@progress/kendo-svg-icons';

const NavigationMeasurementTools: React.FC = () => {
  const { userHasAuthority } = useUser();
  const { dispatch, openedFileNode, interactionMode, viewer3dOpened, measurementLayers, viewConfig } =
    useConsumeDataDeliveryState();
  const { rootId } = useParams();
  const [contextMenuViewLayer, setContextMenuViewLayer] = React.useState(null);
  const [contextOffset, setContextOffset] = React.useState(null);
  const rootDataDelivery = rootId === undefined;
  const useRemoveLayerMutation = useRemoveLayer();

  useEffect(() => {
    document.addEventListener('click', () => {
      setContextMenuViewLayer(null);
    });
  }, []);

  const renderMeasurementButton = (
    mode: InteractionMode,
    enabled: boolean,
    SvgComponentType: any,
    tooltipText: string
  ) => {
    const [hovering, setHovering] = useState(false);
    const active = interactionMode === mode;
    return (
      <li
        onClick={() => {
          if (active) {
            dispatch({ type: 'SET_INTERACTION_MODE', payload: InteractionMode.NAVIGATION });
          } else if (enabled) {
            dispatch({ type: 'SET_INTERACTION_MODE', payload: mode });
          }
        }}
        onMouseEnter={() => {
          if (enabled) setHovering(true);
        }}
        onMouseLeave={() => {
          if (enabled) setHovering(false);
        }}
        className="px-1 position-relative"
        style={{ cursor: enabled ? 'pointer' : 'auto' }}
      >
        <Tooltip anchorElement="target" position="bottom" style={{ maxWidth: '400px', whiteSpace: 'normal' }}>
          <SvgComponentType
            fill={active ? '#89C541' : hovering ? '#fff' : '#aaa'}
            stroke={active ? '#89C541' : hovering ? '#fff' : '#aaa'}
            style={{
              width: 'var(--geosap-viewer-toolbar-icon-width)',
              height: 'var(--geosap-viewer-toolbar-icon-width)',
            }}
          />
          <a style={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', zIndex: 1 }} title={tooltipText} />
        </Tooltip>
      </li>
    );
  };

  const handleLayerDelete = (layer: ViewLayer) => {
    useRemoveLayerMutation.mutateAsync({ layerId: layer.id, viewerId: viewConfig.id });
  };

  const getViewLayerOptions = (viewLayer: ViewLayer) => {
    return [
      {
        text: 'Delete',
        disabled: !userHasAuthority(AuthorityType.VIEWER_AUTHORITY, AuthorityLevel.DELETE),
        action: () => {
          handleLayerDelete(viewLayer);
        },
      },
    ];
  };

  const renderUnavailableOverlay = () => {
    if (!rootDataDelivery) return null;
    return (
      <div
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(0.5,0.5,0.5,0.5)',
          zIndex: 1,
        }}
      >
        <div
          style={{
            width: '90%',
            marginTop: '2rem',
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: '0.75rem',
            display: 'flex',
            flexDirection: 'column',
            border: '4px solid #FF0000',
            background: '#FFCBCB',
            color: 'black',
            alignItems: 'center',
          }}
        >
          <span style={{ fontWeight: 700, fontSize: '1.25rem' }}>Tools Are Disabled</span>
          <span style={{ fontWeight: 500, fontSize: '1rem' }}>Select a project to use these tools.</span>
        </div>
      </div>
    );
  };

  return (
    <div
      className=""
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        position: 'relative',
        padding: '1rem',
        color: 'white',
      }}
    >
      <GeneralizedContextMenu
        show={contextMenuViewLayer !== null && contextMenuViewLayer !== undefined}
        offset={contextOffset}
        items={getViewLayerOptions(contextMenuViewLayer)}
        onClose={() => setContextMenuViewLayer(null)}
      />
      {renderUnavailableOverlay()}
      {/* 2D Tools */}
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem', marginBottom: '1rem' }}>
        <div className="d-flex">
          <Typography.h4
            className="d-none d-lg-flex"
            style={{
              padding: '0px 0px',
              display: 'flex',
              margin: '0 0',
              flex: 1,
              justifyContent: 'flex-start',
            }}
          >
            2D Tools
          </Typography.h4>
        </div>
        <div className="d-flex">
          <ul className="flex-grow-1 d-flex">
            {renderMeasurementButton(
              InteractionMode.LINE_MEASUREMENT_2D,
              !viewer3dOpened && !rootDataDelivery,
              LinesLogo,
              'Measure the distance between two points.'
            )}
            {renderMeasurementButton(
              InteractionMode.POLYGON_MEASUREMENT_2D,
              !viewer3dOpened && !rootDataDelivery,
              PolygonLogo,
              'Area measurements between multiple points.'
            )}
          </ul>
        </div>
      </div>
      {/* 3D Tools */}
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem', marginBottom: '1rem' }}>
        <div className="d-flex">
          <Typography.h4
            className="d-none d-lg-flex"
            style={{
              padding: '0px 0px',
              display: 'flex',
              margin: '0 0',
              flex: 1,
              justifyContent: 'flex-start',
            }}
          >
            3D Tools
          </Typography.h4>
        </div>
        <div className="d-flex">
          <ul className="flex-grow-1 d-flex">
            {renderMeasurementButton(
              InteractionMode.POINT_MEASUREMENT_3D,
              viewer3dOpened && !rootDataDelivery,
              PointLogo,
              'Annotate specific point positions.'
            )}
            {renderMeasurementButton(
              InteractionMode.DISTANCE_MEASUREMENT_3D,
              viewer3dOpened && !rootDataDelivery,
              DistanceLogo,
              'Measure the distance between two points.'
            )}
            {renderMeasurementButton(
              InteractionMode.HEIGHT_MEASUREMENT_3D,
              viewer3dOpened && !rootDataDelivery,
              HeightLogo,
              'Measure the the height between two points.'
            )}
            {renderMeasurementButton(
              InteractionMode.VOLUME_MEASUREMENT_3D,
              viewer3dOpened && !rootDataDelivery,
              PolygonLogo,
              'Area measurements between multiple points.'
            )}
            {renderMeasurementButton(
              InteractionMode.PROFILE_MEASUREMENT_3D,
              viewer3dOpened && !rootDataDelivery,
              ProfileLogo,
              'Profiling tool. Select an origin and drag it to profile that slice of points.'
            )}
          </ul>
        </div>
      </div>
      {/* Layers */}
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem', marginBottom: '1rem' }}>
        <div className="d-flex">
          <Typography.h4
            className="d-none d-lg-flex"
            style={{
              padding: '0px 0px',
              display: 'flex',
              margin: '0 0',
              flex: 1,
              justifyContent: 'flex-start',
            }}
          >
            Layers
          </Typography.h4>
        </div>
        {measurementLayers.map((layer) => {
          return (
            <ViewLayerItem
              key={layer.id}
              viewLayer={layer}
              onMoreOptions={(e) => {
                e.stopPropagation();
                const rect = e.currentTarget.getBoundingClientRect();
                setContextOffset({ left: rect.left, top: rect.bottom });
                setContextMenuViewLayer(layer);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default NavigationMeasurementTools;
