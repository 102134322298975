import { useQueryClient, useMutation } from 'react-query';
import { useAxiosInstance } from '../common';
import { SapFlowGroup, TreeSapFlow, TreeSapGroup } from '../../types';

const useCreateSapGroupFromTemplate = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (treeNodes: (TreeSapGroup | TreeSapFlow)[]) => {
      const response = await axios.post<SapFlowGroup>('data_wrangling/sapgroups/generateFromTemplate', treeNodes);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['sapGroups'], { refetchInactive: true });
      },
    }
  );
};

export default useCreateSapGroupFromTemplate;
