import React, { useEffect } from 'react';
import Dialog from '../../../components/Dialog';
import { Typography } from '@progress/kendo-react-common';
import { UploadWizardType, useCreateFileNodeUploadWizardState } from '../../../context/fileNodeUploadWizard';
import FileNodeUploadWizard from './FileNodeUploadWizard';
import { FileNode } from '../../../types/DataDelivery';
import { TusUpload } from '../../../types';

interface FileNodeUploadModalProps {
  type: UploadWizardType;
  show: boolean;
  handleDone: (upload: TusUpload) => void;
  handleClose: () => void;
  rootFileNode?: FileNode;
  projectId?: string;
  sapflowId?: string;
  itemNodeId?: string;
}

const FileNodeUploadModal: React.FC<FileNodeUploadModalProps> = (props: FileNodeUploadModalProps) => {
  const wizardState = useCreateFileNodeUploadWizardState(
    props.type,
    props.rootFileNode,
    props.projectId,
    props.sapflowId,
    props.itemNodeId
  );
  const { dispatch } = wizardState;

  useEffect(
    () => {
      //dispatch({ type: 'CHANGED_VIEWER', payload: { viewerConfigId: props.viewerConfigId } });
    },
    [
      /*props.viewerConfigId*/
    ]
  );

  return (
    <Dialog
      show={props.show}
      title={<Typography.h3>Upload Data</Typography.h3>}
      onClose={() => {
        props.handleClose();
      }}
      style={{ width: '700px', maxWidth: '80%', maxHeight: '80%', height: '600px' }}
    >
      <FileNodeUploadWizard wizardState={wizardState} onDone={props.handleDone} onClose={props.handleClose} />
    </Dialog>
  );
};

export default FileNodeUploadModal;
