import React from 'react';
import { Popup, Offset } from '@progress/kendo-react-popup';
import { Menu, MenuItemModel } from '@progress/kendo-react-layout';

interface ContextMenuItem {
  text: string;
  action: () => void;
  disabled?: boolean;
}

interface GeneralizedContextMenuProps {
  show: boolean;
  offset: Offset | null;
  items: ContextMenuItem[];
  onClose?: () => void;
  popupClassName?: string;
}

const GeneralizedContextMenu: React.FC<GeneralizedContextMenuProps> = ({
  show,
  offset,
  items,
  onClose,
  popupClassName = 'popup-content',
}) => {
  const menuItems: MenuItemModel[] = items.map(({ text, action, disabled }) => ({
    text,
    data: { action },
    disabled: !!disabled,
  }));

  return (
    <Popup show={show} offset={offset} popupClass={popupClassName}>
      <Menu
        vertical
        items={menuItems}
        onSelect={({ item, syntheticEvent }) => {
          item.data.action();
          syntheticEvent.stopPropagation();
          if (onClose) {
            onClose();
          }
        }}
      />
    </Popup>
  );
};

export default GeneralizedContextMenu;
