import React from 'react';

const TableComponent: React.FC = () => {
  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      <div style={{ flex: 4, height: '100%', background: '#e0e0e0' }}>
        <img src="/assets/config_schema.png" style={{ width: '100%' }}></img>
      </div>
      <div style={{ flex: 1, height: '100%', background: 'white' }}>
        <img src="/assets/config_params.png" style={{ width: '100%' }}></img>
      </div>
    </div>
  );
};

export default TableComponent;
