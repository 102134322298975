import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridColumn, GridDetailRowProps, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { Typography } from '@progress/kendo-react-common';
import { DashboardTransaction, ESapFlowStatus, Page, SapFlowStatus } from '../../types';
import { StyledGrid } from '../../components/styled';
import { toSapFlow } from '../../converters/dashboardTransaction';
import TransactionStatusCell from './TransactionStatusCell';
import TransactionActionsCell from './TransactionActionsCell';
import { SortDescriptor } from '@progress/kendo-data-query';

const ErrorsLogViewer: React.FC<GridDetailRowProps> = (props: GridDetailRowProps) => {
  const divRef = useRef<HTMLDivElement>();
  useEffect(() => {
    if (divRef.current?.parentElement?.tagName === 'TD') {
      const tdEl = divRef.current.parentElement as HTMLTableCellElement;
      tdEl.colSpan = tdEl.colSpan + 1;
      tdEl.ariaColIndex = '1';
    }
  }, []);

  return (
    <div ref={divRef}>
      <div className="px-4 py-2 mb-2 border-bottom">
        <Typography.p fontWeight="bold" fontSize="small" className="m-0">
          Latest error logs
        </Typography.p>
      </div>
      <div className="px-4 pb-1">
        {props.dataItem && props.dataItem.statusHistory && props.dataItem.statusHistory.length > 0 && (
          <ul>
            {props.dataItem.statusHistory /*.slice(0, 5)*/
              .map((statusLog: SapFlowStatus) => (
                <li key={`${statusLog.id}`} className="py-1">
                  <span className="ps-1">{statusLog.statusTime}</span>
                  <span className="ps-1">-</span>
                  <span className="ps-1">{statusLog.statusMessage}</span>
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
};

interface Props {
  data: Page<DashboardTransaction>;
  sort: SortDescriptor[];
  page: number;
  pageSize?: number;
  onExpand: (dataItem: DashboardTransaction) => void;
  onStart: (transid: string) => void;
  onDelete: (transid: string) => void;
  onDownload: (transid: string) => void;
  onPageChange: (e: GridPageChangeEvent) => void;
  onSortChange: (e: GridSortChangeEvent) => void;
  deletingRow: string;
}

const TransactionGrid: React.FC<Props> = ({
  data,
  sort,
  page,
  pageSize = 10,
  onExpand,
  onStart,
  onDelete,
  onDownload,
  deletingRow,
  onPageChange,
  onSortChange,
}) => {
  const navigate = useNavigate();

  const ActionsCellWithErrorHandler: React.FC<{ dataItem: DashboardTransaction }> = ({ dataItem }) => (
    <TransactionActionsCell
      dataItem={dataItem}
      onErrorClick={onExpand}
      onStartClick={onStart}
      onDeleteClick={onDelete}
      onDownloadClick={onDownload}
    />
  );
  ////////////////////////////////////////////////////
  const MemoizedActionCellWithErrorHandler = React.useMemo(
    () => ActionsCellWithErrorHandler,
    [onExpand] // add memorized deps from the parent if neeed.
  );

  const StatusCellWithErrorHandler: React.FC<{ dataItem: DashboardTransaction }> = ({ dataItem }) => (
    <TransactionStatusCell dataItem={dataItem} />
  );

  return (
    <StyledGrid
      pageable
      data={data.content}
      skip={page * pageSize}
      take={pageSize}
      total={data.totalElements}
      sort={sort}
      onPageChange={onPageChange}
      onSortChange={onSortChange}
      sortable
      detail={ErrorsLogViewer}
      expandField="expanded"
      onRowClick={({ dataItem }) => {
        if (dataItem.status === ESapFlowStatus.TRANSACTION_CREATED) {
          // Redirect to sapflow wizard step 5
          navigate(`/sapflow`, { state: { sapflow: toSapFlow(dataItem) } });
        } else {
          navigate(`/details/${dataItem.id}`);
        }
      }}
      rowRender={(trElement, props) => {
        let trProps: any = null;
        if (deletingRow && props.dataItem && deletingRow === props.dataItem.id) {
          trProps = {
            style: {
              pointerEvents: 'none',
              opacity: '0.5',
              background: '#CCC',
            },
          };
        } else {
          trProps = {
            style: { cursor: 'pointer' },
          };
        }
        return React.cloneElement(
          trElement,
          {
            ...trProps,
          },
          trElement.props.children as any
        );
      }}
    >
      <GridColumn
        field="name"
        title="SAP Flow"
        sortable={false}
        cell={({ dataItem }: any) => <td className="fw-bold">{dataItem.name}</td>}
      />
      {/* 
        todo: we need to understand what data we want to bring in this column
        <GridColumn field="type" title="SAP Type" /> 
      */}
      <GridColumn sortable={false} field="projectName" title="Project Name" />
      <GridColumn field="createdDate" title="Date" format="{0:h:mm a - M/d/yy}" />
      <GridColumn sortable={false} cell={StatusCellWithErrorHandler} title="Processing Status" />
      {/*<GridColumn field="status" title="Processing Status" />*/}
      <GridColumn sortable={false} cell={MemoizedActionCellWithErrorHandler} title="Actions" />
    </StyledGrid>
  );
};

export default TransactionGrid;
