import { useState, useCallback, useEffect, useRef } from 'react';
import { Upload, UrlStorage } from 'tus-js-client'; // Adjust the path as needed
import { extractTusUploadType, TusFileNodeUpload, TusSapFlowUpload, TusUpload, TusUploadStatus, UploadType } from '../../types';
import { useUser } from '../authentication';
import { useAppContext } from '../../context/app';
import { getUploads } from '../../common/idbHelper';
import { useUploadContext } from '../../context/uploadContext';
import { FileNode } from '../../types/DataDelivery';
import { useQueryClient } from 'react-query';
import { useFileNodeActions } from '../../actions';

interface UploadManager {
  startFileNodeUpload: (file: File, parentFileNode: FileNode) => TusUpload;
  startSapFlowUpload: (file: File, projectId: string, sapflowId: string, itemNodeId: string) => TusUpload;
  resumeUpload: (fileId: string) => void;
  retryUpload: (fileId: string) => void;
  pauseUpload: (fileId: string) => void;
  cancelUpload: (fileId: string) => void;
  removeUpload: (fileId: string) => void;
}

const useTusUploader = (): UploadManager => {
  const {getAccessToken, getTokenType, getCurrentOrganization} = useUser();
  const { dispatch: uploadDispatch, uploads, uploadsRetrieved } = useUploadContext();
  const {handleUpdateFileNode} = useFileNodeActions();
  const queryClient = useQueryClient();

  const handleUploadCompleted = (tusUploadInfos: TusUpload) => {
    uploadDispatch({type: 'UPDATE_UPLOAD_STATE', payload: {fileId: tusUploadInfos.fileId, status: TusUploadStatus.COMPLETED, progress: 100}});
    
    const uploadType = extractTusUploadType(tusUploadInfos);
    if(uploadType === UploadType.FILENODE) {
      queryClient.invalidateQueries({queryKey: ['fileNodeStructure', (tusUploadInfos as TusFileNodeUpload).parent.rootId], refetchInactive: true});
      // Check if there are other uploads with the same parent ongoing
      const otherUploads = uploads.filter((upload) => (upload as TusFileNodeUpload).parent.id === (tusUploadInfos as TusFileNodeUpload).parent.id && upload.status === TusUploadStatus.UPLOADING);
      // If there are no other uploads with the same parent ongoing, start an upadte of the parent node
      if(otherUploads.length === 0) {
        //handleUpdateFileNode(tusUploadInfos.parent);
      }
    }
    else if(uploadType === UploadType.SAPFLOW) {
      //
    }
  }

  const buildTusUpload = /*useCallback(*/(tusUploadInfos: TusUpload): Upload => {
    const metadata: any = {
      filename: tusUploadInfos.file.name,
      filetype: tusUploadInfos.file.type,
      uploadType: tusUploadInfos.uploadType
    };
    const uploadType = extractTusUploadType(tusUploadInfos);
    if(uploadType === UploadType.FILENODE) {
      metadata.parentId = (tusUploadInfos as TusFileNodeUpload).parent.id
    }
    else if(uploadType === UploadType.SAPFLOW) {
      metadata.projectId = (tusUploadInfos as TusSapFlowUpload).projectId;
      metadata.sapflowId = (tusUploadInfos as TusSapFlowUpload).sapflowId;
      metadata.itemNodeId = (tusUploadInfos as TusSapFlowUpload).itemNodeId;
    }
    const upload = new Upload(tusUploadInfos.file, {
        endpoint: tusUploadInfos.url,
        headers: {
          "Authorization": `${getTokenType()} ${getAccessToken()}`,
          "Organization": `${getCurrentOrganization().id}`
        },
        metadata,
        removeFingerprintOnSuccess: true,
        onError: (error) => {
          uploadDispatch({type: 'UPDATE_UPLOAD_STATE', payload: {fileId: tusUploadInfos.fileId, status: TusUploadStatus.ERRORED, errorMessage: "Upload failed. Please try again later."}});
        },
        onProgress: (bytesUploaded, bytesTotal) => {
          const progress = (bytesUploaded / bytesTotal) * 100;
          const status = progress === 100 ? TusUploadStatus.PROCESSING : TusUploadStatus.UPLOADING;
          uploadDispatch({type: 'UPDATE_UPLOAD_STATE', payload: {fileId: tusUploadInfos.fileId, status, progress, uploaded: bytesUploaded}});
        },
        onSuccess: () => {
          handleUploadCompleted(tusUploadInfos);
        },
      });
      tusUploadInfos.upload = upload;
      return upload;
    }//, [uploads]);

  const loadUploadsFromDB = async () => {
    const storedUploads = await getUploads();
    // if a stored upload is already in the state, keep the state version
    uploadDispatch({type: 'UPDATE_UPLOADS', payload: {uploads: storedUploads.map((storedUpload) => uploads.find((upload) => upload.fileId === storedUpload.fileId) || storedUpload)}});
    const newUploads = storedUploads.filter((storedUpload) => !uploads.some((upload) => upload.fileId === storedUpload.fileId));
    newUploads.forEach((tusUploadInfos) => {
      if (tusUploadInfos.status === TusUploadStatus.UPLOADING || tusUploadInfos.status === TusUploadStatus.PROCESSING) {
        const upload = buildTusUpload(tusUploadInfos);
        uploadDispatch({type: 'UPDATE_UPLOAD_STATE', payload: {fileId: tusUploadInfos.fileId, status: TusUploadStatus.INTERRUPTED, upload}});
      }
    });
  }

  useEffect(() => {
    if(!uploadsRetrieved)
      loadUploadsFromDB();
  }, [uploadsRetrieved]);

  const startOrResumeUpload = (upload: Upload, fromStart?: boolean) => {
    // Check if there are any previous uploads to continue.
    upload.findPreviousUploads().then(function (previousUploads) {
        // Found previous uploads so we select the first one.
        if (!fromStart && previousUploads.length) {
            upload.resumeFromPreviousUpload(previousUploads[0])
        }

        // Start the upload
        upload.start()
    })
}

const abortAllPreviousUploads = (upload: Upload) => {
  // Check if there are any previous uploads to continue.
  upload.findPreviousUploads().then(function (previousUploads) {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        previousUploads.forEach((previousUpload) => {
          upload.resumeFromPreviousUpload(previousUploads[0])
          upload.abort(true)
        });
      }
  })
}

const startFileNodeUpload = useCallback((file: File, parentFileNode: FileNode) => {
  const fileId = `${file.name}-${file.size}-${file.lastModified}`;
  const uploadUrl = process.env.REACT_APP_GEOSAP_SERVERURL + '/tus'; // Replace with your Tus server URL
  let currentParentNode = parentFileNode;
  let path = parentFileNode.name
  while(currentParentNode.parent && currentParentNode.parent.path!==getCurrentOrganization().id) {
    currentParentNode = currentParentNode.parent;
    path = currentParentNode.name + '/' + path;
  }

  const tusUploadInfos: TusFileNodeUpload = {
    uploadType: UploadType.FILENODE,
    fileId,
    file,
    url: uploadUrl,
    progress: 0,
    status: TusUploadStatus.UPLOADING,
    totalSize: file.size,
    uploaded: 0,
    path: path,
    parent: parentFileNode
  };

  const upload = buildTusUpload(tusUploadInfos);
  startOrResumeUpload(upload);

  uploadDispatch({type: 'ADD_UPLOAD', payload: {upload: tusUploadInfos}});

  return tusUploadInfos;
}, []);

const startSapFlowUpload = useCallback((file: File, projectId: string, sapflowId: string, itemNodeId: string) => {
  const fileId = `${file.name}-${file.size}-${file.lastModified}`;
  const uploadUrl = process.env.REACT_APP_GEOSAP_SERVERURL + '/tus'; // Replace with your Tus server URL

  const tusUploadInfos: TusSapFlowUpload = {
    uploadType: UploadType.SAPFLOW,
    fileId,
    file,
    url: uploadUrl,
    progress: 0,
    status: TusUploadStatus.UPLOADING,
    totalSize: file.size,
    uploaded: 0,
    projectId: projectId,
    sapflowId: sapflowId,
    itemNodeId: itemNodeId
  };

  const upload = buildTusUpload(tusUploadInfos);
  startOrResumeUpload(upload);

  uploadDispatch({type: 'ADD_UPLOAD', payload: {upload: tusUploadInfos}});

  return tusUploadInfos;
}, []);

  const pauseUpload = /*useCallback(*/(fileId: string) => {
    const currentUpload = uploads.find((u) => u.fileId === fileId);
    if (currentUpload) {
      if(currentUpload.upload) {
        currentUpload.upload.abort();
      }
      uploadDispatch({type: 'UPDATE_UPLOADS', payload: {uploads: 
        uploads.map((upload) =>
          upload.fileId === fileId ? { ...upload, status: TusUploadStatus.PAUSED } : upload
        )}}
      );
    }
  };//, [uploads, uploadRefs, uploadRefs.current]);

  const resumeUpload = useCallback((fileId: string) => {
    const currentUpload = uploads.find((u) => u.fileId === fileId);
    if(currentUpload.upload) {
      startOrResumeUpload(currentUpload.upload);
      uploadDispatch({type: 'UPDATE_UPLOAD_STATE', payload: {fileId: currentUpload.fileId, status: TusUploadStatus.UPLOADING, progress: currentUpload.progress}});
    }
    else {
      uploadDispatch({type: 'UPDATE_UPLOAD_STATE', payload: {fileId: currentUpload.fileId, status: TusUploadStatus.ERRORED, errorMessage: "Couldn't restart upload."}});
    }
  }, [uploads]);

  const retryUpload = useCallback((fileId: string) => {
    const currentUpload = uploads.find((u) => u.fileId === fileId);
    if(!currentUpload.upload) {
      const upload = buildTusUpload(currentUpload);
      currentUpload.upload = upload;
    }
    uploadDispatch({type: 'UPDATE_UPLOAD_STATE', payload: {fileId: currentUpload.fileId, status: TusUploadStatus.UPLOADING, progress: currentUpload.progress, upload: currentUpload.upload}});
    startOrResumeUpload(currentUpload.upload, true);
  }, [uploads]);

  const cancelUpload = useCallback((fileId: string) => {
    const currentUpload = uploads.find((u) => u.fileId === fileId);
    if(currentUpload) {
      if (currentUpload.upload) {
        currentUpload.upload.abort();
      }
      uploadDispatch({type: 'UPDATE_UPLOAD_STATE', payload: {fileId: currentUpload.fileId, status: TusUploadStatus.CANCELED, progress: currentUpload.progress}});
    }
  }, [uploads]);

  const removeUpload = useCallback((fileId: string) => {
    const currentUpload = uploads.find((u) => u.fileId === fileId);
    if (currentUpload) {
      if(!currentUpload.upload) {
        const upload = buildTusUpload(currentUpload);
        currentUpload.upload = upload;
      }
      abortAllPreviousUploads(currentUpload.upload);
      currentUpload.upload.abort(true);
      uploadDispatch({type: 'REMOVE_UPLOAD', payload: {fileId}});
    }
  }, [uploads]);

  return {
    startFileNodeUpload,
    startSapFlowUpload,
    pauseUpload,
    resumeUpload,
    retryUpload,
    cancelUpload,
    removeUpload
  };
};

export default useTusUploader;
