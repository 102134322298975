import { FileNode } from '../../types/DataDelivery';

export enum UploadWizardType {
  FILENODE = 0,
  SAPFLOW = 1
}

export enum FileNodeUploadWizardStep {
  SELECT_FILES = 0,
  SELECT_DESTINATION = 1,
  TRACK_PROGRESS = 2,
  UNSUPPORTED = -1,
}

export interface FileNodeUploadWizardState {
  type: UploadWizardType;
  step: FileNodeUploadWizardStep;
  rootFileNode: FileNode;
  files: File[];
  parentFileNode: FileNode | null;
  done: boolean;
  projectId: string;
  sapflowId: string;
  itemNodeId: string;
}

export const initialState: FileNodeUploadWizardState = {
  type: UploadWizardType.FILENODE,
  step: FileNodeUploadWizardStep.SELECT_FILES,
  rootFileNode: null,
  files: [],
  parentFileNode: null,
  done: false,
  projectId: null,
  sapflowId: null,
  itemNodeId: null
};
