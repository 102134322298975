import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { SvgIcon } from '@progress/kendo-react-common';
import { gearIcon, chevronLeftIcon, fileAddIcon, folderAddIcon, plusIcon } from '@progress/kendo-svg-icons';
import { FileNode, FileNodeStatus, DOWNLOAD_SIZE_WARNING } from '../../../types/DataDelivery';
import { ShareResourceButton, SubmitButton } from '../../../components/form';
import Tooltipped from '../../../components/TooltippedV2';
import DownloadButton from '../../Dashboard/DownloadButton';
import { useUser } from '../../../hooks/authentication';
import { AuthorityLevel, AuthorityType, UserRole } from '../../../types';
import NavigationOptions from './NavigationOptionsDialog';
import DataDeliveryNavigationOptions from '../../../types/DataDelivery/FileNodeNavigationOptions';
import { useFileNodeNavigationOptionsUpdate, useFileNodeUpdate } from '../../../hooks/data_delivery';
import { OverlayedLoader, WrappedOrEllipsisSpan } from '../../../components';
import CreateRootFileNodeModal from '../../../components/filenodes/CreateFolderFileNodeModal';
import { useAppContext } from '../../../context/app';
import { useConsumeDataDeliveryState } from '../../../context/dataDeliveryContext';
import { useFileNodeActions } from '../../../actions';
import FileUploaderModal from '../../../components/filenodes/FileUploader';
import DownloadWarningModal from '../../../components/filenodes/DownloadWarningModal';
import GeneralizedContextMenu from '../../../components/GenericContextMenu';
import { findFileNodeRecursively } from '../../../common/fileNodeHelper';

const NavigationHeader: React.FC = () => {
  const { screenWidth, dispatch: appDispatch } = useAppContext();
  const { dispatch, rootFileNode, openedFileNode, hoveredFileNode, navigationOptions, searchText, viewer3dOpened } =
    useConsumeDataDeliveryState();
  const { handleCreateFolderFileNode, handleProcessFileNode, handleDownloadFileNode, handleUploadFileNode } =
    useFileNodeActions();
  const [showUploadContextMenu, setShowUploadContextMenu] = React.useState(false);
  const [uploadContextOffset, setUploadContextOffset] = React.useState(null);
  const [creatingFolderFileNode, setCreatingFolderFileNode] = useState(false);
  const [showOptions, setShowOptions] = React.useState(false);
  const [confirmingDownloadFileNode, setConfirmingDownloadFileNode] = useState<FileNode>(null);
  const [uploadingFileNode, setUploadingFileNode] = useState(false);
  const updateFileNodeNavigationOptionsMutation = useFileNodeNavigationOptionsUpdate();
  const { rootId } = useParams();
  const rootDataDelivery = rootId === undefined;
  const { userHasAuthority, userHasRole, getViewAccessToken } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener('click', () => {
      setShowUploadContextMenu(false);
    });
  }, []);

  const createFolderFileNode = (fileNodeName: string) => {
    handleCreateFolderFileNode(fileNodeName, openedFileNode).then((createFileNode: FileNode) => {
      handleProcessFileNode(createFileNode);
    });
  };

  const handleUpdateNavigationOptions = (options: DataDeliveryNavigationOptions) => {
    dispatch({ type: 'SET_NAVIGATION_OPTIONS', payload: options });
    updateFileNodeNavigationOptionsMutation.mutateAsync({
      showSupport: options.showSupport,
      baseMapId: options.baseMap?.id,
      rootId: rootFileNode?.id,
    });
  };

  const handleBack = () => {
    if (openedFileNode) {
      if(openedFileNode.parent){
        const parent = findFileNodeRecursively(rootFileNode, openedFileNode.parent.id);
        if(parent) {
          dispatch({ type: 'ZOOM_TO_FILENODE', payload: parent });
        }
      }
      if(viewer3dOpened) {
        dispatch({type: "SET_VIEWER_3D_OPENED", payload: false});
      }
      dispatch({ type: 'SET_OPENED_FILE_NODE', payload: null });
    } else {
      navigate('/delivery');
    }
  };

  const currentFileNodeName = rootFileNode?.name;

  const uploadOptions = [
    {
      text: 'Add file',
      disabled: false,
      action: () => {
        setUploadingFileNode(true);
      },
    },
    {
      text: 'Create new folder',
      disabled: false,
      action: () => {
        setCreatingFolderFileNode(true);
      },
    },
  ];

  return (
    <div className="d-flex" style={{ color: 'white', background: 'var(--gs-lightgray-bg)', padding: '1rem 2rem' }}>
      <DownloadWarningModal
        filenode={confirmingDownloadFileNode}
        show={confirmingDownloadFileNode !== null}
        handleClose={() => {
          setConfirmingDownloadFileNode(null);
        }}
        onConfirm={() => {
          handleDownloadFileNode(confirmingDownloadFileNode);
          setConfirmingDownloadFileNode(null);
        }}
      />
      <GeneralizedContextMenu
        show={showUploadContextMenu}
        offset={uploadContextOffset}
        items={uploadOptions}
        onClose={() => setShowUploadContextMenu(false)}
      />
      {userHasAuthority(AuthorityType.DATA_AUTHORITY, AuthorityLevel.UPDATE) && (
        <CreateRootFileNodeModal
          show={creatingFolderFileNode}
          handleClose={() => {
            setCreatingFolderFileNode(false);
          }}
          defaultValue="New Project"
          onConfirm={(name: string) => {
            setCreatingFolderFileNode(false);
            createFolderFileNode(name);
          }}
          parent={openedFileNode}
        />
      )}

      {userHasRole(UserRole.ROLE_SYSTEMADMIN) && (
        <NavigationOptions
          show={showOptions}
          handleClose={() => {
            setShowOptions(false);
          }}
          currentNavigationOptions={navigationOptions}
          onNavigationOptionsChange={handleUpdateNavigationOptions}
        ></NavigationOptions>
      )}

      {uploadingFileNode && (
        <FileUploaderModal
          show={uploadingFileNode}
          handleClose={() => {
            setUploadingFileNode(false);
          }}
          parentFileNode={openedFileNode}
          onConfirm={(files: File[]) => {
            setUploadingFileNode(false);
            files.forEach((file: File) => {
              handleUploadFileNode(file, openedFileNode);
            });
          }}
        />
      )}
      <div className="d-flex items-center" style={{ alignItems: 'center' }}>
        {!rootDataDelivery && (
          <SvgIcon
            icon={chevronLeftIcon}
            size="large"
            style={{
              marginRight: '12px',
              cursor: 'pointer',
              fontSize: '28px',
              marginLeft: '-8px',
            }}
            onClick={() => {
              handleBack();
            }}
          />
        )}
      </div>
      <Typography.h5
        className="d-none d-lg-flex"
        style={{
          padding: '0px 0px',
          display: 'flex',
          margin: '0 0',
          flex: 1,
          justifyContent: rootFileNode?.status === FileNodeStatus.CREATED ? 'space-between' : 'flex-start',
        }}
      >
        <div style={{ display: 'flex', width: '100%' }}>
          <div
            style={{ position: 'relative', flex: '1 1 0%', display: 'flex', overflow: 'hidden', marginRight: 'auto' }}
          >
            <WrappedOrEllipsisSpan wrapped={true}>{currentFileNodeName}</WrappedOrEllipsisSpan>

            <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
              <Tooltip anchorElement="target" position="top" style={{ maxWidth: '400px', whiteSpace: 'normal' }}>
                <a
                  style={{ width: '100%', height: '100%', position: 'absolute', zIndex: 1 }}
                  title={currentFileNodeName}
                ></a>
              </Tooltip>
            </div>
          </div>
          <div style={{ marginLeft: 'auto', display: 'flex' }}>
            {userHasRole(UserRole.ROLE_SYSTEMADMIN) && (
              <Tooltipped
                containerStyle={{
                  marginLeft: '0.5rem',
                  cursor: 'pointer',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                label={'Add data'}
                onClick={(e) => {
                  e.stopPropagation();
                  const rect = e.currentTarget.getBoundingClientRect();
                  setShowUploadContextMenu(true);
                  setUploadContextOffset({ left: rect.left, top: rect.bottom });
                  return true;
                }}
              >
                <SvgIcon icon={plusIcon} size="large" style={{ fontSize: '1.2rem', position: 'relative' }}></SvgIcon>
              </Tooltipped>
            )}
            {!rootDataDelivery && (
              <DownloadButton
                style={{ marginLeft: '0.5rem' }}
                iconStyle={{ color: 'rgb(190,190,190)' }}
                iconHoveredStyle={{ color: 'rgb(255,255,255)' }}
                downloading={false}
                onDownload={() => {
                  /*if (!getOrgSubscriptionValid() && !isViewAuthenticated()) {
                  appDispatch({ type: 'CHANGE_SHOW_UNSBSCRIBED_DIALOG', payload: { show: true } });
                  return;
                }*/
                  if (rootFileNode.size && rootFileNode.size > DOWNLOAD_SIZE_WARNING) {
                    setConfirmingDownloadFileNode(rootFileNode);
                  } else {
                    handleDownloadFileNode(rootFileNode);
                  }
                }}
                disabled={false}
                message={'Download'}
              />
            )}
            {!rootDataDelivery && userHasAuthority(AuthorityType.DATA_AUTHORITY, AuthorityLevel.SHARE) && (
              <ShareResourceButton
                resourceName={rootFileNode?.name}
                fileNodeId={rootFileNode?.id}
                disabled={false}
                style={{ marginLeft: '0.5rem' }}
                iconStyle={{ color: 'rgb(190,190,190)' }}
                iconHoveredStyle={{ color: 'rgb(255,255,255)' }}
              ></ShareResourceButton>
            )}
            {!rootDataDelivery && userHasRole(UserRole.ROLE_SYSTEMADMIN) && (
              <Tooltipped
                containerStyle={{
                  marginLeft: '0.5rem',
                  cursor: 'pointer',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                label={'Display navigation options.'}
                onClick={() => {
                  /*if (!getOrgSubscriptionValid()) {
                  appDispatch({ type: 'CHANGE_SHOW_UNSBSCRIBED_DIALOG', payload: { show: true } });
                  return true;
                }*/
                  setShowOptions(true);
                  return true;
                }}
              >
                <SvgIcon icon={gearIcon} size="medium" style={{ fontSize: '1.2rem', position: 'relative' }}></SvgIcon>
              </Tooltipped>
            )}
          </div>
        </div>
      </Typography.h5>
    </div>
  );
};

export default NavigationHeader;
