import React, { useState, useEffect, useRef } from 'react';
import { Popup } from '@progress/kendo-react-popup';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
import { Checkbox } from '@progress/kendo-react-inputs';
import { clipboardTextIcon } from '@progress/kendo-svg-icons';
import {
  useRunProcessNode,
  useRunSapFlow,
  useResumeSapFlow,
  useSetSapFlowProcessNodeStatus,
  useSetSapFlowStatus,
  useRunSapGroup,
  useResumeSapGroup,
} from '../../hooks/data_delivery';
import { RestrictedSubmitButton } from '../../components/restricted';
import {
  AuthorityLevel,
  AuthorityType,
  ESapFlowProcessStatus,
  ESapFlowStatus,
  SapFlow,
  TreeSapFlow,
  TreeSapGroup,
  TreeSapProcessNode,
} from '../../types';
import { SubmitButton } from '../../components/form';
import { useConsumeDataProcessingContextState } from '../../context/dataProcessingContext';
import { extractTreeBranchFromItemPath, treeDataContainsTreeBranch } from '../../common/dataProcessingHelper';

interface TreeViewProps {
  item: TreeSapGroup | TreeSapFlow | TreeSapProcessNode;
  itemHierarchicalIndex: string;
  parent: TreeSapGroup | TreeSapFlow | TreeSapProcessNode;
  onGenerateSapGroup: (item: TreeSapGroup, itemHierarchicalIndex: string) => void;
  onCleanSapGroup: (item: TreeSapGroup, itemHierarchicalIndex: string) => void;
  onConfigure: () => void;
  onHistory: () => void;
}

const FileExplorerItem: React.FC<TreeViewProps> = (props: TreeViewProps) => {
  const { creatingGroup, selectedSapItems, dispatch, treeData, selectedTreeData } =
    useConsumeDataProcessingContextState();
  const setSapFlowStatusMutation = useSetSapFlowStatus();
  const setSapFlowProcessNodeStatusMutation = useSetSapFlowProcessNodeStatus();
  const runSapGroupMutation = useRunSapGroup();
  const resumeSapGroupMutation = useResumeSapGroup();
  const runSapFlowMutation = useRunSapFlow();
  const resumeSapFlowMutation = useResumeSapFlow();
  const runProcessNodeMutation = useRunProcessNode();
  const [hoveringStatus, setHoveringStatus] = useState<boolean>(false);
  const [statusMenuOffset, setStatusMenuOffset] = useState<{ left: number; top: number }>({ left: 0, top: 0 });
  const statusButtonRef = useRef(null);

  useEffect(() => {
    if (statusButtonRef.current) {
      const element: HTMLElement = statusButtonRef.current;
      const rect = element.getBoundingClientRect();
      setStatusMenuOffset({
        left: rect.left,
        top: rect.bottom,
      });
    }
  }, [statusButtonRef.current]);

  const generateSapGroups = () => {
    props.onGenerateSapGroup(props.item as TreeSapGroup, props.itemHierarchicalIndex);
  };

  const cleanSapGroups = () => {
    props.onCleanSapGroup(props.item as TreeSapGroup, props.itemHierarchicalIndex);
  };

  const runSapGroup = () => {
    runSapGroupMutation.mutateAsync(props.item.id);
  };

  const resumeSapGroup = () => {
    resumeSapGroupMutation.mutateAsync(props.item.id);
  };

  const runSapFlow = () => {
    runSapFlowMutation.mutateAsync(props.item.id);
  };

  const resumeSapFlow = () => {
    resumeSapFlowMutation.mutateAsync(props.item.id);
  };

  const runProcessNode = () => {
    runProcessNodeMutation.mutateAsync({ sapFlowId: props.parent.id, processNodeId: props.item.id });
  };

  const renderSapGroupActions = () => {
    if (props.item.type !== 'TreeSapGroup') return;
    const currentItem: TreeSapGroup = props.item;
    if (currentItem.groupType === 'SAPCONFIG') {
      return (
        <div className="d-flex gap-3">
          <RestrictedSubmitButton
            label="Configure"
            fillMode="outline"
            themeColor="info"
            size="small"
            height="fit-content"
            full={false}
            onClick={() => {
              props.onConfigure();
            }}
            authorityType={AuthorityType.DATA_AUTHORITY}
            authorityLevel={AuthorityLevel.EXECUTE}
            xMargin="mx-1"
          ></RestrictedSubmitButton>
          <RestrictedSubmitButton
            label="Generate"
            fillMode="outline"
            themeColor="info"
            size="small"
            height="fit-content"
            full={false}
            onClick={generateSapGroups}
            disabled={currentItem.generating || currentItem.cleaning}
            authorityType={AuthorityType.DATA_AUTHORITY}
            authorityLevel={AuthorityLevel.EXECUTE}
            loading={currentItem.generating}
            xMargin="mx-1"
          ></RestrictedSubmitButton>
          {currentItem.items && currentItem.items.length > 0 && (
            <RestrictedSubmitButton
              label="Clean"
              fillMode="outline"
              themeColor="error"
              size="small"
              height="fit-content"
              full={false}
              onClick={cleanSapGroups}
              disabled={currentItem.generating || currentItem.cleaning}
              authorityType={AuthorityType.DATA_AUTHORITY}
              authorityLevel={AuthorityLevel.EXECUTE}
              loading={currentItem.cleaning}
              xMargin="mx-1"
            ></RestrictedSubmitButton>
          )}
          {props.item.status === ESapFlowStatus.TRANSACTION_INTERVENTION_REQUIRED && (
            <RestrictedSubmitButton
              label="Resume"
              fillMode="outline"
              themeColor="primary"
              size="small"
              full={false}
              onClick={resumeSapGroup}
              authorityType={AuthorityType.DATA_AUTHORITY}
              authorityLevel={AuthorityLevel.UPDATE}
            ></RestrictedSubmitButton>
          )}
          {props.item.status !== ESapFlowStatus.TRANSACTION_IN_PROCESS &&
            props.item.status !== ESapFlowStatus.TRANSACTION_INTERVENTION_REQUIRED && (
              <RestrictedSubmitButton
                label="Run"
                fillMode="outline"
                themeColor="primary"
                size="small"
                height="fit-content"
                full={false}
                onClick={runSapGroup}
                authorityType={AuthorityType.DATA_AUTHORITY}
                authorityLevel={AuthorityLevel.UPDATE}
                xMargin="mx-1"
              ></RestrictedSubmitButton>
            )}
        </div>
      );
    } else if (currentItem.groupType === 'GROUP') {
      return (
        <div className="d-flex gap-3">
          {props.item.status === ESapFlowStatus.TRANSACTION_INTERVENTION_REQUIRED && (
            <RestrictedSubmitButton
              label="Resume"
              fillMode="outline"
              themeColor="primary"
              size="small"
              height="fit-content"
              full={false}
              onClick={resumeSapGroup}
              authorityType={AuthorityType.DATA_AUTHORITY}
              authorityLevel={AuthorityLevel.UPDATE}
              xMargin="mx-1"
            ></RestrictedSubmitButton>
          )}
          {props.item.status !== ESapFlowStatus.TRANSACTION_IN_PROCESS &&
            props.item.status !== ESapFlowStatus.TRANSACTION_INTERVENTION_REQUIRED && (
              <RestrictedSubmitButton
                label="Run"
                fillMode="outline"
                themeColor="primary"
                size="small"
                height="fit-content"
                full={false}
                onClick={runSapGroup}
                authorityType={AuthorityType.DATA_AUTHORITY}
                authorityLevel={AuthorityLevel.UPDATE}
                xMargin="mx-1"
              ></RestrictedSubmitButton>
            )}
        </div>
      );
    }
    return null;
  };
  const renderSapFlowActions = () => {
    return (
      <div className="d-flex gap-3">
        <RestrictedSubmitButton
          label="Configure"
          fillMode="outline"
          themeColor="info"
          size="small"
          height="fit-content"
          full={false}
          onClick={() => {
            props.onConfigure();
          }}
          authorityType={AuthorityType.DATA_AUTHORITY}
          authorityLevel={AuthorityLevel.EXECUTE}
          xMargin="mx-1"
        ></RestrictedSubmitButton>
        {props.item.status === ESapFlowStatus.TRANSACTION_INTERVENTION_REQUIRED && (
          <RestrictedSubmitButton
            label="Resume"
            fillMode="outline"
            themeColor="primary"
            size="small"
            height="fit-content"
            full={false}
            onClick={resumeSapFlow}
            authorityType={AuthorityType.DATA_AUTHORITY}
            authorityLevel={AuthorityLevel.UPDATE}
            xMargin="mx-1"
          ></RestrictedSubmitButton>
        )}
        {props.item.status !== ESapFlowStatus.TRANSACTION_IN_PROCESS &&
          props.item.status !== ESapFlowStatus.TRANSACTION_INTERVENTION_REQUIRED && (
            <RestrictedSubmitButton
              label="Run"
              fillMode="outline"
              themeColor="primary"
              size="small"
              height="fit-content"
              full={false}
              onClick={runSapFlow}
              authorityType={AuthorityType.DATA_AUTHORITY}
              authorityLevel={AuthorityLevel.UPDATE}
              xMargin="mx-1"
            ></RestrictedSubmitButton>
          )}
      </div>
    );
  };
  const renderSapProcessNodeActions = () => {
    return (
      <div className="d-flex gap-3">
        <div className="d-flex gap-3">
          <RestrictedSubmitButton
            label={''}
            icon={clipboardTextIcon}
            iconSize="medium"
            fillMode="outline"
            themeColor="primary"
            size="small"
            height="fit-content"
            full={false}
            onClick={() => {
              props.onHistory();
            }}
            authorityType={AuthorityType.DATA_AUTHORITY}
            authorityLevel={AuthorityLevel.UPDATE}
            xMargin="mx-1"
          ></RestrictedSubmitButton>
          <RestrictedSubmitButton
            label="Run"
            fillMode="outline"
            themeColor="primary"
            size="small"
            height="fit-content"
            full={false}
            onClick={runProcessNode}
            authorityType={AuthorityType.DATA_AUTHORITY}
            authorityLevel={AuthorityLevel.EXECUTE}
            xMargin="mx-1"
          ></RestrictedSubmitButton>
        </div>
      </div>
    );
  };

  const renderActions = () => {
    if (props.item.type === 'TreeSapGroup') {
      return renderSapGroupActions();
    } else if (props.item.type === 'TreeSapFlow') {
      return renderSapFlowActions();
    } else if (props.item.type === 'TreeSapProcessNode') {
      return renderSapProcessNodeActions();
    }
  };

  const renderCheckbox = () => {
    if (props.item.type === 'TreeSapProcessNode') return null;
    const treeSapItem = props.item as TreeSapGroup | TreeSapFlow;
    return (
      <Checkbox
        value={treeSapItem.groupSelected}
        onChange={() => {
          dispatch({
            type: 'ToggleSapItemSelection',
            payload: { itemPath: props.itemHierarchicalIndex },
          });
        }}
      />
    );
  };

  const renderSapFlowStatusMenu = (itemType: 'TreeSapFlow' | 'TreeSapProcessNode') => {
    let options: any = [];
    if (itemType === 'TreeSapFlow') options = Object.values(ESapFlowStatus);
    else if (itemType === 'TreeSapProcessNode') options = Object.values(ESapFlowProcessStatus);
    return (
      <Popup show={hoveringStatus} offset={statusMenuOffset} popupClass={'popup-content'}>
        <Menu
          vertical={true}
          style={{ display: 'inline-block' }}
          onSelect={({ item, itemId, nativeEvent, syntheticEvent, target }) => {
            if (itemType === 'TreeSapFlow')
              setSapFlowStatusMutation.mutateAsync({ sapFlowId: props.item.id, status: options[parseInt(itemId)] });
            else if (itemType === 'TreeSapProcessNode')
              setSapFlowProcessNodeStatusMutation.mutateAsync({
                sapFlowId: props.parent.id,
                processNodeId: props.item.id,
                status: options[parseInt(itemId)],
              });
            syntheticEvent.stopPropagation();
            setHoveringStatus(false);
          }}
        >
          {options.map((status: any, index: number) => (
            <MenuItem
              key={index}
              //cssStyle={selectedReviewStatus === FileNodeReviewStatus.IDLE ? selectedStyle : null}
              text={status}
              disabled={status === props.item.status}
              render={() => <span>{status}</span>}
            />
          ))}
        </Menu>
      </Popup>
    );
  };

  const colorFromSapFlowStatus = (
    status: string
  ):
    | 'base'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'dark'
    | 'light'
    | 'inverse' => {
    if (status === ESapFlowStatus.TRANSACTION_CREATED) return 'light';
    else if (status === ESapFlowStatus.TRANSACTION_ERRORED) return 'error';
    else if (status === ESapFlowStatus.TRANSACTION_READY) return 'success';
    else if (status === ESapFlowStatus.TRANSACTION_FINISHED) return 'success';
    else if (status === ESapFlowStatus.TRANSACTION_IN_PROCESS) return 'info';
    else if (status === ESapFlowStatus.TRANSACTION_INTERVENTION_REQUIRED) return 'warning';
  };
  const colorFromProcessNodeStatus = (
    status: string
  ):
    | 'base'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'dark'
    | 'light'
    | 'inverse' => {
    if (status === ESapFlowProcessStatus.CREATED) return 'light';
    else if (status === ESapFlowProcessStatus.STARTED) return 'info';
    else if (status === ESapFlowProcessStatus.IN_PROGRESS) return 'info';
    else if (status === ESapFlowProcessStatus.COMPLETED) return 'success';
    else if (status === ESapFlowProcessStatus.ERRORED) return 'error';
  };

  const renderStatus = (): JSX.Element => {
    if (props.item.type === 'TreeSapGroup') {
      return null;
    } else if (props.item.type === 'TreeSapFlow') {
      return (
        <div
          style={{ display: 'flex' }}
          onMouseLeave={() => {
            setHoveringStatus(false);
          }}
        >
          <SubmitButton
            buttonRef={statusButtonRef}
            onClick={() => {
              setHoveringStatus(!hoveringStatus);
            }}
            size="small"
            height="fit-content"
            label={props.item.status}
            themeColor={colorFromSapFlowStatus(props.item.status)}
            fillMode={props.item.status === ESapFlowStatus.TRANSACTION_READY ? 'outline' : 'solid'}
            style={{ height: 'fit-content' }}
          ></SubmitButton>
          {renderSapFlowStatusMenu(props.item.type)}
        </div>
      );
    } else if (props.item.type === 'TreeSapProcessNode') {
      return (
        <div
          style={{ display: 'flex' }}
          onMouseLeave={() => {
            setHoveringStatus(false);
          }}
        >
          <SubmitButton
            buttonRef={statusButtonRef}
            onClick={() => {
              setHoveringStatus(!hoveringStatus);
            }}
            size="small"
            height="fit-content"
            label={props.item.status}
            themeColor={colorFromProcessNodeStatus(props.item.status)}
            fillMode={props.item.status === ESapFlowProcessStatus.STARTED ? 'outline' : 'solid'}
            style={{ height: 'fit-content' }}
          ></SubmitButton>
          {renderSapFlowStatusMenu(props.item.type)}
        </div>
      );
    }
    return null;
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <div style={{ flex: '1 1' }}>{props.item.name}</div>
      <div style={{ width: '200px' }}>{renderStatus()}</div>
      <div style={{ width: '350px', display: 'flex', justifyContent: 'end' }}>
        {creatingGroup ? renderCheckbox() : renderActions()}
      </div>
    </div>
  );
};

export default FileExplorerItem;
