import React, { useState } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Typography } from '@progress/kendo-react-common';
import Dialog from '../../components/Dialog';
import { SubmitButton } from '../../components/form';

interface Field {
  name: string;
  label: string;
  placeholder?: string;
  type?: string;
  required?: boolean;
}

interface GenericFormModalProps {
  show: boolean;
  title: string;
  fields: Field[];
  handleClose: () => void;
  onConfirm: (formData: Record<string, string>) => void;
  submitLabel?: string;
}

const GenericFormModal: React.FC<GenericFormModalProps> = ({
  show,
  title,
  fields,
  handleClose,
  onConfirm,
  submitLabel = 'Submit',
}) => {
  const [formData, setFormData] = useState<Record<string, string>>({});

  const handleChange = (name: string, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    onConfirm(formData);
    setFormData({}); // Reset form
  };

  return (
    <Dialog
      show={show}
      title={<Typography.h3>{title}</Typography.h3>}
      onClose={handleClose}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {fields.map((field) => (
          <Input
            key={field.name}
            label={field.label}
            value={formData[field.name] || ''}
            onChange={(e) => handleChange(field.name, e.value)}
            placeholder={field.placeholder}
            type={field.type || 'text'}
            required={field.required}
          />
        ))}
        <SubmitButton type="button" label={submitLabel} uppercase={false} full={false} onClick={handleSubmit} />
      </div>
    </Dialog>
  );
};

export default GenericFormModal;
