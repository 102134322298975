import { useContext, useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';
import Extent, { getArea } from 'ol/extent';
import { transformExtent } from 'ol/proj.js';
import { Appearance3D, LayerType, ViewLayer } from '../../../../types';
import useViewLayer from '../../../../hooks/viewerconfig/useViewLayer';
import React from 'react';
import { FileNode, FileNodeType, FileNodeViewType } from '../../../../types/DataDelivery';
import OctreeObject from './OctreeObject';

const proj4 = window.proj4 || require('proj4');

interface Props {
  viewLayer: ViewLayer;
  show: boolean;
  zIndex: number;
  potreeViewer: any;
  appearance3D: Appearance3D;
}

const SUPPORTED_LAYER_TYPES = [LayerType.OctreeBin];

type Ref = {
  zoomTo: () => void;
} | null;
const DataDeliveryLayer = forwardRef<Ref, Props>((props, ref) => {
  const internalRef = useRef(null);
  const [layerReady, setLayerReady] = useState(false);
  const [zoomWhenReady, setZoomWhenReady] = useState(false);

  useEffect(() => {
    if (zoomWhenReady && layerReady && internalRef.current) {
      internalRef.current.zoomTo();
      setZoomWhenReady(false);
    }
  }, [zoomWhenReady, internalRef.current, layerReady]);

  const zoomTo = () => {
    setZoomWhenReady(true);
  };

  useImperativeHandle(ref, () => ({
    zoomTo,
  }));

  const getInternalLayer = () => {
    if (props.viewLayer?.layerType === LayerType.OctreeBin) {
      return (
        <OctreeObject
          show={props.show}
          ref={(el: any) => {
            internalRef.current = el;
          }}
          zIndex={1}
          layer={props.viewLayer}
          appearance3D={props.appearance3D}
          potreeViewer={props.potreeViewer}
          onReady={() => {
            setLayerReady(true);
          }}
        />
      );
    }
    return null;
  };

  return <div>{getInternalLayer()}</div>;
});

DataDeliveryLayer.displayName = 'DataDeliveryLayer';

export default DataDeliveryLayer;
