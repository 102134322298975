import React, { useContext, useEffect, useImperativeHandle, forwardRef, useState, useRef } from 'react';
import MapContext from '../Map/MapContext';
import OLVectorLayer from 'ol/layer/Vector';
import { Unit } from '../../../../types';
import MapContextData from '../Map/MapContextData';
import { Feature } from 'ol';
import VectorSource from 'ol/source/Vector';
import { Fill, RegularShape, Stroke, Style, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { Draw, Modify } from 'ol/interaction.js';
import { getArea, getLength } from 'ol/sphere.js';
import { Geometry, LineString, Point } from 'ol/geom';
import { FeatureLike } from 'ol/Feature';
import { DrawEvent } from 'ol/interaction/Draw';
import { InteractionMode, useConsumeDataDeliveryState } from '../../../../context/dataDeliveryContext';
const showSegments = true;
const clearPrevious = false;

const style = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.2)',
  }),
  stroke: new Stroke({
    color: 'rgba(0, 0, 0, 0.5)',
    lineDash: [10, 10],
    width: 2,
  }),
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      color: 'rgba(0, 0, 0, 0.7)',
    }),
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.2)',
    }),
  }),
});

const labelStyle = new Style({
  text: new Text({
    font: '14px Calibri,sans-serif',
    fill: new Fill({
      color: 'rgba(255, 255, 255, 1)',
    }),
    backgroundFill: new Fill({
      color: 'rgba(0, 0, 0, 0.7)',
    }),
    padding: [3, 3, 3, 3],
    textBaseline: 'bottom',
    offsetY: -15,
  }),
  image: new RegularShape({
    radius: 8,
    points: 3,
    angle: Math.PI,
    displacement: [0, 10],
    fill: new Fill({
      color: 'rgba(0, 0, 0, 0.7)',
    }),
  }),
});

const tipStyle = new Style({
  text: new Text({
    font: '12px Calibri,sans-serif',
    fill: new Fill({
      color: 'rgba(255, 255, 255, 1)',
    }),
    backgroundFill: new Fill({
      color: 'rgba(0, 0, 0, 0.4)',
    }),
    padding: [2, 2, 2, 2],
    textAlign: 'left',
    offsetX: 15,
  }),
});

const modifyStyle = new Style({
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      color: 'rgba(0, 0, 0, 0.7)',
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 0, 0.4)',
    }),
  }),
  text: new Text({
    text: 'Drag to modify',
    font: '12px Calibri,sans-serif',
    fill: new Fill({
      color: 'rgba(255, 255, 255, 1)',
    }),
    backgroundFill: new Fill({
      color: 'rgba(0, 0, 0, 0.7)',
    }),
    padding: [2, 2, 2, 2],
    textAlign: 'left',
    offsetX: 15,
  }),
});

const segmentStyle = new Style({
  text: new Text({
    font: '12px Calibri,sans-serif',
    fill: new Fill({
      color: 'rgba(255, 255, 255, 1)',
    }),
    backgroundFill: new Fill({
      color: 'rgba(0, 0, 0, 0.4)',
    }),
    padding: [2, 2, 2, 2],
    textBaseline: 'bottom',
    offsetY: -12,
  }),
  image: new RegularShape({
    radius: 6,
    points: 3,
    angle: Math.PI,
    displacement: [0, 8],
    fill: new Fill({
      color: 'rgba(0, 0, 0, 0.4)',
    }),
  }),
});
const segmentStyles = [segmentStyle];

const formatLength = function (line: Geometry, units: Unit) {
  const length = getLength(line);
  let output;
  if (units === Unit.FEET) {
    output = Math.round(length * 3.28084 * 100) / 100 + ' ft';
  } else if (length > 100) {
    output = Math.round((length / 1000) * 100) / 100 + ' km';
  } else {
    output = Math.round(length * 100) / 100 + ' m';
  }
  return output;
};

const formatArea = function (polygon: Geometry, units: Unit) {
  const area = getArea(polygon);
  let output;
  if (units === Unit.FEET) {
    output = Math.round(area * 10.7639 * 100) / 100 + ' ft\xB2';
  } else if (area > 10000) {
    output = Math.round((area / 1000000) * 100) / 100 + ' km\xB2';
  } else {
    output = Math.round(area * 100) / 100 + ' m\xB2';
  }
  return output;
};

interface Props {
  show: boolean;
  zIndex: number;
  active: boolean;
  measurements: Feature<Geometry>[];
  onMeasurementAdded?: (measurement: Feature) => void;
}

type Ref = {
  getOlLayer: (layer: any) => any;
} | null;
const MeasurementTool = forwardRef<Ref, Props>(({ zIndex = 0, show, measurements, onMeasurementAdded }, ref) => {
  //const { dispatch, units, olMapInitialized, mode2d } = useConsumeViewerState();
  const { dispatch, units, interactionMode } = useConsumeDataDeliveryState();
  const { map } = useContext<MapContextData>(MapContext);
  const [vectorLayerState, setVectorLayerState] = useState<any>(null);
  const vectorLayerRef = useRef<OLVectorLayer<VectorSource<Feature<Geometry>>>>(null);
  const sourceRef = useRef<VectorSource>(null);
  const interactionRef = useRef<any>(null);
  const modifyRef = useRef<any>(null);
  const tipPointRef = useRef<any>(null);
  const drawingRef = useRef<any>(false);

  const styleFunction = (feature: FeatureLike) => {
    const showSegments = true;
    const featureProperties = feature.getProperties();
    const selected = featureProperties['selected'];
    const styles: any[] = [];
    const geometry: any = feature.getGeometry();
    const type = geometry.getType();
    let point, label, line: LineString;
    const styling = style;
    if (selected) {
      styling.getFill().setColor('rgba(255, 255, 255, 0.4)');
      styling.getStroke().setColor('#ffcc33');
    } else {
      styling.getFill().setColor('rgba(255, 255, 255, 0.2)');
      styling.getStroke().setColor('rgba(0, 0, 0, 0.5)');
    }
    styles.push(styling);
    if (type === 'Polygon') {
      point = geometry.getInteriorPoint();
      label = formatArea(geometry, units);
      line = new LineString(geometry.getCoordinates()[0]);
    } else if (type === 'LineString') {
      point = new Point(geometry.getLastCoordinate());
      label = formatLength(geometry, units);
      line = geometry;
    }
    if (selected && line) {
      let count = 0;
      line.forEachSegment(function (a, b) {
        const segment = new LineString([a, b]);
        const label = formatLength(segment, units);
        if (segmentStyles.length - 1 < count) {
          segmentStyles.push(segmentStyle.clone());
        }
        const segmentPoint = new Point(segment.getCoordinateAt(0.5));
        segmentStyles[count].setGeometry(segmentPoint);
        segmentStyles[count].getText()?.setText(label);
        styles.push(segmentStyles[count]);
        count++;
      });
    }
    if (selected && label) {
      labelStyle.setGeometry(point);
      labelStyle.getText()?.setText(label);
      styles.push(labelStyle);
    }
    if (type === 'Point' && !modifyRef.current.getOverlay().getSource().getFeatures().length) {
      tipPointRef.current = geometry;
      tipStyle.getText()?.setText(drawingRef.current ? 'Double click to end measurement' : 'Click to start measuring');
      styles.push(tipStyle);
    }
    return styles;
  };

  useEffect(() => {
    if (map) {
      // Create a vector source with the clicked feature
      const source = new VectorSource();

      const modify = new Modify({ source: source, style: modifyStyle });

      const newVectorLayer = new OLVectorLayer({
        source: source,
        zIndex: 3,
        style: function (feature: FeatureLike) {
          return styleFunction(feature);
        },
      });

      // Add the vector layer to the map
      map.addLayer(newVectorLayer);

      //map.addInteraction(modify);

      // Save the vector layer reference
      vectorLayerRef.current = newVectorLayer;
      sourceRef.current = source;
      modifyRef.current = modify;
      setVectorLayerState(newVectorLayer);

      // Add click event handler
      //map.getViewport().addEventListener('contextmenu', (e: MouseEvent) => {
      //  e.preventDefault();
      //  if (e.button === 2) {
      //    dispatch({ type: 'CHANGE_NAVIGATION_2D', payload: { mode: NavigationMode2D.NORMAL } });
      //  }
      //});
      return () => {
        map.removeLayer(vectorLayerRef.current);
        //map.removeInteraction(modifyRef.current);
        vectorLayerRef.current = null;
        vectorLayerRef.current = null;
        sourceRef.current = null;
        modifyRef.current = null;
      };
    }
  }, [map, units]);

  useEffect(() => {
    if (!vectorLayerState) return;
    sourceRef.current.clear();
    sourceRef.current.addFeatures(measurements);
  }, [vectorLayerState, measurements]);

  const handleStartedMeasurement = (e: DrawEvent) => {
    drawingRef.current = true;
    if (clearPrevious) {
      sourceRef.current.clear();
    }
    modifyRef.current.setActive(false);
  };

  const handleCompletedMeasurement = (e: DrawEvent) => {
    drawingRef.current = false;
    modifyStyle.setGeometry(tipPointRef.current);
    modifyRef.current.setActive(true);
    map.once('pointermove', function () {
      modifyStyle.setGeometry(null);
    });
    onMeasurementAdded(e.feature);
  };

  useEffect(() => {
    if (!sourceRef.current) return;
    if (
      interactionMode !== InteractionMode.LINE_MEASUREMENT_2D &&
      interactionMode !== InteractionMode.POLYGON_MEASUREMENT_2D
    )
      return;
    let draw: Draw; // global so we can remove it later
    let drawType: 'LineString' | 'Polygon' = 'LineString';
    if (interactionMode === InteractionMode.LINE_MEASUREMENT_2D) {
      drawType = 'LineString';
    }
    if (interactionMode === InteractionMode.POLYGON_MEASUREMENT_2D) {
      drawType = 'Polygon';
    }
    modifyRef.current.setActive(true);
    const addInteraction = () => {
      draw = new Draw({
        source: sourceRef.current,
        type: drawType,
        condition: function (e) {
          // when the point's button is 1(leftclick), allows drawing
          if (e.originalEvent.buttons === 1) {
            return true;
          } else {
            return false;
          }
        },
        style: function (feature) {
          return styleFunction(feature);
        },
      });
      draw.on('drawstart', handleStartedMeasurement);
      draw.on('drawend', handleCompletedMeasurement);
      modifyRef.current.setActive(true);
      map.addInteraction(draw);
      interactionRef.current = draw;
    };

    addInteraction();

    return () => {
      map.removeInteraction(draw);
      modifyRef.current?.setActive(false);
    };
  }, [interactionMode, vectorLayerState]);

  const getOlLayer = (layer: any) => {
    return vectorLayerRef.current;
  };

  useImperativeHandle(ref, () => ({
    getOlLayer,
  }));
  return null;
});
MeasurementTool.displayName = 'MeasurementTool';

export default MeasurementTool;
