import { useEffect, useRef, useState } from 'react';
import { Vector3, compareVectors } from '../../../types/Vector';

interface CameraPollerProps {
  potreeViewer: any; // Replace 'any' with the correct type if available
  onCameraPositionChange: (position: Vector3, target: Vector3) => void;
}

const CameraPoller: React.FC<CameraPollerProps> = ({ potreeViewer, onCameraPositionChange }) => {
  const lastCameraPositionRef = useRef<{ x: number; y: number; z: number }>({
    x: 0,
    y: 0,
    z: 0,
  });
  const lastCameraTargetRef = useRef<{ x: number; y: number; z: number }>({
    x: 0,
    y: 0,
    z: 0,
  });

  useEffect(() => {
    const pollCameraPosition = () => {
      const cameraView = potreeViewer?.scene?.view;
      if (cameraView) {
        const { x, y, z } = cameraView.position;
        const currentPosition = { x, y, z };
        const positionChanged = compareVectors(lastCameraPositionRef.current, currentPosition);

        const currentTarget = cameraView.direction
          .clone()
          .multiplyScalar(potreeViewer.scene.view.radius)
          .add(potreeViewer.scene.view.position);
        const targetChanged = compareVectors(lastCameraTargetRef.current, currentTarget);
        if (positionChanged || targetChanged) {
          onCameraPositionChange(currentPosition, currentTarget);
          lastCameraPositionRef.current = currentPosition;
          lastCameraTargetRef.current = currentTarget;
        }
      }
    };
    const intervalId = setInterval(pollCameraPosition, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [potreeViewer]);

  return null;
};

export default CameraPoller;
