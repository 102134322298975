import { useQuery } from 'react-query';
import { SapFlow } from '../../../types';
import { useAxiosInstance } from '../../common/';

const useTransaction = (sapflowId: string, refetchInterval: number | false = false) => {
  const axios = useAxiosInstance();

  return useQuery(
    ['sapflow', sapflowId],
    async () => {
      const result = await axios.get<SapFlow>("sapflow/" + sapflowId);
      return result.data;
    },
    { refetchInterval }
  );
};

export default useTransaction;
