import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SvgIcon } from '@progress/kendo-react-common';
import { downloadIcon } from '@progress/kendo-svg-icons';
import { useUser } from '../../hooks/authentication';
import { useListTransactionItems } from '../../hooks/sapflow/';
import { Skeleton } from '@progress/kendo-react-indicators';
import { StyledGrid } from '../../components/styled';
import { GridColumn } from '@progress/kendo-react-grid';
import { useTable } from '../../hooks/common';
import { SapFlow } from '../../types';

const ProjectDownload: React.FC = () => {
  const { transactionId } = useParams();
  const { getUser } = useUser();
  const [data, setData] = useState<SapFlow[]>([]);
  const tableProps = useTable({ pageSize: 10, data, sortDescriptor: { field: 'created', dir: 'desc' } });

  const transactionItemsQuery = useListTransactionItems(getUser().id, transactionId);
  useEffect(() => {
    if (transactionItemsQuery.isSuccess) {
      setData(transactionItemsQuery.data);
    }
  }, [transactionItemsQuery.isSuccess, transactionItemsQuery.data]);

  const ActionsCell: React.FC<{ dataItem: SapFlow }> = ({ dataItem }) => {
    return (
      <td>
        <div className="d-flex gap-3">
          <div
            onClick={() => {
              /*useDownloadTransactionFile(getUser().id, transactionId, dataItem.id)*/
            }}
          >
            <a
              download
              href={
                process.env.REACT_APP_GEOSAP_SERVERURL +
                '/file_manager/download_files?user_id=' +
                getUser().id +
                '&transaction_id=' +
                transactionId +
                '&item_id_list=' +
                dataItem.id
              }
            >
              <SvgIcon icon={downloadIcon} style={{ fontSize: '1.2rem' }} />
            </a>
          </div>
          {/*<div onClick={() => useDownloadTransactionFile(getUser().id, transactionId, dataItem.id)}>*/}
          {/*  <span className="k-icon k-i-download" style={{ fontSize: '1.2rem' }}></span>*/}
          {/*</div>*/}
        </div>
      </td>
    );
  };

  return (
    <div className="container-fluid p-2 p-lg-5">
      <h1>DOWNLOAD</h1>
      {transactionItemsQuery.isLoading && (
        <div>
          <Skeleton shape="rectangle" className="w-100" style={{ height: '600px' }} />
        </div>
      )}
      {transactionItemsQuery.isSuccess && (
        <StyledGrid {...tableProps} pageable total={data.length} sortable>
          <GridColumn
            field="name"
            title="File Name"
            cell={({ dataItem }) => <td className="fw-bold">{dataItem.name}</td>}
          />

          <GridColumn field="status" title="Status" />
          <GridColumn field="fileformat" title="File Format" />
          <GridColumn field="group" title="Group" />
          <GridColumn field="type" title="Type" />
          <GridColumn cell={ActionsCell} title="Actions" />
        </StyledGrid>
      )}
    </div>
  );
};

export default ProjectDownload;
