import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import {useQueryClient} from "react-query";
import {
  FileNode,
  FileNodeType,
  FileNodeStatus,
  DOWNLOAD_SIZE_WARNING,
} from '../../../types/DataDelivery';
import RenameLayerModal from '../../../components/filenodes/RenameLayerModal';
import DeleteLayerModal from '../../../components/filenodes/DeleteLayerModal';
import { useUser } from '../../../hooks/authentication';
import { AuthorityLevel, AuthorityType, UserRole } from '../../../types';
import ContextMenu from '../../../components/filenodes/ContextMenu';
import PropertiesPanel from '../../../components/filenodes/PropertiesPanel';
import DownloadWarningModal from '../../../components/filenodes/DownloadWarningModal';
import { assignOrReplaceDescendant, getFileNodeTag } from '../../../common/fileNodeHelper';
import { useFileNodeQueries, useFileNodeUpdate } from '../../../hooks/data_delivery';
import { OverlayedLoader, WrappedOrEllipsisSpan } from '../../../components';
import MoveFileNodeModal from '../../../components/filenodes/MoveFileNodeModal';
import { DeliveryNavigationMode, useConsumeDataDeliveryState } from '../../../context/dataDeliveryContext';
import { useFileNodeActions } from '../../../actions';
import NavigationSidebarLayerItem from './NavigationSidebarLayerItem';
import { uuidv4 } from '../../../common/uuid';

const LayerElement = styled.div`
  position: relative;
  color: 'rgb(116,116,116)',
  background: white;
  padding: 16px 24px;
  display: flex;
  font-size: 0.85rem;
  justify-content: center;
  align-items: center;
`;

const NavigationLayerList: React.FC = () => {
  const { dispatch, rootFileNode, hoveredFileNode, navigationOptions, navigationMode } = useConsumeDataDeliveryState();
  const { handleDeleteFileNode, handleForgetFileNode, handleDownloadFileNode, handleUploadFileNode } =
    useFileNodeActions();
  const queryClient = useQueryClient();
  const [confirmingDownloadFileNode, setConfirmingDownloadFileNode] = useState<FileNode>(null);
  const [showProperties, setShowProperties] = useState(false);
  const [fileNodeProperties, setFileNodeProperties] = useState<FileNode>(null);
  const [contextMenuFileNode, setContextMenuFileNode] = React.useState(null);
  const [contextOffset, setContextOffset] = React.useState(null);
  const [movingFileNode, setMovingFileNode] = useState(null);
  const [renamingLayer, setRenamingLayer] = React.useState(null);
  const [deletingFileNode, setDeletingFileNode] = React.useState(null);
  const { rootId } = useParams();
  const rootDataDelivery = rootId === undefined;
  const { userHasAuthority, userHasRole, getViewAccessToken, getCurrentOrganization } = useUser();
  const filenodeUpdateMutation = useFileNodeUpdate();
  const { refetchRootStructure, refetchFileNodeOverview, isFetchingRootStructure, isFetchingFileNodeOverview } =
    useFileNodeQueries();
  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener('click', () => {
      if (contextMenuFileNode) {
        setContextMenuFileNode(null);
      }
    });
  }, [contextMenuFileNode]);

  const handleFileNodeVisibility = (fileNode: FileNode) => {
    if (fileNode.hidden) {
      fileNode.hidden = false;
      const newRoot = assignOrReplaceDescendant(rootFileNode, fileNode);
      queryClient.setQueryData(["fileNodeStructure", rootFileNode.id], JSON.parse(JSON.stringify(newRoot)));
      //dispatch({ type: 'SET_OPENED_FILE_NODE', payload: JSON.parse(JSON.stringify(newRoot)) });
    } else {
      fileNode.hidden = true;
      const newRoot = assignOrReplaceDescendant(rootFileNode, fileNode);
      queryClient.setQueryData(["fileNodeStructure", rootFileNode.id], JSON.parse(JSON.stringify(newRoot)));
    }
  };

  const handleExpandFileNode = (fileNode: FileNode) => {
    if (rootDataDelivery) {
      if (getViewAccessToken()) {
        // Viewer mode
        dispatch({ type: 'SET_ROOT', payload: null });
        navigate('/shared-deliver/' + fileNode.id);
      } else {
        dispatch({ type: 'SET_ROOT', payload: null });
        navigate('/delivery/' + fileNode.id);
      }
    } else if (fileNode.expanded) {
      fileNode.expanded = false;
      const newRoot = assignOrReplaceDescendant(rootFileNode, fileNode);
      queryClient.setQueryData(["fileNodeStructure", rootFileNode.id], JSON.parse(JSON.stringify(newRoot)));
      //dispatch({ type: 'SET_OPENED_FILE_NODE', payload: JSON.parse(JSON.stringify(newRoot)) });
    } else {
      fileNode.loading = true;
      const newRoot = assignOrReplaceDescendant(rootFileNode, fileNode);
      queryClient.setQueryData(["fileNodeStructure", rootFileNode.id], JSON.parse(JSON.stringify(newRoot)));
      //dispatch({ type: 'SET_OPENED_FILE_NODE', payload: JSON.parse(JSON.stringify(newRoot)) });
      refetchFileNodeOverview(fileNode.id).then((queryData) => {
        const data: FileNode = JSON.parse(JSON.stringify(queryData)); // Deep copy
        data.loading = false;
        data.expanded = true;
        const newRoot = assignOrReplaceDescendant(rootFileNode, data);
        queryClient.setQueryData(["fileNodeStructure", rootFileNode.id], JSON.parse(JSON.stringify(newRoot)));
        //dispatch({ type: 'SET_OPENED_FILE_NODE', payload: JSON.parse(JSON.stringify(newRoot)) });
        dispatch({ type: 'ZOOM_TO_FILENODE', payload: data });
      });
    }
  };

  const renderFileNodeElement = (fileNode: FileNode, level: number) => {
    const fileNodeKey = fileNode.id === '00000000-0000-0000-0000-000000000000' ? uuidv4() : fileNode.id;
    const selectable =
      fileNode.isDir || fileNode.status === FileNodeStatus.READY; /* || fileNode !== props.selectedFileNode*/
    //const selected = fileNode === props.selectedFileNode;
    const hovered = fileNode === hoveredFileNode;
    const backgroundColor = hovered ? 'var(--geosap-input-border-color)' : 'white';
    const color = hovered ? 'white' : 'rgb(116,116,116)';
    if (
      !navigationOptions?.showSupport &&
      (fileNode.isSupport ||
        fileNode.fileType === FileNodeType.OTHER ||
        fileNode.fileType === FileNodeType.SUPPORT ||
        fileNode.fileType === FileNodeType.SHAPEFILE_ATTR ||
        fileNode.fileType === FileNodeType.SHAPEFILE_INDEX ||
        fileNode.fileType === FileNodeType.SHAPEFILE_PROJECTION)
    )
      return null;

    return (
      <div key={fileNodeKey} style={{ paddingLeft: level * 16 }}>
        <NavigationSidebarLayerItem
          fileNode={fileNode}
          onToggleVisibility={handleFileNodeVisibility}
          hideExpandButton={rootDataDelivery}
          hideVisibilityIcon={rootDataDelivery}
          draggable={!rootDataDelivery}
          onMoreOptions={(e) => {
            e.stopPropagation();
            setContextOffset({ left: e.clientX, top: e.clientY });
            setContextMenuFileNode(fileNode);
          }}
          onExpandFileNode={handleExpandFileNode}
        />
        {fileNode.isDir && fileNode.expanded && fileNode.children
          ? fileNode?.children?.map((childFileNode, index) => renderFileNodeElement(childFileNode, level + 1))
          : null}
      </div>
    );
  };

  const isLoading =
    (rootDataDelivery && isFetchingRootStructure(getCurrentOrganization().id)) ||
    (!rootDataDelivery && isFetchingFileNodeOverview(rootId));
  return (
    <div style={{ width: '100%', height: '100%', overflow: 'auto', position: 'relative' }}>
      <div
        className="animated-background"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '3px',
          background:
            navigationMode === DeliveryNavigationMode.LAYERS
              ? '#3CBBDF'
              : navigationMode === DeliveryNavigationMode.MEASUREMENTS
              ? '#89C541'
              : '#E0493B',
        }}
      />
      <OverlayedLoader show={isLoading > 0} />
      {rootFileNode?.children?.map((childFileNode, index) => renderFileNodeElement(childFileNode, 0))}
      <DownloadWarningModal
        filenode={confirmingDownloadFileNode}
        show={confirmingDownloadFileNode !== null}
        handleClose={() => {
          setConfirmingDownloadFileNode(null);
        }}
        onConfirm={() => {
          handleDownloadFileNode(confirmingDownloadFileNode);
          setConfirmingDownloadFileNode(null);
        }}
      />
      <ContextMenu
        show={contextMenuFileNode !== null}
        fileNode={contextMenuFileNode}
        offset={contextOffset}
        onZoomToFileNode={(filenode) => {
          dispatch({ type: 'ZOOM_TO_FILENODE', payload: filenode });
        }}
        onDownloadFileNode={() => {
          if (contextMenuFileNode.size && contextMenuFileNode.size > DOWNLOAD_SIZE_WARNING) {
            setConfirmingDownloadFileNode(contextMenuFileNode);
          } else {
            handleDownloadFileNode(contextMenuFileNode);
          }
        }}
        onMoveFileNode={(fileNode: FileNode) => {
          setMovingFileNode(fileNode);
        }}
        onForgetFileNode={handleForgetFileNode}
        onPropertiesFileNode={(filenode) => {
          setShowProperties(true);
          setFileNodeProperties(filenode);
        }}
        onCloseContextMenu={() => {
          setContextMenuFileNode(null);
        }}
      ></ContextMenu>
      <PropertiesPanel
        show={showProperties}
        onClose={() => {
          setShowProperties(false);
        }}
        rootFileNode={rootFileNode}
        fileNode={fileNodeProperties}
      />

      {userHasAuthority(AuthorityType.DATA_AUTHORITY, AuthorityLevel.UPDATE) && (
        <MoveFileNodeModal
          show={movingFileNode !== null}
          handleClose={() => {
            setMovingFileNode(null);
          }}
          fileNode={movingFileNode}
        />
      )}

      <RenameLayerModal
        show={renamingLayer !== null}
        handleClose={() => {
          setRenamingLayer(null);
        }}
        defaultValue={renamingLayer?.displayName}
        onConfirm={(value: string) => {
          //props.onRenameLayer(renamingLayer, value);
          setRenamingLayer(null);
        }}
      />
      <DeleteLayerModal
        show={deletingFileNode !== null}
        handleClose={() => {
          setDeletingFileNode(null);
        }}
        fileNode={deletingFileNode}
        onConfirm={() => {
          handleDeleteFileNode(deletingFileNode);
          setDeletingFileNode(null);
        }}
      />
    </div>
  );
};

export default NavigationLayerList;
