import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useConsumeDataDeliveryState } from '../../../../context/dataDeliveryContext';
import { useUser } from '../../../../hooks/authentication';
import { Typography } from '@progress/kendo-react-common';
import { useParams } from 'react-router-dom';
import AnalysisItem from './AnalysisListItem';

const ProcessingTools: React.FC = () => {
  const { userHasAuthority } = useUser();
  const { dispatch, openedFileNode, interactionMode, viewer3dOpened, measurementLayers, viewConfig } =
    useConsumeDataDeliveryState();
  const { rootId } = useParams();
  const rootDataDelivery = rootId === undefined;

  const renderUnavailableOverlay = () => {
    if (!rootDataDelivery) return null;
    return (
      <div
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(0.5,0.5,0.5,0.5)',
          zIndex: 1,
        }}
      >
        <div
          style={{
            width: '90%',
            marginTop: '2rem',
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: '0.75rem',
            display: 'flex',
            flexDirection: 'column',
            border: '4px solid #FF0000',
            background: '#FFCBCB',
            color: 'black',
            alignItems: 'center',
          }}
        >
          <span style={{ fontWeight: 700, fontSize: '1.25rem' }}>Tools Are Disabled</span>
          <span style={{ fontWeight: 500, fontSize: '1rem' }}>Select a project to use these tools.</span>
        </div>
      </div>
    );
  };

  const renderList = () => {
    const items = [
      {
        title: 'Slope Analysis',
        description: 'Analyze terrain slope changes in a selected area.',
        output: 'Raster slope heat map.',
      },
      {
        title: 'Vector Change Analysis',
        description: 'Take two vector input datasets to determine the change volume.',
        output: 'Raster heat map showing the amount of change.',
      },
      {
        title: 'Volumetric Change Analysis',
        description: 'Volumetric change analysis compares two point clouds in the same location and determines the changes between the two.',
        output: 'New point cloud attribute.',
      },
      {
        title: 'Elevation Accuracy Report',
        description: 'Compares vertical elevation differences between control points to point cloud data',
        output: 'PDF or text report.',
      },
      {
        title: 'Vegetation Encroachment Analysis',
        description: 'Analysis of point cloud to determine which points are closest to a given vector input.',
        output: 'New point cloud attribute.',
      },
      {
        title: 'Point Density Report',
        description: 'Determines point density across a point cloud',
        output: 'Raster difference map.',
      },
      {
        title: 'Raster Change Analysis',
        description: 'Compare two raster images for pixel-based changes.',
        output: 'Raster difference map.',
      },
    ];

    return (
      <div style={{ maxWidth: '400px', margin: '0 auto', fontFamily: 'Arial, sans-serif' }}>
        {items.map((item, index) => (
          <AnalysisItem key={index} title={item.title} description={item.description} output={item.output} />
        ))}
      </div>
    );
  };

  return (
    <div
      className=""
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        position: 'relative',
        padding: '1rem',
        color: 'white',
      }}
    >
      {renderUnavailableOverlay()}
      {/* 2D Tools */}
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem', marginBottom: '1rem' }}>
        <Typography.h3
          className="d-none d-lg-flex"
          style={{
            padding: '0px 0px',
            display: 'flex',
            margin: '0 0',
            flex: 1,
            justifyContent: 'flex-start',
          }}
        >
          Analysis & Reporting
        </Typography.h3>
        <div className="d-flex">
          <Typography.h4
            className="d-none d-lg-flex"
            style={{
              padding: '0px 0px',
              display: 'flex',
              margin: '0 0',
              flex: 1,
              justifyContent: 'flex-start',
            }}
          >
            2D Analysis
          </Typography.h4>
        </div>
        {renderList()}
      </div>
    </div>
  );
};

export default ProcessingTools;
