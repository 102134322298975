import { useContext, useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';
import MapContext from '../Map/MapContext';
import Extent, { getArea } from 'ol/extent';
import { transformExtent } from 'ol/proj.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import { bbox as bboxStrategy } from 'ol/loadingstrategy.js';
import VectorSource from 'ol/source/Vector';
import { Layer2DClickResult, LayerType, ViewLayer } from '../../../../types';
import useViewLayer from '../../../../hooks/viewerconfig/useViewLayer';
import VectorLayer from './VectorLayer';
import React from 'react';
import { FileNode, FileNodeType, FileNodeViewType } from '../../../../types/DataDelivery';
import { getWfsPreviewLayerUrl } from '../../../../common/viewLayerHelper';
import AnnotationLayer from './AnnotationLayer';
import { fetchProj4 } from '../../../../common/proj4Helper';
import GeotiffLayer from './GeotiffLayer';
import GsVecLayer from './GsVecLayer';
import GeojsonLayer from './GeojsonLayer';
import { useConsumeDataDeliveryState } from '../../../../context/dataDeliveryContext';
import { MapBrowserEvent } from 'ol';

const proj4 = window.proj4 || require('proj4');

interface Props {
  fileNode: FileNode;
  show: boolean;
  zIndex: number;
  style?: any;
  onLayerUpdated?: (layer: ViewLayer) => void;
}

const SUPPORTED_ZOOMED_IN_LAYER_TYPES = [LayerType.GeoTif, LayerType.GSVEC, LayerType.GeoJSON];

type Ref = {
  zoomToLayer: (layer: any) => void;
  handleMapClick: (evt: MapBrowserEvent<any>) => Promise<Layer2DClickResult>;
} | null;
const DataDeliveryLayer = forwardRef<Ref, Props>((props, ref) => {
  const { dispatch, openedFileNode, hoveredFileNode, selectedFeature, featureFilter } = useConsumeDataDeliveryState();
  const { map } = useContext<any>(MapContext);
  const [viewLayerIdToFetch, setViewLayerIdToFetch] = useState(null);
  const useViewLayerQuery = useViewLayer(null, viewLayerIdToFetch);
  const [layer, setLayer] = useState<ViewLayer>(null);
  const lowResolutionRef = useRef<any>(null);
  const highResolutionRef = useRef<any>(null);
  const [shouldDisplayAnnotation, setShouldDisplayAnnotation] = useState(true);
  const [sufficientCoverage, setSufficientCoverage] = useState(false);
  const viewLayerRef = useRef<any>(null);

  const highlight = hoveredFileNode?.id === props.fileNode?.id;
  const opened = openedFileNode?.id === props.fileNode?.id;

  useEffect(() => {
    // Here we determine whether we should display annotation or actual layer.
    if (!layer) {
      // Layer isn't available.
      setShouldDisplayAnnotation(true);
      return;
    }
    if (!SUPPORTED_ZOOMED_IN_LAYER_TYPES.includes(layer?.layerType)) {
      // Layer type isn't supported
      setShouldDisplayAnnotation(true);
      return;
    }
    if (opened) {
      // We are focusing the layer
      setShouldDisplayAnnotation(false);
      return;
    }
    if (sufficientCoverage) {
      // Zoomed in enough
      setShouldDisplayAnnotation(false);
      return;
    }
    setShouldDisplayAnnotation(true);
  }, [layer, opened, sufficientCoverage]);

  useEffect(() => {
    setLayer(useViewLayerQuery.data);
    viewLayerRef.current = useViewLayerQuery.data;
    const code = useViewLayerQuery.data?.paramsMap['projection'];
    const mapMovedHandler = () => {
      calculateIfDataOrAnnotationShouldBeDisplayed();
    };
    const prepareLayer = async () => {
      if (code) {
        await fetchProj4(code);
      }
      if (map) {
        map.getView().on('change:resolution', mapMovedHandler);
      }
    };
    prepareLayer();
    return () => {
      if (map) {
        map.getView().un('change:resolution', mapMovedHandler);
      }
    };
  }, [useViewLayerQuery.isSuccess, useViewLayerQuery.data, map]);

  useEffect(() => {
    const supportingWFSLayers = props.fileNode.supportFiles?.filter(
      (supportFile) => supportFile.fileType === FileNodeType.GSVEC && supportFile.name.includes(props.fileNode.name)
    );
    if (supportingWFSLayers && supportingWFSLayers.length > 0 && supportingWFSLayers[0].viewLayer) {
      setViewLayerIdToFetch(supportingWFSLayers[0].viewLayer.id);
    } else if (props.fileNode.viewLayer) {
      setViewLayerIdToFetch(props.fileNode.viewLayer.id);
    }
  }, [props.fileNode]);

  const getLayerExtents = (): Extent.Extent => {
    const projection = viewLayerRef.current?.paramsMap['projection'];
    const extents = viewLayerRef.current?.paramsMap['extents'];
    if (projection && extents) {
      let olExtents: Extent.Extent = [extents[0], extents[1], extents[2], extents[3]];
      if (projection !== map.getView().getProjection().getCode()) {
        olExtents = transformExtent(olExtents, projection, map.getView().getProjection().getCode());
      }
      return olExtents;
    }
  };

  const zoomToLayer = (layer: any) => {
    const olExtents: Extent.Extent = getLayerExtents();
    if (olExtents) {
      map.getView().fit(olExtents);
    }
  };

  const calculateIfDataOrAnnotationShouldBeDisplayed = () => {
    const olExtents: Extent.Extent = getLayerExtents();
    if (!olExtents) return;
    const view = map.getView();
    const extent = view.calculateExtent(map.getSize());
    const visibleArea = getArea(extent);
    const layerArea = getArea(olExtents);

    // Check if Layer occupies at least 20% of the viewport
    const sufficientCoverage = layerArea / visibleArea >= 0.1;
    setSufficientCoverage(sufficientCoverage);
  };

  const handleMapClick = async (evt: MapBrowserEvent<any>): Promise<Layer2DClickResult> => {
    let clickResult = null;
    if (highResolutionRef.current && highResolutionRef.current.handleMapClick) {
      clickResult = await highResolutionRef.current.handleMapClick(evt);
    }
    return clickResult;
  };

  useImperativeHandle(ref, () => ({
    zoomToLayer,
    handleMapClick,
  }));

  const getAnnotationLayer = () => {
    if (props.fileNode.isSupport) return null;
    return (
      <AnnotationLayer
        ref={(el: any) => {
          lowResolutionRef.current = el;
        }}
        key={props.fileNode.id}
        show={true}
        fileNode={props.fileNode}
        zIndex={props.zIndex}
        highlight={highlight}
      ></AnnotationLayer>
    );
  };

  const getZoomedInLayer = () => {
    if (!props.fileNode.viewTypes.includes(FileNodeViewType.MAP)) {
      return null;
    }
    if (layer?.layerType === LayerType.GeoTif && !props.fileNode.isSupport) {
      return (
        <GeotiffLayer
          ref={(el: any) => {
            highResolutionRef.current = el;
          }}
          show={props.show}
          zIndex={props.zIndex}
          style={{
            'stroke-width': 0.75,
            'stroke-color': 'white',
            'fill-color': highlight ? 'rgba(85,137,85,0.25)' : 'rgba(100,100,100,0.25)',
          }}
          layer={layer}
        />
      );
    } else if (layer?.layerType === LayerType.GSVEC && !props.fileNode.isSupport) {
      return (
        <GsVecLayer
          ref={(el: any) => {
            highResolutionRef.current = el;
          }}
          selectedFeature={selectedFeature}
          show={props.show}
          zIndex={props.zIndex}
          layer={layer}
        />
      );
    } else if (layer?.layerType === LayerType.GeoJSON && !props.fileNode.isSupport) {
      return (
        <GeojsonLayer
          ref={(el: any) => {
            highResolutionRef.current = el;
          }}
          featureFilter={featureFilter}
          show={props.show}
          zIndex={props.zIndex}
          layer={layer}
        />
      );
    }
    return null;
  };

  if (props.fileNode.hidden) {
    return null;
  }

  return (
    <div>
      {shouldDisplayAnnotation && getAnnotationLayer()}
      {!shouldDisplayAnnotation && getZoomedInLayer()}
    </div>
  );
});

DataDeliveryLayer.displayName = 'DataDeliveryLayer';

export default DataDeliveryLayer;
