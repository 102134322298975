export interface PointcloudVisualization {
  attribute: string;
  attributeValues: {
    [key: string]: PCAttributeVisualization; // Index signature for dynamic properties
  };
}

export interface PCAttributeVisualization {
  attributeType: PCAttributeType;
  min?: number;
  max?: number;
  currentMin?: number;
  currentMax?: number;
  gamma?: number;
  brightness?: number;
  contrast?: number;
  classificationSchemas?: { [key: string]: PCClassification };
}

export enum PCAttributeType {
  COLORED = 'COLORED',
  RANGE = 'RANGE',
  CLASSIFICATION = 'CLASSIFICATION',
}

export interface RangeVisualization {
  min: number;
  max: number;
}

export interface PCClassification {
  index: number;
  visible: boolean;
  name: string;
  color: number[];
}

export interface PotreePointcloudAttribute {
  name: string;
  type: {
    ordinal: number;
    name: string;
    size: number;
  };
  numElements: number;
  byteSize: number;
  description: string;
  range: number[];
  distinctValues: any[];
  initialRange: number[];
}

export const RGBA = 'rgba';
export const INTENSITY = 'intensity';
export const INTENSITY_GRADIENT = 'intensity gradient';
export const CLASSIFICATION = 'classification';
export const SAMPLECLASSIFICATION = 'sampleClassification';
export const USER_DATA = 'user data';
export const ELEVATION = 'elevation z';
export const ELEVATION_NORMALIZED = 'elevation normalized';
export const HEIGHT = 'height';
export const NUMBER_RETURNS = 'number of returns';
export const RETURN_NUMBER = 'return number';
export const SOURCE_ID = 'source id';
export const POINT_SOURCE_ID = 'point source id';

export const SUPPORTED_ATTRIBUTES = [RGBA, INTENSITY, INTENSITY_GRADIENT, CLASSIFICATION, USER_DATA, ELEVATION, HEIGHT];
export const IGNORED_ATTRIBUTES = [
  'position',
  //'return number',
  'number of returns',
  //'scan angle rank',
  'point source id',
  //'gps-time',
  'classification flags',
  //'ScanChannel',
  'ClassFlags',
];

export const DEFAULT_ATTRIBUTE_VALUES: any = {
  DEFAULT: {
    0: { name: 'Never Classified', color: [0.5, 0.5, 0.5, 1] },
    1: { name: 'Unclassified', color: [0.5, 0.5, 0.5, 1] },
    2: { name: 'Ground', color: [0.63, 0.32, 0.18, 1] },
    3: { name: 'Low Vegetation', color: [0.0, 1.0, 0.0, 1] },
    4: { name: 'Medium Vegetation', color: [0.0, 0.8, 0.0, 1] },
    5: { name: 'High Vegetation', color: [0.0, 0.6, 0.0, 1] },
    6: { name: 'Building', color: [1.0, 0.66, 0.0, 1] },
    7: { name: 'Low point(noise)', color: [1.0, 0.0, 1.0, 1] },
    8: { name: 'Key-point', color: [1.0, 0.0, 0.0, 1] },
    9: { name: 'Water', color: [0.0, 0.0, 1.0, 1] },
    10: { name: 'Rail', color: [0.0, 0.0, 0.0, 1] },
    11: { name: 'Road Surface', color: [0.0, 0.0, 0.0, 1] },
    12: { name: 'Default', color: [0.0, 0.0, 0.0, 1] },
    13: { name: 'Wire - Guard (Shield)', color: [0.0, 0.0, 0.0, 1] },
    14: { name: 'Wire - Conductor (Phase)', color: [0.0, 0.0, 0.0, 1] },
    15: { name: 'Transmission Tower', color: [0.0, 0.0, 0.0, 1] },
    16: { name: 'Wire-Structure Connector (Insulator)', color: [0.0, 0.0, 0.0, 1] },
    17: { name: 'Bridge Deck', color: [0.5, 0.6, 0.1, 1] },
    18: { name: 'High Noise', color: [0.1, 0.3, 0.25, 1] },
  },
};
