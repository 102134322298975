import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Popup, Offset } from '@progress/kendo-react-popup';
import { Menu, MenuItemModel } from '@progress/kendo-react-layout';
import { useSelectOrganization, useSignout, useUser } from '../../../hooks/authentication';
import { useAppContext } from '../../../context/app';

interface ContextMenuProps {
  show: boolean;
  offset: Offset;
  onCloseContextMenu(): void;
}

const ContextMenu: React.FC<ContextMenuProps> = (props: ContextMenuProps) => {
  const { availableOrganizations } = useAppContext();
  const { getUser, getAccessToken } = useUser();
  const selectOrgMutation = useSelectOrganization();
  const navigate = useNavigate();
  const signoutMutation = useSignout();

  const availableOptions: MenuItemModel[] = [];
  availableOptions.push({
    text: 'Settings',
    data: {
      action: () => {
        navigate('settings');
        props.onCloseContextMenu();
      },
    },
  });
  if (availableOrganizations && availableOrganizations.length > 1) {
    availableOptions.push({
      text: 'Switch Organization',
      data: {
        action: () => {
          selectOrgMutation.mutateAsync(null).then(() => {
            navigate('/selectorganization');
          });
          props.onCloseContextMenu();
        },
      },
    });
  }
  availableOptions.push({
    text: 'Logout',
    data: {
      action: () => {
        const { email } = getUser();
        const bearerToken = getAccessToken();
        signoutMutation.mutate({ email, bearerToken });
        props.onCloseContextMenu();
      },
    },
  });
  return (
    <Popup show={props.show && props.offset !== null} offset={props.offset} popupClass={'popup-content'}>
      <Menu
        vertical={true}
        style={{ display: 'inline-block' }}
        items={availableOptions}
        onSelect={({ item, itemId, nativeEvent, syntheticEvent, target }) => {
          item.data.action();
          syntheticEvent.stopPropagation();
        }}
      ></Menu>
    </Popup>
  );
};

export default ContextMenu;
