import React, { useRef, useState, useEffect } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { saveAs, encodeBase64 } from '@progress/kendo-file-saver';
import { useAxiosInstance } from '../hooks/common/';
import { useQuery } from 'react-query';
import { SapCategory } from '../types';

const Test: React.FC = () => {
  // const {initWebsocketClient, sendMessageToServer, closeConnection} = useWebsocketClient();
  const axios = useAxiosInstance();
  // const dataURI = "data:text/plain;base64," + encodeBase64("Hello World!");
  const endpoint = 'http://127.0.0.1:8093/api/file_manager/test_download';

  const testDownlod = async () => {
    const result = await axios.get(endpoint);
    const data = result.data;
    console.log('Headers: ', data.headers);
    // console.log(data);
    const blob = new Blob([data], { type: 'application/laz' });
    data.headers;
    saveAs(blob, 'test.laz');

    // saveAs(dataURI, "test.txt");
  };

  return (
    <div>
      <Button onClick={testDownlod}>Test Download</Button>
    </div>
  );
};

export default Test;
