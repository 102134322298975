import React from 'react';
import PropTypes from 'prop-types';

interface Props {
  children: any;
}
const Objects: React.FC<Props> = ({ children }) => {
  return <div>{children}</div>;
};

Objects.propTypes = {
  children: PropTypes.any,
};

export default Objects;
