import { useQueryClient, useMutation } from 'react-query';
import { useAxiosInstance } from '../../common';
import { FileNode } from '../../../types/DataDelivery';
import { CollectionType, CollectionValidationResult } from '../../../types/DataProcessing';

interface Params {
  collectionType: CollectionType,
  path: string
}

const useValidateCollection = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (params: Params) => {
      const response = await axios.post<CollectionValidationResult>('data_wrangling/collection/validate', params);
      return response.data;
    },
    {
      onSuccess: () => {
        //queryClient.invalidateQueries(['sapGroups'], { refetchInactive: true });
      },
    }
  );
};

export default useValidateCollection;
