import { useMutation } from 'react-query';
import { useAxiosInstance } from '../common';
import { ESapFlowStatus } from '../../types';

interface Params {
  sapFlowId: string;
  status: ESapFlowStatus;
}

const useSetSapFlowStatus = () => {
  const axios = useAxiosInstance();

  return useMutation(
    async (params: Params) => {
      return axios.put('/file_sapflow/' + params.sapFlowId + '/status', {}, { params: { newstatus: params.status } });
    },
    {
      onSuccess: () => {
        //queryClient.invalidateQueries(['transactions'], { refetchInactive: true });
      },
    }
  );
};

export default useSetSapFlowStatus;
