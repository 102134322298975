import React, { useEffect } from 'react';
import Dialog from '../../components/Dialog';
import { Typography } from '@progress/kendo-react-common';
import { SvgIcon } from '@progress/kendo-react-common';
import { chevronLeftIcon } from '@progress/kendo-svg-icons';
import {
  DataCollectionWizardProvider,
  useDataCollectionWizardState,
  DataCollectionWizardStep,
} from '../../context/dataCollectionWizard';
import SelectConfigType from './steps/SelectConfigType';
import EnterPath from './steps/EnterPath';
import AdjustConfig from './steps/AdjustConfig';

interface Props {
  show: boolean;
  clientProjectId: string;
  handleClose: () => void;
}

const WizardContent: React.FC<Props> = ({ show, handleClose, clientProjectId }) => {
  const { state, dispatch } = useDataCollectionWizardState();

  useEffect(() => {
    handleClose();
  }, [state.done]);

  useEffect(() => {
    dispatch({ type: 'SELECT_CLIENT_PROJECT', payload: { clientProjectId } });
  }, [clientProjectId]);

  return (
    <Dialog
      show={show}
      title={<Typography.h3>Data Collection Configuration</Typography.h3>}
      onClose={handleClose}
      style={{ display: 'flex', flexDirection: 'column', width: '1200px', height: '800px', maxHeight: '80%' }}
    >
      <div className="d-flex flex-column h-100 position-relative">
        {state.step > 0 && (
          <div
            style={{ position: 'absolute', top: 0, left: 0, cursor: 'pointer', zIndex: 1000 }}
            onClick={() => dispatch({ type: 'GO_BACK', payload: {} })}
          >
            <SvgIcon icon={chevronLeftIcon} size="large" />
          </div>
        )}
        <div className="container">
          <div className="row align-items-center justify-content-center">
            {
              {
                [DataCollectionWizardStep.SELECT_CONFIG_TYPE]: <SelectConfigType />,
                [DataCollectionWizardStep.ENTER_PATH]: <EnterPath />,
                [DataCollectionWizardStep.ADJUST_CONFIG]: <AdjustConfig />,
              }[state.step]
            }
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const DataCollectionWizard: React.FC<Props> = (props) => {
  return (
    <DataCollectionWizardProvider clientProjectId={props.clientProjectId}>
      <WizardContent {...props} />
    </DataCollectionWizardProvider>
  );
};

export default DataCollectionWizard;
