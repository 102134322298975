import React, { useEffect } from 'react';
import { RangeSlider, Slider } from '@progress/kendo-react-inputs';
import ClassificationToggle from './ClassificationToggle';
import {
  AuthorityLevel,
  AuthorityType,
  HEIGHT,
  PCAttributeType,
  PointcloudVisualization,
  RGBA,
  ViewLayer,
} from '../../../../../../types';
import { updateLayerParam } from '../../../../../../common/viewerConfigHelper';
import { useUser } from '../../../../../../hooks/authentication';

// To avoid transpiler error on window.Potree  https://stackoverflow.com/questions/56457935/typescript-error-property-x-does-not-exist-on-type-window
declare const window: any;
const Potree = window.Potree;

interface HeightControlsProps {
  layer: ViewLayer;
  onLayerUpdate?: (layer: ViewLayer, persistent?: boolean) => void;
  onAttributeValueChange: (visualizationParamPath: string, value: any) => void;
  onAttributeRangeChange: (visualizationParamPath: string, newMin: any, newMax: any) => void;
  onClassificationUpdate?: (
    layer: ViewLayer,
    attributeName: string,
    id: number,
    name: string,
    visible: boolean,
    color: number[]
  ) => void;
}

const HeightControls: React.FC<HeightControlsProps> = (props: HeightControlsProps) => {
  const visualization = props.layer?.paramsMap?.visualization;
  const height = visualization?.attributeValues ? visualization?.attributeValues[HEIGHT] : null;

  if (!height) {
    return null;
  }

  const heightValue = height.value;
  return (
    <div className="flex-column d-flex m-2">
      <span>
        Range: {0} to {100}
      </span>
      <Slider
        min={-200}
        max={300}
        step={0.01}
        defaultValue={heightValue}
        onChange={(e: any) => {
          props.onAttributeValueChange('height.value', e.value);
        }}
      ></Slider>
    </div>
  );
};

export default HeightControls;
