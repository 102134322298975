import React, { Fragment, useEffect, useState } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { Reveal } from '@progress/kendo-react-animation';
import { Loader, Skeleton } from '@progress/kendo-react-indicators';
import { ExpansionPanelActionEvent } from '@progress/kendo-react-layout';
import StyledExpansionPanel from '../../components/styled/StyledExpansionPanel';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { truncateText } from '../../common/stringHelper';
import 'jsoneditor-react/es/editor.min.css';
import Dialog from '../../components/Dialog';
import { Input, SubmitButton, Select } from '../../components/form';
import ProcessParamsForm from '../SapFlowCreation/ProcessParamsForm';
import { useSapConfigParams, useSapFlow, useSapFlowParamsUpdate } from '../../hooks/data_delivery';
import {
  SapConfigParamDetails,
  SapConfigParamType,
  SapConfigProcessNode,
  SapProcessNodeParamDetails,
} from '../../types/DataDelivery';
import { useServers } from '../../hooks/system';
import { DeploymentServer, EServerType, SapFlowProcessNode } from '../../types';
import { Switch } from '@progress/kendo-react-inputs';
import useSapFlowUpdate from '../../hooks/data_wrangling/useSapFlowUpdate';

interface Props {
  show: boolean;
  handleClose: () => void;
  onConfirm: () => void;
  configId: string;
  sapflowId: string;
}

interface FormValues {
  resetWorkspace: boolean;
  resetOutput: boolean;
  processName: string;
  processDescription: string;
  projectTags: string[] | null;
  processNodeSelected: SapConfigProcessNode | null;
}

const getValidationSchema = () =>
  yup.object({
    processName: undefined, //yup.object().required(REQUIRED_FIELD),
    resetWorkspace: undefined,
    resetOutput: undefined,
    processDescription: undefined, //yup.object().required(REQUIRED_FIELD),
    processNodeSelected: undefined,
  });

let shown = false;

const specificServer: DeploymentServer = { id: '0', machineName: 'Specific', online: true, inetAddress: '' };

const ProvideDetailsStep: React.FC<Props> = (props: Props) => {
  const sapFlowUpdateMutation = useSapFlowUpdate();
  const sapFlowParamsUpdateMutation = useSapFlowParamsUpdate();
  const sapFlowQuery = useSapFlow(shown ? props.sapflowId : null);
  const serversQuery = useServers();
  const paramsQuery = useSapConfigParams(shown ? props.configId : null);
  const [validationSchema, setValidationSchema] = useState(getValidationSchema());
  const { handleSubmit, control, formState, reset, watch, setValue } = useForm<FormValues>({
    defaultValues: {
      resetWorkspace: false,
      resetOutput: false,
      processDescription: '',
      processNodeSelected: null,
    },
    resolver: yupResolver(validationSchema),
  });
  const paramsForm = useForm<any>();
  const sapFlowConfigValue = watch('processNodeSelected');
  const [sharedProcessingServer, setSharedProcessingServer] = useState(specificServer);
  useEffect(() => {
    shown = true;
  }, [props.show]);
  useEffect(() => {
    if (!sapFlowQuery.isLoading && !sapFlowQuery.isRefetching && sapFlowQuery.data) {
      console.log('Restting');
      reset({
        processName: sapFlowQuery.data.sapflowname,
        processDescription: sapFlowQuery.data.sapflowdescription,
        resetWorkspace: sapFlowQuery.data.resetWorkspace,
        resetOutput: sapFlowQuery.data.resetOutput,
        processNodeSelected: null,
      });
      //paramsForm.reset();
      setSharedProcessingServer(specificServer);
    }
  }, [sapFlowQuery.isLoading, sapFlowQuery.isRefetching, sapFlowQuery.data]);

  const getAvailableServersByType = (applicationType: EServerType): DeploymentServer[] => {
    if (serversQuery.isLoading) return [];
    if (applicationType === EServerType.GS_SERVER) {
      return [];
    } else if (applicationType === EServerType.GS_ROOTS_SERVER) {
      return serversQuery.data.filter((server) => server.rootsServerEnabled);
    } else if (applicationType === EServerType.GS_FOLIAGE_SERVER) {
      return serversQuery.data.filter((server) => server.foliageServerEnabled);
    } else if (applicationType === EServerType.GS_DATA_PROCESSING_SERVER) {
      return serversQuery.data.filter((server) => server.dataProcServerEnabled);
    } else if (applicationType === EServerType.GS_IMAGE_SERVER) {
      return serversQuery.data.filter((server) => server.imageServerEnabled);
    } else if (applicationType === EServerType.GS_COMMUNICATOR_SERVER) {
      return serversQuery.data.filter((server) => server.communicatorServerEnabled);
    } else return [];
  };

  const handleExpansionPanelAction = (selected: SapConfigProcessNode | null, event: ExpansionPanelActionEvent) => {
    setValue('processNodeSelected', event.expanded ? null : selected, { shouldDirty: true });
  };

  const provideDetails = async ({ processName, processDescription, resetWorkspace, resetOutput }: FormValues) => {
    if (!sapFlowQuery.data) return;
    const updateBody = {
      sapFlowId: sapFlowQuery.data.id,
      name: processName,
      description: processDescription,
      resetWorkspace: resetWorkspace,
      resetOutput: resetOutput,
    };
    if (paramsQuery.isSuccess && paramsQuery.data.length > 0) {
      // Proceed to update sapflow parameters
      const paramValues = paramsForm.getValues();
      const processnodeparams: any = {};
      sapFlowQuery.data.processnodes.forEach((processNode: SapFlowProcessNode) => {
        // filter flag params
        const processNodeParams = paramValues[processNode.order];
        const originalValues = paramsQuery.data.filter((param) => param.processNodeOrder === processNode.order)[0]
          .params;
        Object.keys(processNodeParams).filter((param: any) => {
          if (
            !processNodeParams[param].active &&
            !(param === 'processingServer' && sharedProcessingServer.id !== '0')
          ) {
            delete processNodeParams[param];
          }
        });
        processnodeparams[processNode.id] = {};
        Object.keys(processNodeParams).forEach((processNodeParam) => {
          const originalParam = originalValues.filter(
            (originalParamDetails) => originalParamDetails['name'] === processNodeParam
          )[0];
          if (originalParam && originalParam.type === SapConfigParamType.ListInt) {
            processnodeparams[processNode.id][processNodeParam] = processNodeParams[processNodeParam].value.map(
              (listValue: any) => listValue.value
            );
          } else if (originalParam && originalParam.type === SapConfigParamType.Boolean) {
            processnodeparams[processNode.id][processNodeParam] = processNodeParams[processNodeParam].value
              ? 'True'
              : 'False';
          } else if (processNodeParam === 'processingServer' && sharedProcessingServer.id !== '0') {
            processnodeparams[processNode.id][processNodeParam] = sharedProcessingServer.machineName;
          } else {
            processnodeparams[processNode.id][processNodeParam] = processNodeParams[processNodeParam].value;
          }
        });
      });
      const paramUpdateBody = {
        sapFlowId: sapFlowQuery.data.id,
        sapFlowParams: processnodeparams,
      };
      await sapFlowParamsUpdateMutation.mutateAsync(paramUpdateBody);
    }
    await sapFlowUpdateMutation.mutateAsync(updateBody);
    return;
  };

  const parametersAvailable = true;
  const loading = sapFlowQuery.isLoading;
  return (
    <Dialog
      show={props.show}
      title={<Typography.h3>SapFlow Configuration</Typography.h3>}
      onClose={props.handleClose}
      style={{ display: 'flex', flexDirection: 'column', width: '1200px', height: '800px', maxHeight: '80%' }}
    >
      {loading && <Loader></Loader>}
      {!loading && sapFlowQuery.data && (
        <div style={{ paddingTop: '4rem' }}>
          <Typography.h1 fontWeight="light" textAlign="center">
            {sapFlowQuery.data.sapflowname}
          </Typography.h1>
          <Typography.h5 fontWeight="light" textAlign="center">
            {sapFlowQuery.data.id}
          </Typography.h5>
          <Typography.h3 fontWeight="light" textAlign="center">
            SapFlow Configuration
          </Typography.h3>
          <div>
            <form onSubmit={handleSubmit(provideDetails)}>
              <div className="w-75 mx-auto">
                {/*<div className="d-flex justify-content-center py-4">
                  <Controller
                    control={control}
                    name="resetWorkspace"
                    render={({ field, fieldState: { error } }) => (
                      <Typography.h4 fontWeight="light" textAlign="center" className="d-flex justify content-center">
                        <span className="text-center d-flex align-items-center ps-3 mx-2">Reset workspace</span>
                        <div className="mx-2">
                          <Switch className="neutral my-1" {...field} checked={field.value} />
                        </div>
                      </Typography.h4>
                    )}
                  />
                </div>
                <div className="d-flex justify-content-center py-4">
                  <Controller
                    control={control}
                    name="resetOutput"
                    render={({ field, fieldState: { error } }) => (
                      <Typography.h4 fontWeight="light" textAlign="center" className="d-flex justify content-center">
                        <span className="text-center d-flex align-items-center ps-3 mx-2">Reset output</span>
                        <div className="mx-2">
                          <Switch className="neutral my-1" {...field} checked={field.value} />
                        </div>
                      </Typography.h4>
                    )}
                  />
                </div>*/}
              </div>
              <Typography.h4 className="mt-4" fontWeight="light" textAlign="center">
                Parameters
              </Typography.h4>

              <div className="d-flex align-items-center justify-content-center p-2">
                <span className="px-2 text-end" style={{ flex: 4 }}>
                  Processing Server
                </span>
                <div className="px-2" style={{ flex: 8 }}>
                  <Select
                    className="w-75"
                    data={[specificServer, ...getAvailableServersByType(EServerType.GS_DATA_PROCESSING_SERVER)]}
                    value={sharedProcessingServer}
                    dataItemKey="id"
                    textField="machineName"
                    name={'processingServer'}
                    onChange={(e) => {
                      setSharedProcessingServer(e.target.value);
                    }}
                  />
                </div>
              </div>
              {parametersAvailable && (
                <div className="m-auto mt-4 w-75">
                  {sapFlowQuery.data === null && (
                    <div>
                      {new Array(5)
                        .fill(<Skeleton shape="rectangle" style={{ width: '100%', height: '74px' }} className="my-3" />)
                        .map((skeleton, i) => (
                          <Fragment key={i}>{skeleton}</Fragment>
                        ))}
                    </div>
                  )}
                  {sapFlowQuery.data !== null &&
                    sapFlowQuery.data.processnodes.length > 0 &&
                    sapFlowQuery.data.processnodes.map((processNode: SapFlowProcessNode, index: number) => {
                      let processNodeParams: SapProcessNodeParamDetails[] = [];
                      if (paramsQuery.isSuccess && paramsQuery.data.length > 0) {
                        const processNodeParamDetails: SapConfigParamDetails = paramsQuery.data.filter(
                          (param) => param.processNodeId === processNode.configid
                        )[0];
                        if (processNodeParamDetails) {
                          processNodeParams = processNodeParamDetails.params;
                          if (processNodeParamDetails.strictParams === false) {
                            processNode.params.forEach((instanceParam: any) => {
                              let key = instanceParam.key;
                              if (key.charAt(0) === '-') {
                                key = key.substring(1);
                              }
                              if (
                                processNodeParams.filter((configParam: SapProcessNodeParamDetails) => {
                                  return configParam.name === key;
                                }).length === 0
                              ) {
                                // Add the instance param to the list of params
                                processNodeParams.push({
                                  id: '',
                                  name: key,
                                  displayname: key,
                                  description: '',
                                  toolbox: '',
                                  toolblock: '',
                                  sap: '',
                                  type: SapConfigParamType.String,
                                  default_value: '',
                                  possible_values: [],
                                  version: 1,
                                });
                              }
                            });
                          }
                        }
                      }
                      let processingServerParam = processNodeParams.find((param) => {
                        return param.name === 'processingServer';
                      });
                      if (processNode.name === 'Run LAS Classification Script')
                        console.log('Processing server param', processingServerParam);
                      const servers = getAvailableServersByType(processNode.applicationType);
                      if (!processingServerParam) {
                        processingServerParam = {
                          id: '',
                          name: 'processingServer',
                          displayname: 'Processing Server',
                          description: 'The server on which the ProcessNode will be executed.',
                          toolbox: '',
                          toolblock: '',
                          sap: '',
                          type: SapConfigParamType.Enum,
                          default_value: [''],
                          possible_values: servers.map((server) => server.machineName),
                          version: 1,
                        };
                        processNodeParams.unshift(processingServerParam);
                      } else {
                        processingServerParam.possible_values = servers.map((server) => server.machineName);
                      }
                      return (
                        <StyledExpansionPanel
                          key={processNode.id}
                          title={
                            <>
                              <Typography.h3 className="flex-grow-1 text-capitalize">
                                {truncateText(index + 1 + ' - ' + processNode.displayname, 46)}
                              </Typography.h3>
                            </>
                          }
                          expanded={sapFlowConfigValue?.id === processNode.id}
                          tabIndex={0}
                          onAction={handleExpansionPanelAction.bind(undefined, processNode)}
                          expandIcon="k-icon k-i-arrow-chevron-right animated-transform rotated-0"
                          collapseIcon="k-icon k-i-arrow-chevron-right animated-transform rotated--90"
                        >
                          <Reveal>
                            <ProcessParamsForm
                              processNodeIndex={index}
                              processNode={processNode}
                              loading={paramsQuery.isLoading}
                              processNodeParams={processNodeParams}
                              sapflowProcessingServer={sharedProcessingServer}
                              form={paramsForm}
                              show={sapFlowConfigValue?.id === processNode.id}
                            />
                          </Reveal>
                        </StyledExpansionPanel>
                      );
                    })}
                </div>
              )}
              <div className="py-3 w-75 d-flex justify-content-end mx-auto">
                <SubmitButton
                  label="Submit details"
                  uppercase={false}
                  full={false}
                  disabled={!formState.isDirty}
                  //disabled={!formState.isDirty /* && !formHasValue*/}
                  loading={sapFlowParamsUpdateMutation.isLoading}
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default ProvideDetailsStep;
