import React from 'react';
import { ReactComponent as LayerIcon } from '../../../assets/icons/delivery-layer.svg';
import { ReactComponent as MeasureIcon } from '../../../assets/icons/delivery-measure.svg';
import { ReactComponent as SapFlowLogo } from '../../../assets/icons/navbar-sapflow.svg';
import { DeliveryNavigationMode, useConsumeDataDeliveryState } from '../../../context/dataDeliveryContext';

const NavigationModeBar: React.FC = () => {
  const { dispatch, navigationMode } = useConsumeDataDeliveryState();
  const modeOptions = [
    { Icon: LayerIcon, color: '#3CBBDF', navigationMode: DeliveryNavigationMode.LAYERS },
    { Icon: MeasureIcon, color: '#89C541', navigationMode: DeliveryNavigationMode.MEASUREMENTS },
    { Icon: SapFlowLogo, color: '#E0493B', navigationMode: DeliveryNavigationMode.PROCESSES },
  ];
  return (
    <div
      className="d-flex flex-column"
      style={{ width: 'var(--geosap-delivery-navbar-mode-width)', height: '100%', background: 'black' }}
    >
      {modeOptions.map((mode, index) => {
        return (
          <div
            className="p-4"
            style={{
              position: 'relative',
              opacity: mode.navigationMode === navigationMode ? 1 : 0.5,
              cursor: 'pointer',
            }}
            key={index}
            onClick={() => {
              dispatch({ type: 'SET_NAVIGATION_MODE', payload: mode.navigationMode });
            }}
          >
            <mode.Icon style={{ width: '100%', height: '100%' }} fill={'white'} stroke="white" />
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: '10%',
                bottom: '10%',
                width: '6px',
                background: mode.color,
              }}
            ></div>
          </div>
        );
      })}
    </div>
  );
};

export default NavigationModeBar;
