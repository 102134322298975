import { useQueryClient, useMutation } from 'react-query';
import { useAxiosInstance } from '../../common';

const useDeleteClientProject = (orgId: string) => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
    async (clientProjectId: string) => {
      const response = await axios.delete<string>(`data_wrangling/` + clientProjectId);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['clientProjects', orgId], { refetchInactive: true });
      },
    }
  );
};

export default useDeleteClientProject;
