import React, { useEffect, useState } from 'react';
import SapCategory from '../../../../types/SapCategory';
import { useConsumeDataProcessorState } from '../../../../context/dataProcessorContext';
import { useCategories } from '../../../../hooks/sapflow';
import AvailableCategories from './AvailableCategories';
import SapConfig from './SapConfig';

interface Tool {
  name: string;
  category: string;
  canUpgrade: boolean;
  selected: boolean;
}
const ToolPanel: React.FC = () => {
  const { dispatch, selectedSapConfig } = useConsumeDataProcessorState();

  if (selectedSapConfig) {
    return <SapConfig />;
  } else {
    return <AvailableCategories />;
  }
};

export default ToolPanel;
