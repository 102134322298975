import { SapFlow, DashboardTransaction, ESapFlowStatus } from '../types';

export const toDashboardTransaction = (sapFlowTrans: SapFlow): DashboardTransaction => ({
  id: sapFlowTrans.id,
  name: sapFlowTrans.sapflowname,
  createdDate: new Date(sapFlowTrans.createdDate),
  status: sapFlowTrans.status,
  projectId: sapFlowTrans.project?.id,
  projectName: sapFlowTrans.project?.name,
  expanded: false,
  errored: sapFlowTrans?.status === ESapFlowStatus.TRANSACTION_ERRORED,
  statusHistory: sapFlowTrans.transactionHistory,
  progress: sapFlowTrans.transactionPercentComplete,
  suppliedFiles: sapFlowTrans.itemnodes.filter((itemNode: any) => {
    return itemNode && itemNode.type === 'SUPPLIED';
  }),
  outputFiles: sapFlowTrans.itemnodes.filter((itemNode: any) => {
    return itemNode && itemNode.properties && itemNode.properties.includes('OUTPUT');
  }),
  visualizableFiles: sapFlowTrans.itemnodes.filter((itemNode: any) => {
    return itemNode && itemNode.properties && itemNode.properties.includes('VISUALIZE');
  }),
});

export const toDashboardTransactionSet = (sapFlowTransSet: SapFlow[]) =>
  sapFlowTransSet.map((t) => toDashboardTransaction(t));

export const toSapFlow = (dashboardTrans: DashboardTransaction): SapFlow => ({
  id: dashboardTrans.id,
  sapflowname: dashboardTrans.name,
  status: ESapFlowStatus.TRANSACTION_CREATED,
  configid: null,
  itemnodes: dashboardTrans.suppliedFiles,
  project: {
    id: dashboardTrans.projectId,
    name: dashboardTrans.projectName,
    description: '',
    user: null,
    lastModifiedDate: '',
  },
  sapflowdescription: '',
  createdDate: '',
  lastModifiedDate: '',
  processnodes: [],
  transactionHistory: [],
  transactionPercentComplete: 0,
  transactionMessage: '',
  transactionStartTime: 0,
  transactionFinishTime: 0,
  transactionRuntime: 0,
  resetWorkspace: false,
  resetOutput: false,
  copyToWorkspace: false
});
export const sapFlowStatusToLabel = (status: ESapFlowStatus) => {
    // Split by "_", remove the first element, and process the rest
    const parts = status.split("_").slice(1);
    return parts
        .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()) // Capitalize each word
        .join(" "); // Join with a space
}