import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { Skeleton } from '@progress/kendo-react-indicators';
import { GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { AuthorityLevel, AuthorityType, DashboardTransaction, Page, SapFlow } from '../../types';
import { toDashboardTransactionSet } from '../../converters/dashboardTransaction';
import { useAppContext } from '../../context/app';
import { useUser } from '../../hooks/authentication';
import { usePaginatedTransactions, useDeleteTransaction, useRunSapFlow } from '../../hooks/sapflow';
import { RestrictedDiv } from '../../components/restricted';
import { LinkButton } from '../../components/layout';
import { FetchIndicator } from '../../components/feedback';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import TransactionGrid from './TransactionGrid';
import ProjectList from './ProjectList';
import { SortDescriptor } from '@progress/kendo-data-query';
import { useQueries } from 'react-query';

const DASHBOARD_PAGESIZE = 10;

const Dashboard: React.FC = () => {
  const [data, setData] = useState<Page<DashboardTransaction>>(null);
  const [page, setPage] = useState<number>(0);
  const [sort, setSort] = useState<SortDescriptor[]>([{ field: 'createdDate', dir: 'desc' }]);
  const [transactions, setTransactions] = useState<SapFlow[]>([]);
  const { dispatch } = useAppContext();
  const navigate = useNavigate();
  const { getUser, getCurrentOrganization, userHasAuthority } = useUser();
  const [deletingTransaction, setDeletingTransaction] = useState<any>(null);
  const { mutate } = useRunSapFlow();

  const transactionsQuery = usePaginatedTransactions(
    false /*REFETCH_INTERVAL*/,
    page,
    DASHBOARD_PAGESIZE,
    sort
      .map((sortDesc: SortDescriptor) => {
        return [sortDesc.field, sortDesc.dir];
      })
      .flat()
  );
  const selectTransactionById = transactionsQuery.data
    ? transactionsQuery.data.selectTransactionById
    : (): void => undefined;

  const transactionQueries = useQueries<SapFlow[]>(
    transactionsQuery.data?.content
      ? transactionsQuery.data?.content?.map((id) => ({
          queryKey: ['transaction', id],
          queryFn: () => selectTransactionById(id),
        }))
      : []
  );

  const transactionsData = transactionQueries.map((query) => query.data);

  const dataString = JSON.stringify(transactionsData);

  useEffect(() => {
    const transactions: SapFlow[] = transactionQueries.map((q) => q.data as SapFlow).filter(Boolean);
    setTransactions(transactions);
  }, [transactionsQuery.isSuccess, transactionsQuery.data, dataString]);

  const deleteTransactionMutation = useDeleteTransaction(() => {
    setDeletingTransaction(null);
  });
  //console.log('Queries: ' + JSON.stringify(transactionQueries));

  const handleStart = async (transid: string) => {
    let sapFlow: SapFlow = null;
    try {
      sapFlow = transactions.filter((trans: SapFlow) => trans.id === transid)[0];
    } catch (e) {
      //
    }
    if (sapFlow === null) {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { content: "Couldn't start the sapflow transaction.", type: 'error' } },
      });
      return;
    }
    mutate(
      { sapflowid: sapFlow.id, processnodeid: sapFlow.project.id },
      {
        onSuccess: () => {
          const content = 'SAPFlow successfully started';
          dispatch({ type: 'SHOW_NOTIFICATION', payload: { notification: { content, type: 'success' } } });
        },
      }
    );
  };

  const handleDelete = async (transid: string) => {
    setDeletingTransaction(transid);
  };

  const handleDownload = async (transid: string) => {
    let trans: DashboardTransaction = null;
    const newTrans = data?.content.map((item) => {
      if (transid === item.id) {
        trans = item;
        return {
          ...item,
          downloading: true,
        };
      } else {
        return {
          ...item,
        };
      }
    });
    if (!trans) return;
    setData({ ...data, content: newTrans });
    const filesIdToDownload =
      !trans.outputFiles || trans.outputFiles.length === 0
        ? null
        : trans.outputFiles
            .map((file) => {
              return file.id;
            })
            .join();
    const url =
      process.env.REACT_APP_GEOSAP_SERVERURL +
      '/file_manager/download_items_async_zip?user_id=' +
      getUser().id +
      '&transaction_id=' +
      transid +
      '&item_id_list=' +
      filesIdToDownload +
      '&filename=ALL_OUTPUTS_ITEMS';
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'json';

    xhr.onload = () => {
      if (xhr.status === 200) {
        const { requestId, downloadUrl } = xhr.response;
        dispatch({
          type: 'CREATE_ZIP_NOTIFICATION',
          payload: {
            zipNotification: {
              totalBytes: 1,
              currentBytes: 0,
              fileTotalBytes: 0,
              fileCurrentBytes: 0,
              timeStamp: '',
              requestId: requestId,
              downloadUrl: downloadUrl,
              downloadFileName: trans.name,
              downloading: false,
            },
          },
        });
      } else {
        console.error('Error downloading file:', xhr.statusText);
      }
      return;
    };

    xhr.onerror = () => {
      console.error('Error downloading file:', xhr.statusText);
      const newData = data?.content.map((item) => {
        if (transid === item.id) {
          return {
            ...item,
            downloading: false,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      setData({ ...data, content: newData });
    };

    xhr.send();
  };

  const deleteTransaction = async (transid: string) => {
    await deleteTransactionMutation.mutateAsync({
      transid: transid,
    });
    setDeletingTransaction(null);
  };

  const handleExpand = (dataItem: DashboardTransaction) => {
    const newData = data?.content.map((item) => ({
      ...item,
      expanded: dataItem.id === item.id ? !item.expanded : item.expanded,
    }));
    setData({ ...data, content: newData });
  };

  useEffect(() => {
    if (transactionsQuery.isSuccess && transactions) {
      // User has no project/transaction
      if (
        transactionsQuery.data.empty &&
        userHasAuthority(AuthorityType.TRANSACTION_AUTHORITY, AuthorityLevel.CREATE)
      ) {
        navigate('/sapflow/new');
        return;
      }
      const newData: Page<DashboardTransaction> = {
        ...transactionsQuery.data,
        content: toDashboardTransactionSet(transactions),
      };
      setData(newData);
    }
  }, [transactionsQuery.isSuccess, transactions]);

  return (
    <div className="container-fluid p-5">
      {deletingTransaction && (
        <ConfirmationDialog
          show={true}
          onConfirm={() => {
            deleteTransaction(deletingTransaction);
          }}
          onClose={() => {
            setDeletingTransaction(null);
          }}
          loading={deleteTransactionMutation.isLoading}
          title="Please confirm"
          text="Are you sure you wish to delete this Transaction ?"
        />
      )}
      <div className="row pb-5">
        <RestrictedDiv
          authorityType={AuthorityType.TRANSACTION_AUTHORITY}
          authorityLevel={AuthorityLevel.CREATE}
          className="col"
        >
          <LinkButton label="Run SAPFlow" to="/sapflow" className="me-3" />
        </RestrictedDiv>
      </div>
      <div className="row pb-5">
        <div className="col">
          <Typography.h4 fontWeight="normal" className="text-uppercase">
            Projects
          </Typography.h4>
          <ProjectList />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="row pb-3 g-3">
            <div className="col-12 col-md-auto">
              <Typography.h4 fontWeight="normal">SAP FLOW ACTIVITY</Typography.h4>
            </div>
            <div className="col-12 col-md-auto">
              <FetchIndicator isFetching={transactionsQuery.isFetching} />
            </div>
          </div>
          {transactionsQuery.isLoading && deleteTransactionMutation.isLoading && (
            <div>
              <Skeleton shape="rectangle" className="w-100" style={{ height: '600px' }} />
            </div>
          )}
          {transactionsQuery.isSuccess && data && (
            <TransactionGrid
              data={data}
              sort={sort}
              page={page}
              pageSize={DASHBOARD_PAGESIZE}
              onExpand={handleExpand}
              onStart={handleStart}
              onDelete={handleDelete}
              onDownload={handleDownload}
              deletingRow={deletingTransaction}
              onPageChange={(e: GridPageChangeEvent) => {
                setPage(e.page.skip / DASHBOARD_PAGESIZE);
              }}
              onSortChange={(e: GridSortChangeEvent) => {
                setSort(e.sort);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
