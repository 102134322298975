import { useQuery } from 'react-query';
import { useAxiosInstance } from '../common';
import { SapFlow } from '../../types';

const useSapConfig = (sapFlowId: string) => {
  const axios = useAxiosInstance();
  return useQuery(['sapFlow', sapFlowId], async () => {
    if (sapFlowId === null) return null;
    const result = await axios.get<SapFlow>('file_sapflow/' + sapFlowId);
    return result.data;
  });
};

export default useSapConfig;
