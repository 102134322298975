import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useAppContext } from '../../../context/app';
import NavigationModeBar from './NavigationModeBar';
import DashboardNavigation from './DashboardNavigation';
import ProcessingNavigation from './ProcessingNavigation';
import { ProcessorNavigationMode, useConsumeDataProcessorState } from '../../../context/dataProcessorContext';

const LayersContainer = styled.div`
  background: var(--gs-darkgray-bg);
  width: var(--geosap-datadelivery-navigation-width);
  overflow: hidden;
`;

const NavigationSidebar = () => {
  const { screenWidth, dispatch: appDispatch } = useAppContext();
  const { dispatch, navigationMode } = useConsumeDataProcessorState();

  let style: React.CSSProperties = { position: 'absolute', left: 0, top: 0, bottom: 0, zIndex: 1 };
  if (screenWidth < 922) {
    style = { width: '100%', height: '300px', zIndex: 1, borderRadius: 0 };
  }

  const renderContent = () => {
    if (navigationMode === ProcessorNavigationMode.DASHBOARD) {
      return <DashboardNavigation />;
    } else if (navigationMode === ProcessorNavigationMode.PROCESSING) {
      return <ProcessingNavigation />;
    }
  };

  return (
    <LayersContainer className="d-flex flex-column animated-left" style={style}>
      <div style={{ height: '100%', width: '100%', display: 'flex' }}>
        <NavigationModeBar />
        {renderContent()}
      </div>
    </LayersContainer>
  );
};

export default NavigationSidebar;
