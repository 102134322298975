import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useAppContext } from '../../../context/app';
import NavigationHeader from './NavigationHeader';
import NavigationLayerList from './NavigationLayerList';
import NavigationModeBar from './NavigationModeBar';
import LayerInspector from './LayerInspector';
import MeasurementTools from './MeasurementTools';
import ProcessingTools from './ProcessingTools';
import { DeliveryNavigationMode, useConsumeDataDeliveryState } from '../../../context/dataDeliveryContext';

const LayersContainer = styled.div`
  background: var(--gs-darkgray-bg);
  width: var(--geosap-datadelivery-navigation-width);
  overflow: hidden;
`;

const NavigationSidebar = () => {
  const { screenWidth, dispatch: appDispatch } = useAppContext();
  const { dispatch, openedFileNode, navigationMode } = useConsumeDataDeliveryState();

  let style: React.CSSProperties = { position: 'absolute', left: 0, top: 0, bottom: 0, zIndex: 1 };
  if (screenWidth < 922) {
    style = { width: '100%', height: '300px', zIndex: 1, borderRadius: 0 };
  }

  const renderContent = () => {
    if (navigationMode === DeliveryNavigationMode.LAYERS) {
      if (openedFileNode) {
        return <LayerInspector />;
      } else {
        return <NavigationLayerList />;
      }
    } else if (navigationMode === DeliveryNavigationMode.MEASUREMENTS) {
      return <MeasurementTools />;
    } else if (navigationMode === DeliveryNavigationMode.PROCESSES) {
      return <ProcessingTools />;
    }
  };

  return (
    <LayersContainer className="d-flex flex-column animated-left" style={style}>
      <NavigationHeader />
      <div style={{ height: '100%', width: '100%', display: 'flex' }}>
        <NavigationModeBar />
        {renderContent()}
      </div>
    </LayersContainer>
  );
};

export default NavigationSidebar;
