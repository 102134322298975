import React, { useEffect, useState } from 'react';
import { Slider, SliderLabel } from '@progress/kendo-react-inputs';
import DraggableWindow from '../../../../../../components/DraggableWindow';
import { LayerType, ViewLayer } from '../../../../../../types';
import { useConsumeViewerState } from '../../../../../../context/viewer';
import { updateLayerParam } from '../../../../../../common/viewerConfigHelper';
import { getOrGenerateDefaultVisualizationParams } from '../../../../../../converters/viewLayerHelper';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import WFSPropertiesControls from './WFSPropertiesControls';
import VectorLegendControls from './VectorLegendControls';
//import VectorControls from './VectorControls';

interface VectorControlsProps {
  layer: ViewLayer;
  onLayerUpdated: (layer: ViewLayer) => void;
}

const VectorControls: React.FC<VectorControlsProps> = (props: VectorControlsProps) => {
  const [currentOpacity, setCurrentOpacity] = useState<number>(1);
  const [showWfsProperties, setShowWfsProperties] = useState(false);
  const [internalSelectedLayer, setInternalSelectedLayer] = useState<ViewLayer>(null);

  useEffect(() => {
    let newInternalLayer = { ...props.layer };
    //console.log('NewInternal Layer: ' + JSON.stringify(newInternalLayer));
    if (!props.layer) {
      // No selectd layer
    } else if (
      props.layer.id === internalSelectedLayer?.id &&
      props.layer.lastUpdated === internalSelectedLayer.lastUpdated
    ) {
      // No new changes to consider
    } else {
      const visualization = getOrGenerateDefaultVisualizationParams(props.layer);
      if (visualization && visualization['opacity'] !== null && visualization['opacity'] !== undefined) {
        setCurrentOpacity(visualization['opacity']);
      }
      if (!props.layer.paramsMap['visualization']) {
        const newLayer = JSON.parse(JSON.stringify(props.layer));
        updateLayerParam(newLayer, 'visualization', visualization);
        newInternalLayer = newLayer;
        props.onLayerUpdated(newLayer);
      }
    }
    setInternalSelectedLayer(newInternalLayer);
  }, [props.layer]);

  if (
    !internalSelectedLayer ||
    (internalSelectedLayer.layerType !== LayerType.GSVEC && internalSelectedLayer.layerType !== LayerType.WFS)
  )
    return null;

  return (
    <div>
      {/*<div className="d-flex flex-column p-2">
        <FloatingLabel
          label={'Visualization Type'}
          editorId={'visType'}
          editorValue={true}
          className="w-75 mx-auto pb-2"
        >
          <DropDownList
            style={{
              width: '200px',
            }}
            size="small"
            data={availableVisualizations}
            value={currentlySelectedVisualization}
            onChange={(event: any) => {
              const newLayer = JSON.parse(JSON.stringify(props.layer));
              const vis = newLayer.paramsMap.visualization;
              vis.type = event.target.value;
              updateLayerParam(newLayer, 'visualization', vis);
              setCurrentlySelectedVisualization(event.target.value);
              setInternalSelectedLayer(newLayer);
              props.onLayerUpdated(newLayer);
            }}
            disabled={props.layer === null}
            popupSettings={{ width: 250 }}
          />
        </FloatingLabel>
      </div>*/}
      {internalSelectedLayer.layerType === LayerType.GSVEC && (
        <div
          style={{ width: '100%', height: '10px' }}
          onClick={() => {
            setShowWfsProperties(!showWfsProperties);
          }}
        ></div>
      )}
      <div className="d-flex flex-column p-2">
        <FloatingLabel label={'Opacity'} editorId={'opacity'} editorValue={true} className="w-75 mx-auto pb-2">
          <Slider
            min={0}
            max={1}
            step={0.01}
            value={currentOpacity}
            onChange={(e) => {
              const newLayer = JSON.parse(JSON.stringify(props.layer));
              const vis = newLayer.paramsMap.visualization;
              vis.opacity = e.value;
              updateLayerParam(newLayer, 'visualization', vis);
              setCurrentOpacity(e.value);
              setInternalSelectedLayer(newLayer);
              props.onLayerUpdated(newLayer);
            }}
          >
            <SliderLabel position={0}>0%</SliderLabel>
            <SliderLabel position={1}>100%</SliderLabel>
          </Slider>
        </FloatingLabel>
      </div>
      {showWfsProperties && (
        <WFSPropertiesControls layer={internalSelectedLayer} onLayerUpdated={props.onLayerUpdated} />
      )}
      {<VectorLegendControls layer={internalSelectedLayer} onLayerUpdated={props.onLayerUpdated} />}
      {/*internalSelectedLayer && currentlySelectedVisualization === VisualizationType.VECTOR && (
        <VectorControls layer={internalSelectedLayer} onLayerUpdated={props.onLayerUpdated} />
      )*/}
    </div>
  );
};

export default VectorControls;
