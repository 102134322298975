import React, { useReducer, useContext } from 'react';
import { DataDeliveryState, initialState } from './dataDeliveryState';
import { dataDeliveryReducer, DataDeliveryAction } from './dataDeliveryReducer';

export interface DataDeliveryProviderValue extends DataDeliveryState {
  dispatch: (action: DataDeliveryAction) => void;
}

interface Props {
  children: JSX.Element | JSX.Element[];
  value: DataDeliveryProviderValue;
}

const DataDeliveryContext = React.createContext({
  ...initialState,
  dispatch: () => {
    // not implemented
  },
} as DataDeliveryProviderValue);

export const DataDeliveryProvider: React.FC<Props> = ({ children, value }) => {
  return <DataDeliveryContext.Provider value={value}>{children}</DataDeliveryContext.Provider>;
};

/*
  Create Data Delivery Wizard state.
*/
export const useCreateDataDeliveryState = (): DataDeliveryProviderValue => {
  const [state, dispatch] = useReducer(dataDeliveryReducer, {
    ...initialState,
  });
  return {
    ...state,
    dispatch,
  };
};

/*
  Consume Data Delivery Wizard state.
*/
export const useConsumeDataDeliveryState = () => {
  return useContext(DataDeliveryContext);
};
