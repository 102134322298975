import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Upload,
  UploadOnBeforeUploadEvent,
  UploadOnStatusChangeEvent,
  UploadFileStatus,
} from '@progress/kendo-react-upload';
import { Typography } from '@progress/kendo-react-common';
import { Switch } from '@progress/kendo-react-inputs';
import { DropDownTree, DropDownTreeChangeEvent, DropDownTreeExpandEvent } from '@progress/kendo-react-dropdowns';
import { processTreeData, expandedState } from '../../common/treeDataOperations';
import { useConsumeSapFlowWizardState } from '../../context/sapFlowWizard';
import { useSapFlow, useFileFormat, useReusableFiles } from '../../hooks/sapflow';
import { useUser } from '../../hooks/authentication';
import { useAuthorization } from '../../hooks/common';
import { formatSizeUnits } from '../../converters/formatSizeUnits';
import { SapFlowItemNode, RequiredFileStatus, ReusableFileStructure, FileFormat, TusUpload } from '../../types';
import { SubmitButton } from '../../components/form';
import FileNodeUploadModal from '../../components/filenodes/FileUploader/FileNodeUploadModal';
import { UploadWizardType } from '../../context/fileNodeUploadWizard';
import { useUploadContext } from '../../context/uploadContext';

interface Props {
  itemnode: SapFlowItemNode;
  handleStatusChange?: (newFileStatus: RequiredFileStatus, itemId: string) => void;
}

const InputDetailSelection: React.FC<Props> = ({ itemnode, handleStatusChange }) => {
  const { getUser, getCurrentOrganization, getTokenType, getAccessToken } = useUser();
  const [uploading, setUploading] = React.useState<boolean>(false);
  const [requiredFileStatus, setRequiredFileStatus] = useState<RequiredFileStatus>({
    ProvisionType: 'Upload',
    Uploaded: false,
    FilesToReuse: null,
  });
  const [treeOpened, setTreeOpened] = React.useState<boolean>(false);
  const { sapFlow, project } = useConsumeSapFlowWizardState();
  const { dispatch: uploadDispatch } = useUploadContext();
  const { getSuppliedItems, getUploadUrl, getFileFormatNames, getExtensions } = useSapFlow();
  const fileFormatQuery = useFileFormat(getFileFormatNames(sapFlow));
  const { addAuthorizationHeader } = useAuthorization();
  const reusableItemsQuery = useReusableFiles();
  const [reusableItems, setReusableItems] = React.useState<any>(null);
  const [expanded, setExpanded] = React.useState<any>(null);

  const selectField = 'selected';
  const expandField = 'expanded';
  const dataItemKey = 'id';
  const textField = 'name';
  const subItemsField = 'items';
  const fields = {
    selectField,
    expandField,
    dataItemKey,
    subItemsField,
  };
  const treeData = useMemo(() => {
    if (reusableItems)
      return processTreeData(
        reusableItems,
        {
          expanded,
          values: requiredFileStatus.FilesToReuse,
        },
        fields
      );
  }, [reusableItems, requiredFileStatus.FilesToReuse, expanded]);

  const onExpandChange = useCallback(
    (event: DropDownTreeExpandEvent) => setExpanded(expandedState(event.item, dataItemKey, expanded)),
    [expanded]
  );

  useEffect(() => {
    if (reusableItemsQuery.isSuccess && itemnode.acceptedFileExtensions) {
      console.log('itemnode: ' + JSON.stringify(itemnode));
      const a: any = reusableItemsQuery.data
        .map((projElement: ReusableFileStructure) => {
          return {
            type: 'PROJECT',
            name: projElement.name,
            id: projElement.id,
            items: projElement.transactionDetails
              .map((transElement: any) => {
                return {
                  type: 'TRANSACTION',
                  name: transElement.name,
                  id: transElement.id,
                  items: transElement.itemDetails
                    .map((itemElement: any) => {
                      return {
                        type: 'ITEM',
                        name: itemElement.name,
                        id: itemElement.id,
                        size: itemElement.size,
                        items: !itemElement.files
                          ? null
                          : itemElement.files
                              .filter((fileElement: any) => {
                                return itemnode.acceptedFileExtensions.includes(fileElement.format);
                              })
                              .map((fileElement: any) => {
                                return {
                                  type: 'FILE',
                                  name: fileElement.name,
                                  id: fileElement.fileURIPath,
                                  size: fileElement.size,
                                  itemId: itemElement.id,
                                };
                              }),
                      };
                    })
                    .filter((itemElement: any) => itemElement.items?.length > 0),
                };
              })
              .filter((transElement) => transElement.items?.length > 0),
          };
        })
        .filter((projElement) => projElement.items?.length > 0);
      setReusableItems(a);
      if (a.length > 0) setExpanded([a[0][dataItemKey]]);
    }
  }, [reusableItemsQuery.isSuccess, reusableItemsQuery.data]);

  const handleBeforeUpload = (event: UploadOnBeforeUploadEvent) => {
    addAuthorizationHeader(event.headers, getTokenType(), getAccessToken());
    event.headers.Organization = `${getCurrentOrganization().id}`;
    setUploading(true);
  };

  const itemRenderInternal = (title: string, description: string, extra: string) => {
    return (
      <div
        className="fill-width"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: '1rem',
        }}
      >
        <Typography.h5
          fontWeight="light"
          textAlign="center"
          style={{ maxWidth: '75%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {title}
          <Typography.h6 style={{ textAlign: 'left' }}>{description}</Typography.h6>
        </Typography.h5>
        <Typography.h6 style={{}}>{extra}</Typography.h6>
      </div>
    );
  };

  const itemRender = (fileDetail: any) => {
    if (!fileDetail) {
      return itemRenderInternal('Select an items upload to reuse.', null, null);
    } else if (fileDetail.type === 'PROJECT') {
      return itemRenderInternal(fileDetail?.name, 'PROJECT', null);
    } else if (fileDetail.type === 'TRANSACTION') {
      return itemRenderInternal(fileDetail?.name, 'SAPFLOW', null);
    } else {
      return itemRenderInternal(fileDetail?.name, formatSizeUnits(fileDetail?.size), fileDetail?.createdDate);
    }
  };

  const valueRender = (fileDetail: any) => {
    if (!fileDetail) {
      return itemRenderInternal('Select an items upload to reuse.', null, null);
    } else if (fileDetail.length === 0) {
      return itemRenderInternal('Select an items upload to reuse.', null, null);
    } else if (fileDetail.length === 1) {
      return itemRenderInternal(fileDetail[0].name, formatSizeUnits(fileDetail[0].size), fileDetail[0].createdDate);
    } else {
      return itemRenderInternal(
        'Multiple files selected',
        formatSizeUnits(fileDetail.reduce((accumulator: number, file: any) => accumulator + file.size, 0)),
        null
      );
    }
  };

  const treeItemRender = (treeItemProps: any) => {
    return itemRender(treeItemProps.item);
  };

  const treeValueRender = (treeItemProps: any) => {
    return valueRender(treeItemProps.item);
  };

  const toggleFileSelected = (reusableFile: any) => {
    const newRequiredFileStatus = { ...requiredFileStatus };
    newRequiredFileStatus.ProvisionType = 'Reuse';
    if (newRequiredFileStatus.FilesToReuse === null) {
      newRequiredFileStatus.FilesToReuse = [];
    } else {
      newRequiredFileStatus.FilesToReuse = [...newRequiredFileStatus.FilesToReuse];
    }
    if (newRequiredFileStatus.FilesToReuse.filter((file: any) => file.id === reusableFile.id).length > 0) {
      newRequiredFileStatus.FilesToReuse = newRequiredFileStatus.FilesToReuse.filter(
        (file: any) => file.id !== reusableFile.id
      );
      if (newRequiredFileStatus.FilesToReuse.length === 0) {
        newRequiredFileStatus.FilesToReuse = null;
      }
    } else {
      newRequiredFileStatus.FilesToReuse.push(reusableFile);
    }
    newRequiredFileStatus.Uploaded = false;
    setRequiredFileStatus(newRequiredFileStatus);
    handleStatusChange(newRequiredFileStatus, itemnode.id);
  };

  const addFileSelected = (reusableFile: any, newRequiredFileStatus: any) => {
    if (newRequiredFileStatus.FilesToReuse.filter((file: any) => file.id === reusableFile.id).length <= 0) {
      newRequiredFileStatus.FilesToReuse.push(reusableFile);
    }
  };

  const removeFileSelected = (reusableFile: any, newRequiredFileStatus: any) => {
    if (newRequiredFileStatus.FilesToReuse.filter((file: any) => file.id === reusableFile.id).length > 0) {
      newRequiredFileStatus.FilesToReuse = newRequiredFileStatus.FilesToReuse.filter(
        (file: any) => file.id !== reusableFile.id
      );
      if (newRequiredFileStatus.FilesToReuse.length === 0) {
        newRequiredFileStatus.FilesToReuse = null;
      }
    }
  };

  const handleUploadStatusChange = (event: UploadOnStatusChangeEvent, itemId: string) => {
    const newRequiredFileStatus = { ...requiredFileStatus };
    newRequiredFileStatus.FilesToReuse = null;
    newRequiredFileStatus.Uploaded = event.newState[0].status === UploadFileStatus.Uploaded;
    setRequiredFileStatus(newRequiredFileStatus);
    handleStatusChange(newRequiredFileStatus, itemnode.id);
  };

  return (
    <div
      className="my-4"
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '8px',
        borderRadius: '8px',
        border: '1px solid rgba(100,100,100,0.2)',
      }}
    >
      {uploading && (
        <FileNodeUploadModal
          type={UploadWizardType.SAPFLOW}
          show={uploading}
          handleDone={(uploadToFocus: TusUpload) => {
            setUploading(false);
            uploadDispatch({
              type: 'UPLOADS_VISIBILITY',
              payload: { visibility: true, selectedUpload: uploadToFocus },
            });
          }}
          handleClose={() => {
            setUploading(false);
          }}
          projectId={project.id}
          sapflowId={sapFlow.id}
          itemNodeId={itemnode.id}
        />
      )}
      <Typography.h3 style={{ paddingLeft: '24px' }}>{itemnode.name}</Typography.h3>
      {!reusableItemsQuery.isLoading && reusableItemsQuery.isSuccess && reusableItemsQuery.data.length !== 0 && (
        <div className="mb-2" style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <span style={{ marginRight: '8px' }}>Upload</span>
          <Switch
            className="neutral"
            onChange={(event) => {
              const newRequiredFileStatus = { ...requiredFileStatus };
              newRequiredFileStatus.ProvisionType = event.target.value ? 'Reuse' : 'Upload';
              newRequiredFileStatus.FilesToReuse = null;
              setRequiredFileStatus(newRequiredFileStatus);
              handleStatusChange(newRequiredFileStatus, itemnode.id);
            }}
            checked={requiredFileStatus.ProvisionType === 'Reuse'}
            disabled={
              reusableItemsQuery.isLoading || !reusableItemsQuery.isSuccess || reusableItemsQuery.data.length === 0
            }
          />
          <span style={{ marginLeft: '8px' }}>Reuse</span>
        </div>
      )}
      {requiredFileStatus.ProvisionType === 'Reuse' && (
        <DropDownTree
          item={treeItemRender}
          valueHolder={treeValueRender}
          data={treeData}
          value={requiredFileStatus.FilesToReuse}
          opened={treeOpened}
          onFocus={() => {
            setTreeOpened(true);
          }}
          onBlur={() => {
            setTreeOpened(false);
          }}
          onChange={(event: DropDownTreeChangeEvent) => {
            if (event.value && event.value.type === 'FILE') {
              toggleFileSelected(event.value);
            } else if (expanded.indexOf(event.value[dataItemKey]) !== -1 && event.value.type === 'ITEM') {
              // Check if all items are selected
              let allSelected = true;
              if (!requiredFileStatus.FilesToReuse || requiredFileStatus.FilesToReuse.length === 0) {
                allSelected = false;
              } else {
                event.value.items.forEach((reusableItems: any) => {
                  if (requiredFileStatus.FilesToReuse.filter((file: any) => file.id === reusableItems.id).length <= 0) {
                    allSelected = false;
                  }
                });
              }
              const newRequiredFileStatus = { ...requiredFileStatus };
              newRequiredFileStatus.ProvisionType = 'Reuse';
              if (newRequiredFileStatus.FilesToReuse === null) {
                newRequiredFileStatus.FilesToReuse = [];
              } else {
                newRequiredFileStatus.FilesToReuse = [...newRequiredFileStatus.FilesToReuse];
              }
              if (allSelected) {
                // deselect Everything
                event.value.items.forEach((reusableItems: any) => {
                  removeFileSelected(reusableItems, newRequiredFileStatus);
                });
              } else {
                event.value.items.forEach((reusableItems: any) => {
                  addFileSelected(reusableItems, newRequiredFileStatus);
                });
              }
              newRequiredFileStatus.Uploaded = false;
              setRequiredFileStatus(newRequiredFileStatus);
              handleStatusChange(newRequiredFileStatus, itemnode.id);
            } else {
              setExpanded(expandedState(event.value, dataItemKey, expanded));
              return;
            }
          }}
          placeholder="Select ..."
          textField={textField}
          dataItemKey={dataItemKey}
          selectField={selectField}
          expandField={expandField}
          onExpandChange={onExpandChange}
        ></DropDownTree>
      )}
      {/*requiredFileStatus.ProvisionType === 'Upload' && (
        <Upload
          key={itemnode.id}
          id={itemnode.id}
          batch={true}
          defaultFiles={[]}
          withCredentials={false}
          saveUrl={getUploadUrl(sapFlow.id, itemnode.id, false)}
          multiple
          accept={getExtensions(itemnode.fileformat, fileFormatQuery.data).join(', ')}
          restrictions={{
            allowedExtensions: getExtensions(itemnode.fileformat, fileFormatQuery.data),
          }}
          onBeforeUpload={handleBeforeUpload}
          onStatusChange={(e) => handleUploadStatusChange(e, itemnode.id)}
          selectMessageUI={() => <span>{`Upload your ${itemnode.fileformat.toUpperCase()} file`}</span>}
        />
      )*/}
      {requiredFileStatus.ProvisionType === 'Upload' && (
        <SubmitButton
          label={'UPLOAD'}
          full={false}
          onClick={() => {
            setUploading(true);
          }}
        />
      )}
    </div>
  );
};

export default InputDetailSelection;
