import React from 'react';
import { useConsumeDataProcessorState } from '../../../context/dataProcessorContext';

interface TableRow {
  flowName: string;
  sapType: string;
  inputFiles: string[];
  outputFiles: string[];
}

const tableData: TableRow[] = [
  {
    flowName: 'Base Process Point Cloud',
    sapType: 'Base LiDAR Processing',
    inputFiles: ['.laz/.las'],
    outputFiles: ['.laz/.las'],
  },
  {
    flowName: 'Point Cloud Classification',
    sapType: 'Base LiDAR Processing',
    inputFiles: ['.laz/.las'],
    outputFiles: ['.shp/.kml'],
  },
  { flowName: 'Hydro Flattened DEM', sapType: 'Model Enhancer', inputFiles: ['.laz/.las'], outputFiles: ['.tif'] },
  {
    flowName: 'Digital Terrain Model',
    sapType: 'Base LiDAR Processing',
    inputFiles: ['.laz/.las'],
    outputFiles: ['.tif'],
  },
  {
    flowName: 'Denoise Point Cloud',
    sapType: 'Base LiDAR Processing',
    inputFiles: ['.laz/.las'],
    outputFiles: ['.laz/.las'],
  },
  {
    flowName: 'Merge Point Clouds',
    sapType: 'Base LiDAR Processing',
    inputFiles: ['.laz/.las'],
    outputFiles: ['.laz/.las'],
  },
  {
    flowName: 'Tile Point Clouds',
    sapType: 'Base LiDAR Processing',
    inputFiles: ['.laz/.las', '.shp/.kml'],
    outputFiles: ['.laz/.las'],
  },
  {
    flowName: 'Tree Delineation',
    sapType: 'Point Cloud Enhancer',
    inputFiles: ['.laz/.las'],
    outputFiles: ['.laz/.las'],
  },
];

const TableComponent: React.FC = () => {
  const { dispatch } = useConsumeDataProcessorState();
  return (
    <div style={{ fontFamily: 'Arial, sans-serif' }}>
      <table
        style={{
          width: '100%',
          borderCollapse: 'collapse',
          textAlign: 'left',
        }}
      >
        <thead>
          <tr style={{ backgroundColor: '#f2f2f2' }}>
            <th style={thStyle}>SAP Flows</th>
            <th style={thStyle}>SAP Type</th>
            <th style={thStyle}>Required Input Files</th>
            <th style={thStyle}>Outputs Files</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr
              key={index}
              style={{
                backgroundColor: index % 2 === 0 ? '#ffffff' : '#f9f9f9',
              }}
            >
              <td style={tdStyle}>
                <button
                  style={buttonStyle}
                  onClick={() => {
                    dispatch({ type: 'SET_SELECTED_CONFIG', payload: true });
                  }}
                >
                  Run SAPFlow
                </button>{' '}
                {row.flowName}
              </td>
              <td style={tdStyle}>{row.sapType}</td>
              <td style={tdStyle}>{row.inputFiles.join(', ')}</td>
              <td style={tdStyle}>{row.outputFiles.join(', ')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const thStyle: React.CSSProperties = {
  padding: '10px',
  borderBottom: '2px solid #ddd',
  fontWeight: 'bold',
};

const tdStyle: React.CSSProperties = {
  padding: '10px',
  borderBottom: '1px solid #ddd',
};

const buttonStyle: React.CSSProperties = {
  backgroundColor: '#4CAF50',
  color: 'white',
  border: 'none',
  padding: '5px 10px',
  borderRadius: '5px',
  cursor: 'pointer',
  marginRight: '10px',
};

export default TableComponent;
