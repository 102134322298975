import React, { useEffect, useState } from 'react';
import { Breadcrumb } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { FileNode } from '../../types/DataDelivery';
import { SvgIcon, Typography } from '@progress/kendo-react-common';
import { folderAddIcon, folderIcon } from '@progress/kendo-svg-icons';
import { useFilenodeStructure } from '../../hooks/data_delivery';
import { CornerLoader } from '..';
import CreateFolderFileNodeModal from '../../components/filenodes/CreateFolderFileNodeModal';
import { useUser } from '../../hooks/authentication';
import { useFileNodeActions } from '../../actions';
import { useQueryClient } from 'react-query';

interface Props {
  rootFileNode: FileNode;
  selectedFileNode: FileNode;
  onSelect: (node: FileNode) => void;
  showFiles?: boolean;
}

const FileNodeBrowser: React.FC<Props> = ({ rootFileNode, showFiles, selectedFileNode, onSelect }: Props) => {
  const queryClient = useQueryClient();
  const [breadcrumbs, setBreadcrumbs] = useState<FileNode[]>([rootFileNode]);
  const [availableNodes, setAvailableNodes] = useState<FileNode[]>([]);
  const [creatingFolderFileNode, setCreatingFolderFileNode] = useState(false);
  const fileNodeQuery = useFilenodeStructure(selectedFileNode.id);
  const { getCurrentOrganization } = useUser();
  const { handleCreateFolderFileNode } = useFileNodeActions();

  useEffect(() => {
    const newBreadcrumbs = [selectedFileNode];
    let iteratingFileNode = selectedFileNode;
    while (iteratingFileNode.parent) {
      newBreadcrumbs.unshift(iteratingFileNode.parent);
      iteratingFileNode = iteratingFileNode.parent;
    }
    // If the first Breadcrumb is not the root node, then add the root node
    if (newBreadcrumbs[0].id !== rootFileNode.id) {
      newBreadcrumbs.unshift(rootFileNode);
    }
    setBreadcrumbs(newBreadcrumbs);
  }, [rootFileNode, selectedFileNode]);

  useEffect(() => {
    if (fileNodeQuery.isLoading || fileNodeQuery.isRefetching) {
      return;
    }
    const fileNodeToConsider = selectedFileNode.id === rootFileNode.id ? rootFileNode : fileNodeQuery.data;
    if (fileNodeQuery.isSuccess && fileNodeToConsider && fileNodeToConsider.children) {
      setAvailableNodes(fileNodeToConsider.children.filter((node) => node.isDir && !node.isSupport));
    } else {
      setAvailableNodes([]);
    }
  }, [fileNodeQuery.isSuccess, fileNodeQuery.data]);

  const createRootFileNode = async (fileNodeName: string) => {
    await handleCreateFolderFileNode(fileNodeName, selectedFileNode);
    queryClient.invalidateQueries(['fileNodeStructure', selectedFileNode.id], { refetchInactive: true });
  };

  const handleBreadcrumbClick = (index: number) => {
    const newBreadcrumbs = breadcrumbs.slice(0, index + 1);
    onSelect(newBreadcrumbs.slice(-1)[0]);
    setBreadcrumbs(newBreadcrumbs);
  };

  const handleCreateFolder = () => {
    setCreatingFolderFileNode(true);
  };

  const handleSelectFileNode = (node: FileNode) => {
    onSelect(node);
    setBreadcrumbs([...breadcrumbs, node]);
  };

  const renderNode = (node: FileNode) => (
    <div key={node.path} className="flex items-center space-x-2 mb-2">
      {node.isDir && (
        <Typography.h4
          style={{ cursor: 'pointer' }}
          onClick={() => {
            handleSelectFileNode(node);
          }}
        >
          <SvgIcon icon={folderIcon} size="xxlarge" style={{ color: 'var(--warning-yellow)' }} />
          <span style={{ position: 'relative', bottom: '-0.2rem', left: '1rem' }}>{node.name}</span>
        </Typography.h4>
      )}
      {!node.isDir && <span>{node.name}</span>}
    </div>
  );

  const noAvailableFiles = !fileNodeQuery.isLoading && !fileNodeQuery.isRefetching && availableNodes?.length === 0;

  return (
    <div className="ps-4" style={{ border: '1px solid gray', borderRadius: '1rem', overflow: 'hidden', flex: '1' }}>
      <CornerLoader show={fileNodeQuery.isLoading} />
      <CreateFolderFileNodeModal
        show={creatingFolderFileNode}
        handleClose={() => {
          setCreatingFolderFileNode(false);
        }}
        defaultValue="New Folder"
        onConfirm={(name: string) => {
          setCreatingFolderFileNode(false);
          createRootFileNode(name);
        }}
        parent={selectedFileNode.id === rootFileNode.id ? null : selectedFileNode}
      />
      <div style={{ overflow: 'auto', height: '100%' }}>
        <Breadcrumb
          data={breadcrumbs.map((node, index) => ({
            text: node.name,
            id: index + '',
          }))}
          onItemSelect={(e) => {
            handleBreadcrumbClick(parseInt(e.id));
          }}
        />
        <Typography.h4 style={{ cursor: 'pointer' }} onClick={handleCreateFolder}>
          <SvgIcon icon={folderAddIcon} themeColor="tertiary" size="xxlarge" />
          <span style={{ position: 'relative', bottom: '-0.2rem', left: '1rem' }}>Create Folder</span>
        </Typography.h4>
        <div className="mt-4">
          {noAvailableFiles && 'No files or folders'}
          {!noAvailableFiles && availableNodes.map(renderNode)}
        </div>
      </div>
    </div>
  );
};

export default FileNodeBrowser;
