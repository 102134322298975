import { SvgIcon, Typography } from '@progress/kendo-react-common';
import { chevronLeftIcon } from '@progress/kendo-svg-icons';
import React from 'react';
import { useConsumeDataProcessorState } from '../../../../context/dataProcessorContext';
import { WrappedOrEllipsisSpan } from '../../../../components';
import { Tooltip } from '@progress/kendo-react-tooltip';

const TableComponent: React.FC = () => {
  const { dispatch } = useConsumeDataProcessorState();
  const handleBack = () => {
    dispatch({ type: 'SET_SELECTED_CONFIG', payload: null });
  };
  return (
    <div
      className=""
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        position: 'relative',
        padding: '1rem',
        color: 'white',
      }}
    >
      <div className="d-flex" style={{ width: '100%' }}>
        <div className="d-flex items-center" style={{ alignItems: 'center' }}>
          <SvgIcon
            icon={chevronLeftIcon}
            size="large"
            style={{
              marginRight: '12px',
              cursor: 'pointer',
              fontSize: '28px',
              marginLeft: '-8px',
            }}
            onClick={() => {
              handleBack();
            }}
          />
        </div>
        <Typography.h5
          className="d-none d-lg-flex"
          style={{
            padding: '0px 0px',
            display: 'flex',
            margin: '0 0',
            flex: 1,
            justifyContent: 'flex-start',
          }}
        >
          <div style={{ display: 'flex', width: '100%' }}>
            <div
              style={{ position: 'relative', flex: '1 1 0%', display: 'flex', overflow: 'hidden', marginRight: 'auto' }}
            >
              <WrappedOrEllipsisSpan wrapped={true}>{'Hydroflatten DEM'}</WrappedOrEllipsisSpan>

              <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                <Tooltip anchorElement="target" position="top" style={{ maxWidth: '400px', whiteSpace: 'normal' }}>
                  <a
                    style={{ width: '100%', height: '100%', position: 'absolute', zIndex: 1 }}
                    title={'Hydroflatten DEM'}
                  ></a>
                </Tooltip>
              </div>
            </div>
          </div>
        </Typography.h5>
      </div>
      <img src="assets/navigation_sapconfig_example.png" style={{ width: '100%' }}></img>
    </div>
  );
};

export default TableComponent;
