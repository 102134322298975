import React, { useEffect, useState } from 'react';
import { RangeSlider, Slider, SliderLabel } from '@progress/kendo-react-inputs';
import { BandInfo, LayerType, ViewLayer } from '../../../../../../types';
import { updateLayerParam } from '../../../../../../common/viewerConfigHelper';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { ColorResult, SketchPicker } from 'react-color';
import { VectorVisualization } from '../../../../../../types/Rendering';
import { getWfsPreviewLayerLegendUrl } from '../../../../../../common/viewLayerHelper';

interface OLVectorControlsProps {
  layer: ViewLayer;
  onLayerUpdated?: (layer: ViewLayer) => void;
}

const VectorControls: React.FC<OLVectorControlsProps> = ({ layer, onLayerUpdated }) => {
  return (
    <div className="d-flex flex-column p-2 mt-4 mb-4">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <img
          style={{ width: 'fit-content' }}
          src={getWfsPreviewLayerLegendUrl(layer, {
            transparent: true,
            legend_options: 'fontName:Microsoft Sans Serif;fontSize:18;fontColor:0xffffff;labelMargin:16;',
          })}
        />
      </div>
    </div>
  );
};

export default VectorControls;
