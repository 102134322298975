import React, { useEffect, useState } from 'react';
import Dialog from '../../../components/Dialog';
import { Checkbox } from '@progress/kendo-react-inputs';
import { SVGIcon, Typography } from '@progress/kendo-react-common';
import { FileNode } from '../../../types/DataDelivery';
import { useTusUploader } from '../../../hooks/common';
import { extractTusUploadType, TusFileNodeUpload, TusUpload, TusUploadStatus, UploadType } from '../../../types';
import { ProgressBar } from '../../feedback';
import { formatSizeUnits } from '../../../converters/formatSizeUnits';
import IconButton from '../../form/IconButton';
import { pauseIcon, playIcon, rotateIcon, stopIcon, trashIcon } from '@progress/kendo-svg-icons';

interface Props {
  upload: TusUpload;
  onBack?: () => void;
}

interface UploadAction {
  action: () => void;
  text: string;
  disabled: boolean;
  icon: SVGIcon;
}

const UploadProgress: React.FC<Props> = (props: Props) => {
  const { resumeUpload, retryUpload, pauseUpload, cancelUpload, removeUpload } = useTusUploader();
  const getStatusText = (status: TusUploadStatus) => {
    switch (status) {
      case TusUploadStatus.UPLOADING:
        return 'Uploading';
      case TusUploadStatus.PROCESSING:
        return 'Processing';
      case TusUploadStatus.PAUSED:
        return 'Paused';
      case TusUploadStatus.INTERRUPTED:
        return 'Interrupted';
      case TusUploadStatus.CANCELED:
        return 'Canceled';
      case TusUploadStatus.COMPLETED:
        return 'Completed';
      case TusUploadStatus.ERRORED:
        return 'Errored';
      default:
        return 'Unknown';
    }
  };

  const handlePauseUpload = () => {
    pauseUpload(props.upload.fileId);
  };

  const handleResumeUpload = () => {
    resumeUpload(props.upload.fileId);
  };

  const handleRetryUpload = () => {
    retryUpload(props.upload.fileId);
  };

  const handleStopUpload = () => {
    cancelUpload(props.upload.fileId);
  };

  const handleRemoveUpload = () => {
    removeUpload(props.upload.fileId);
    props.onBack && props.onBack();
  };

  const renderProgressBar = () => {
    let bgColor = 'var(--geosap-primary-color)';
    const textColor = 'white';
    let text = 'Uploading';
    if (props.upload.status === TusUploadStatus.UPLOADING) {
      bgColor = 'var(--geosap-primary-color)';
      text = 'Uploading';
    } else if (props.upload.status === TusUploadStatus.PROCESSING) {
      bgColor = 'var(--status-new)';
      text = 'Processing...';
    } else if (props.upload.status === TusUploadStatus.COMPLETED) {
      bgColor = 'var(--view-green)';
      text = 'Completed';
    } else if (props.upload.status === TusUploadStatus.PAUSED) {
      bgColor = 'var(--warning-yellow)';
      text = 'Paused';
    } else if (props.upload.status === TusUploadStatus.INTERRUPTED) {
      bgColor = 'var(--warning-yellow)';
      text = 'Interrupted';
    } else if (props.upload.status === TusUploadStatus.CANCELED) {
      bgColor = 'var(--light-red)';
      text = 'Canceled';
    } else if (props.upload.status === TusUploadStatus.ERRORED) {
      bgColor = 'var(--hazard-red)';
      text = 'Errored';
    }
    const disabledAnim =
      props.upload.status !== TusUploadStatus.UPLOADING && props.upload.status !== TusUploadStatus.PROCESSING;
    console.log('Disabled anim: ', disabledAnim);
    return (
      <div className="d-flex gap-3">
        <ProgressBar
          progress={props.upload.progress}
          text={text}
          disableAnimation={disabledAnim}
          textColor={textColor}
          progressStyle={{ backgroundColor: bgColor }}
        />
      </div>
    );
  };

  const renderActions = () => {
    const availableOptions: UploadAction[] = [];
    if (props.upload.status === TusUploadStatus.UPLOADING) {
      availableOptions.push({
        text: 'Pause',
        action: handlePauseUpload,
        disabled: false,
        icon: pauseIcon,
      });
      availableOptions.push({
        text: 'Stop',
        action: handleStopUpload,
        disabled: false,
        icon: stopIcon,
      });
    } else if (props.upload.status === TusUploadStatus.PAUSED || props.upload.status === TusUploadStatus.INTERRUPTED) {
      availableOptions.push({
        text: 'Resume',
        action: handleResumeUpload,
        disabled: false,
        icon: playIcon,
      });
      availableOptions.push({
        text: 'Stop',
        action: handleStopUpload,
        disabled: false,
        icon: stopIcon,
      });
    }
    if (props.upload.status === TusUploadStatus.CANCELED || props.upload.status === TusUploadStatus.ERRORED) {
      availableOptions.push({
        text: 'Retry',
        action: handleRetryUpload,
        disabled: false,
        icon: rotateIcon,
      });
    }
    if (
      props.upload.status === TusUploadStatus.CANCELED ||
      props.upload.status === TusUploadStatus.COMPLETED ||
      props.upload.status === TusUploadStatus.ERRORED
    ) {
      availableOptions.push({
        text: 'Remove',
        action: handleRemoveUpload,
        disabled: false,
        icon: trashIcon,
      });
    }
    return (
      <div style={{ display: 'flex' }}>
        {availableOptions.map((option, index) => (
          <IconButton
            key={index}
            icon={option.icon}
            style={{ margin: '0.25rem' }}
            onClick={(e) => {
              e.preventDefault();
              option.action();
            }}
            disabled={option.disabled}
          ></IconButton>
        ))}
      </div>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '2rem', textAlign: 'center' }}>
      <Typography.h3 style={{ padding: '1rem' }}>{`Status: ${getStatusText(props.upload.status)}`}</Typography.h3>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
          marginLeft: '4rem',
          paddingRight: '1rem',
          textAlign: 'start',
        }}
      >
        <Typography.p style={{ marginBottom: 0 }}>{`Uploading: ${props.upload.file.name}`}</Typography.p>
        {extractTusUploadType(props.upload) === UploadType.FILENODE && (
          <Typography.p style={{ marginBottom: 0 }}>{`To: ${(props.upload as TusFileNodeUpload).path}`}</Typography.p>
        )}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '2rem', marginBottom: '2rem' }}>
        <div style={{ display: 'flex', flexDirection: 'column', flex: '1', margin: '0.25rem', paddingRight: '1rem' }}>
          {renderProgressBar()}
          <Typography.p style={{ textAlign: 'end', marginBottom: 0 }}>{`Uploaded ${formatSizeUnits(
            props.upload.uploaded
          )} of ${formatSizeUnits(props.upload.totalSize)}`}</Typography.p>
        </div>
        {renderActions()}
      </div>
      <Checkbox value={false} label={'Notify me when upload & processing is complete'} disabled={true} />
    </div>
  );
};

export default UploadProgress;
