import { QueryClient } from 'react-query';
import { SapFlow, ESapFlowStatus, WSMessage, SapFlowGroup, SapFlowStatus } from '../../../types';

const updateSapFlowStatusInGroups = (transactionStatus: SapFlowStatus, queryClient: QueryClient)=>{
  let groupsData: SapFlowGroup[] | undefined = null;
  try {
    groupsData = JSON.parse(JSON.stringify(queryClient.getQueryData(['sapGroups'])));
  } catch (e) {
    return;
  }
  const findAndModifyGroupSapflow = (group: SapFlowGroup) => {
    if (group.sapflows && group.sapflows.length > 0) {
      group.sapflows.forEach((sapflow: SapFlow) => {
        if (sapflow.id === transactionStatus.transactionID) {
          if (
            sapflow.transactionHistory.filter((transStatus) => transStatus.id === transactionStatus.id).length === 0
          ) {
            sapflow.transactionHistory.push(transactionStatus);
            sapflow.transactionPercentComplete = transactionStatus.currentTransactionPercentComplete;
            sapflow.transactionRuntime = transactionStatus.currentTransactionRuntime;
            sapflow.status = transactionStatus.transactionStatus;
          }
        }
      });
    }
    if (group.groups && group.groups.length > 0) {
      group.groups.forEach((group) => {
        findAndModifyGroupSapflow(group);
      });
    }
  };
  groupsData.forEach((group: SapFlowGroup) => {
    findAndModifyGroupSapflow(group);
  });

  queryClient.setQueryData(['sapGroups'], groupsData);
}

const updateSapFlowStatus = (transactionStatus: SapFlowStatus, queryClient: QueryClient)=>{
  let sapflow: SapFlow | undefined = null;
  try {
    sapflow = JSON.parse(JSON.stringify(queryClient.getQueryData(['sapflow', transactionStatus.transactionID])));
  } catch (e) {
    return;
  }
  
  if (
    sapflow.transactionHistory.filter((transStatus) => transStatus.id === transactionStatus.id).length === 0
  ) {
    sapflow.transactionHistory.push(transactionStatus);
    sapflow.transactionPercentComplete = transactionStatus.currentTransactionPercentComplete;
    sapflow.transactionRuntime = transactionStatus.currentTransactionRuntime;
    sapflow.status = transactionStatus.transactionStatus;
  }

  queryClient.setQueryData(['sapflow', transactionStatus.transactionID], sapflow);
}

const handleMessage = (wsMessage: WSMessage, queryClient: QueryClient) => {
  const transactionStatus: SapFlowStatus = wsMessage.payload;
  if (transactionStatus !== null && transactionStatus.transactionID) {
    updateSapFlowStatusInGroups(transactionStatus, queryClient);
    updateSapFlowStatus(transactionStatus, queryClient);
  } else {
    console.log('Invalid Trans Status update');
  }
};

export default handleMessage;
