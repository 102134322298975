import React, { createContext, useContext, useReducer } from 'react';
import { DataCollectionWizardState, initialState, DataCollectionWizardStep } from './dataCollectionWizardState';
import { CollectionType } from '../../types';

type Action =
  | { type: 'SELECT_CONFIG_TYPE'; payload: { configType: CollectionType } }
  | { type: 'SELECT_CLIENT_PROJECT'; payload: { clientProjectId: string } }
  | { type: 'VALIDATE_PATH'; payload: { path: string } }
  | { type: 'COMPLETE_WIZARD'; payload: {} }
  | { type: 'GO_BACK'; payload: {} };

const reducer = (state: DataCollectionWizardState, action: Action): DataCollectionWizardState => {
  switch (action.type) {
    case 'SELECT_CONFIG_TYPE':
      return {
        ...state,
        configType: action.payload.configType,
        step: DataCollectionWizardStep.ENTER_PATH,
      };
    case 'SELECT_CLIENT_PROJECT':
      return {
        ...state,
        clientProjectId: action.payload.clientProjectId,
      };
    case 'VALIDATE_PATH':
      return {
        ...state,
        path: action.payload.path,
        step: DataCollectionWizardStep.ADJUST_CONFIG,
      };
    case 'COMPLETE_WIZARD':
      return {
        ...state,
        done: true,
      };
    case 'GO_BACK':
      return {
        ...state,
        step: state.step - 1,
      };
    default:
      return state;
  }
};

export const DataCollectionWizardContext = createContext<{
  state: DataCollectionWizardState;
  dispatch: React.Dispatch<Action>;
} | null>(null);

export const useDataCollectionWizardState = () => {
  const context = useContext(DataCollectionWizardContext);
  if (!context) {
    throw new Error('useDataCollectionWizardState must be used within a DataCollectionWizardProvider');
  }
  return context;
};

export const DataCollectionWizardProvider: React.FC<{ children: React.ReactNode; clientProjectId: string }> = ({
  children,
  clientProjectId,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    clientProjectId,
  });

  return (
    <DataCollectionWizardContext.Provider value={{ state, dispatch }}>{children}</DataCollectionWizardContext.Provider>
  );
};
