import React from 'react';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { SubmitButton } from './form';
import Dialog from './Dialog';
import { Tooltip } from '@progress/kendo-react-tooltip';

interface DialogProps {
  label: string;
  onClick?: (event?: React.MouseEvent<HTMLDivElement>) => {};
  children: JSX.Element;
  style?: any;
  containerStyle?: any;
}

const Tooltipped: React.FC<DialogProps> = (props: DialogProps) => {
  return (
    <div style={{ ...{ position: 'relative', display: 'flex' }, ...props.containerStyle }} onClick={props.onClick}>
      {props.children}
      <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
        <Tooltip anchorElement="target" position="top" style={{ maxWidth: '400px', whiteSpace: 'normal' }}>
          <a style={{ width: '100%', height: '100%', position: 'absolute', zIndex: 1 }} title={props.label}></a>
        </Tooltip>
      </div>
    </div>
  );
};

export default Tooltipped;
