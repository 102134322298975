import { useMutation } from 'react-query';
import { useAxiosInstance } from '../../common/';

interface Params {
  sapflowid: string;
  processnodeid?: string;
}

const useStopSapFlow = () => {
  const axios = useAxiosInstance();

  return useMutation(async (params: Params) => {
    return axios.post("/sapflow/" + params.sapflowid + "/stop", {});
  },
  {
    onSuccess: () => {
      //queryClient.invalidateQueries(['transactions'], { refetchInactive: true });
    },
  });
};

export default useStopSapFlow;
