import React, { useEffect, useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import { FileNode, FileNodeStatus } from '../../../types/DataDelivery';
import Map from './Map';
import { Layers, DataDeliveryLayer, BaseMapLayer } from './Layers';
import {
  Controls,
  FullScreenControl,
  MouseTrackerControl,
  PixelInfoControl,
  ScaleLineControl,
  VCDrawingTool,
  VCMeasurementTool,
} from './Controls';
import MapEvent from 'ol/MapEvent';
import { fromLonLat, toLonLat } from 'ol/proj';
import OLMap from 'ol/Map';
import 'ol/ol.css';
import { Polygon } from 'ol/geom';
import { Feature, MapBrowserEvent } from 'ol';
import { InteractionMode, useConsumeDataDeliveryState } from '../../../context/dataDeliveryContext';
import { debounce } from 'lodash';
import { Layer2DClickResult } from '../../../types';

//https://openlayers.org/en/latest/examples/center.html

declare const window: any;
declare const proj4: any;

const THREE = window.THREE;

interface Props {
  show?: boolean;
  coordinates?: number[];
  height?: number;
}

type Ref = {
  zoomToFileNode: (fileNode: any) => void;
} | null;
const OLViewer = forwardRef<Ref, Props>((props, ref) => {
  const {
    dispatch,
    rootFileNode,
    openedFileNode,
    hoveredFileNode,
    navigationOptions,
    selectedFeature,
    zoomToFileNode: zoomToFileNodeFn,
    interactionMode,
    olMap,
  } = useConsumeDataDeliveryState();
  const [loadingMapData, setLoadingMapData] = useState<boolean>(false);
  const [cameraPosition, setCameraPosition] = useState<any>([-8726319.082769498, 5281472.8611597875]);
  const [cameraZoom, setCameraZoom] = useState<number>(6.25);
  const openedFileNodeRef = React.useRef<any>(openedFileNode);
  const layerRefs = React.useRef<any>({});
  const measurementsRefs = React.useRef<any>(null);
  const drawingsRefs = React.useRef<any>(null);
  const interactionModeRef = React.useRef<any>(interactionMode);
  interactionModeRef.current = interactionMode;

  //useEffect(() => {
  //  if (rootFileNode && rootFileNode.geolocation) {
  //    if (lastFileNodeZoomedTo === null || lastFileNodeZoomedTo.id !== rootFileNode.id) {
  //      zoomToFileNode(rootFileNode);
  //    }
  //  }
  //}, [rootFileNode, olMap]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'SET_OL_MAP', payload: null });
      dispatch({ type: 'SET_OPENLAYERS_ACTIVE', payload: false });
    };
  }, []);

  useEffect(() => {
    //if (openedFileNode) {
    //  zoomToFileNode(openedFileNode);
    //}
    //else if(rootFileNode){
    //  zoomToFileNode(rootFileNode);
    //}
    openedFileNodeRef.current = openedFileNode;
  }, [openedFileNode]);

  const handleMapClick = async (evt: MapBrowserEvent<any>, map: OLMap) => {
    if (interactionModeRef.current === InteractionMode.NAVIGATION) {
      const pixel = map.getEventPixel((evt as any).originalEvent);
      let fileNodeFound: FileNode = null;
      map.forEachFeatureAtPixel((evt as any).pixel, function (f: Feature) {
        const featureFileNode = f.getProperties()['fileNode'];
        if (!fileNodeFound && featureFileNode) {
          if (f.getProperties()['interactable']) {
            fileNodeFound = featureFileNode;
          }
        }
      });

      if (fileNodeFound && (fileNodeFound.isDir || fileNodeFound.status === FileNodeStatus.READY)) {
        // TODO props.onFileNodeSelected(fileNodeFound);
      }
      //return;
    }
    let clickResult: Layer2DClickResult;
    //if (!layerRefs.current || layerRefs.current.length === 0) {
    //  console.log('Click result: ' + JSON.stringify(clickResult));
    //  return;
    //}
    const layerRefIds = Object.keys(layerRefs.current);
    for (let i = 0; i < layerRefIds.length; i++) {
      if (layerRefs.current[layerRefIds[i]] && layerRefs.current[layerRefIds[i]].handleMapClick) {
        clickResult = await layerRefs.current[layerRefIds[i]].handleMapClick(evt);
        if (clickResult) {
          break;
        }
      }
    }
    if (!clickResult && measurementsRefs.current) {
      clickResult = await measurementsRefs.current.handleMapClick(evt);
    }

    console.log('Click result: ' + JSON.stringify(clickResult));

    if (clickResult) {
      if (clickResult.layer) {
        console.log('Click layer id: ' + clickResult.layer.id);
        dispatch({ type: 'SET_SELECTED_LAYER', payload: clickResult.layer });
      }
      if (clickResult.features && clickResult.features.length > 0) {
        if (clickResult.layer.id === openedFileNodeRef.current?.viewLayer?.id) {
          dispatch({
            type: 'SET_SELECTED_FEATURE',
            payload: {
              layerId: clickResult.features[0].getProperties()['layerId'],
              feature: clickResult.features[0],
            },
          });
        }
      } else {
        dispatch({ type: 'SET_SELECTED_FEATURE', payload: null });
      }
    } else {
      dispatch({ type: 'SET_SELECTED_FEATURE', payload: null });
      //dispatch({ type: 'SELECT_FEATURE', payload: null });
    }
  };

  const debouncedMapClick = useCallback(debounce(handleMapClick, 50), []);

  const handleMapEvent = (event: MapEvent, map: OLMap) => {
    if (event.type === 'postrender') {
      if (!olMap) {
        dispatch({ type: 'SET_OPENLAYERS_ACTIVE', payload: true });
        dispatch({ type: 'SET_OL_MAP', payload: map });
      }
    } else if (event.type === 'loadstart') {
      setLoadingMapData(true);
    } else if (event.type === 'loadend') {
      setLoadingMapData(false);
    } else if (event.type === 'pointermove') {
      const pixel = map.getEventPixel((event as any).originalEvent);
      let fileNodeFound: FileNode = null;
      if (!map.hasFeatureAtPixel(pixel)) {
        dispatch({ type: 'SET_HOVERED_FILE_NODE', payload: null });
        return;
      }

      map.forEachFeatureAtPixel((event as any).pixel, function (f: Feature) {
        const featureFileNode = f.getProperties()['fileNode'];
        if (!fileNodeFound && featureFileNode) {
          if (f.getProperties()['interactable']) {
            fileNodeFound = featureFileNode;
          }
        }
      });

      if (fileNodeFound) {
        (map as any).getTarget().style.cursor = 'pointer';
        dispatch({ type: 'SET_HOVERED_FILE_NODE', payload: fileNodeFound });
      } else {
        (map as any).getTarget().style.cursor = '';
        dispatch({ type: 'SET_HOVERED_FILE_NODE', payload: null });
      }
    } else if (event.type === 'click') {
      debouncedMapClick(event as MapBrowserEvent<any>, map);
    }
  };

  const handleCameraMoved = (newPos: any, newZoom: number) => {
    //setCameraPosition(newPos);
    //console.log('Position: ' + JSON.stringify(newPos));
    //console.log('Position Lon/Lat: ' + JSON.stringify(toLonLat(newPos)));
    //console.log('Zoom: ' + newZoom);
  };

  const zoomToFileNode = (fileNode: FileNode): any => {
    if (fileNode.geolocation) {
      if (olMap) {
        const sitePoints = [];
        sitePoints.push(fromLonLat([fileNode.geolocation.lonMin, fileNode.geolocation.latMin]));
        sitePoints.push(fromLonLat([fileNode.geolocation.lonMin, fileNode.geolocation.latMax]));
        sitePoints.push(fromLonLat([fileNode.geolocation.lonMax, fileNode.geolocation.latMax]));
        sitePoints.push(fromLonLat([fileNode.geolocation.lonMax, fileNode.geolocation.latMin]));
        sitePoints.push(sitePoints[0]);
        olMap.getView().fit(new Polygon([sitePoints]), { padding: [50, 50, 50, 50], duration: 1000 });
      } else {
        setCameraPosition(fromLonLat([fileNode.geolocation.lonCenter, fileNode.geolocation.latCenter]));
        console.log('Centering: ' + fileNode.geolocation.lonCenter + ', ' + fileNode.geolocation.latCenter);
      }
      //setLastFileNodeZoomedTo(fileNode);
    }
  };

  useImperativeHandle(ref, () => ({
    zoomToFileNode,
  }));

  const renderFileNodeItem = (fileNode: FileNode, zIndex: number) => {
    if (fileNode.isSupport && fileNode.supportingFile) {
      // FileNodes that are supporting another file shall be displayed for that FileNode.
      return;
    }
    if (fileNode.isDir && fileNode.expanded && fileNode.children?.length > 0) {
      return (
        <div key={fileNode.id}>
          {fileNode.isDir && fileNode.expanded && fileNode.children
            ? fileNode?.children?.map((child, id) => renderFileNodeItem(child, 10 + rootFileNode.children.length - id))
            : null}
        </div>
      );
    }
    if (openedFileNode && openedFileNode.id !== fileNode.id) return null;
    return (
      <DataDeliveryLayer
        ref={(el: any) => {
          layerRefs.current[fileNode.id] = el;
        }}
        fileNode={fileNode}
        key={fileNode.name}
        show={true}
        zIndex={zIndex}
      ></DataDeliveryLayer>
    );
  };

  return (
    <div
      id="olContainer"
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: props.height + 'px',
        backgroundColor: 'rgb(242,239,233)',
        opacity: props.show ? 1 : 0,
        zIndex: props.show ? 1 : -1,
      }}
    >
      <Map
        center={cameraPosition}
        zoom={cameraZoom}
        handleCameraMoved={handleCameraMoved}
        handleMapEvent={handleMapEvent}
      >
        <Layers>
          <BaseMapLayer baseMap={navigationOptions?.baseMap} zIndex={0} show={true} />
          {rootFileNode && !rootFileNode.isDir && renderFileNodeItem(rootFileNode, 10)}
          {rootFileNode &&
            rootFileNode.children &&
            rootFileNode.children.map((child, id) => renderFileNodeItem(child, 10 + rootFileNode.children.length - id))}
        </Layers>
        <VCMeasurementTool
          ref={(el: any) => {
            measurementsRefs.current = el;
          }}
          show={true}
          zIndex={2}
        ></VCMeasurementTool>
        {/*<VCDrawingTool
          ref={(el: any) => {
            drawingsRefs.current = el;
          }}
          show={true}
          zIndex={2}
        ></VCDrawingTool>*/}
        <Controls>
          <FullScreenControl />
          {/*<MouseTrackerControl />*/}
          <MouseTrackerControl />
          {/*<PixelInfoControl />*/}
          <ScaleLineControl />
        </Controls>
      </Map>
      <div
        style={{
          display: loadingMapData ? 'flex' : 'none',
          position: 'absolute',
          bottom: 0,
          right: 0,
          zIndex: 10,
          padding: '1rem',
          borderTopLeftRadius: '1rem',
          backgroundColor: 'rgba(200,200,200,0.6)',
        }}
      >
        <Loader size="small" type={'converging-spinner'} themeColor={'primary'} />
      </div>
    </div>
  );
});

OLViewer.displayName = 'OLViewer';

export default OLViewer;
